/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, Switch, Select, IconButton, MenuItem } from '@mui/material';
import Iconify from 'components/iconify';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import HelpText from '../../../HelpText';

const days = [...Array(6).keys()];

const WaitingTime = ({ name, control, waitingTime, enable, setEnable, setWaitingTime, classes, help }) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={2}>
        <Switch defaultValue={false} checked={enable} onChange={(e) => setEnable(e.target.checked, `${name}_active`)} />
      </Grid>
      <Grid container item xs={10} spacing={2}>
        <Grid container item xs={11.3} spacing={2}>
          <Grid item xs={5}>
            <Controller
              name={name}
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={() => (
                <Select
                  value={waitingTime.day}
                  disabled={!enable}
                  onChange={(e) => {
                    setWaitingTime({
                      ...waitingTime,
                      day: e.target.value,
                    })
                  }
                  }
                  size="small"
                  sx={{
                    height: '46px',
                    width: '100%',
                    '.MuiSvgIcon-root': { display: 'none' },
                    background: '#fff',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '60px !important',
                        boxShadow: 'unset !important',
                        background: 'unset !important',
                        marginTop: '10px',
                        borderRadius: '6px !important',
                        '& .MuiList-root': {
                          background: '#fff !important',
                          paddingTop: 'unset !important',
                          paddingBottom: 'unset !important',
                          width: '45% !important',
                          boxShadow: '2px 5px 6px 3px #bdbdbd78',
                          borderRadius: '6px',
                        },
                        '& .MuiMenuItem-root': {
                          color: theme.palette.primary.main,
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '40px',
                          textAlign: 'center',
                          fontWeight: 600,
                          lineHeight: '40px',
                          '&:hover': {
                            background: '#78909c1a',
                          },
                        },
                      },
                    },
                  }}
                  endAdornment={
                    <IconButton size="small">
                      <Iconify icon="mdi-calendar" className={classes.iconNormal} />
                    </IconButton>
                  }
                  renderValue={(value) => `0${value}d`}
                >
                  {days.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={7} sx={{ paddingLeft: '8px' }}>
            <TimePicker
              defaultValue={moment(`2022-04-17T${waitingTime.hour}:${waitingTime.minute}`)}
              // TODO: find more minimize way
              value={moment(
                `2022-04-17T${waitingTime.hour < 10 ? `0${waitingTime.hour}` : waitingTime.hour}:${
                  waitingTime.minute < 10 ? `0${waitingTime.minute}` : waitingTime.minute
                }`
              )}
              className={classes.autoComplete}
              sx={{ input: { height: '29px' } }}
              slotProps={{ textField: { size: 'small' } }}
              disabled={!enable}
              onChange={(value) => {
                setWaitingTime({
                  day: waitingTime?.day || 1,
                  hour: parseInt(value.format('HH'), 10),
                  minute: parseInt(value.format('mm'), 10),
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction={'row'} item xs={0.7} justifyContent={'end'} sx={{ paddingLeft: 'unset !important' }}>
          <HelpText classes={classes} text={help} />
        </Grid>
      </Grid>
    </>
  );
};

WaitingTime.propTypes = {
  enable: PropTypes.bool,
  setEnable: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.object,
  classes: PropTypes.object,
  waitingTime: PropTypes.object,
  setWaitingTime: PropTypes.func,
  help: PropTypes.string,
};

export default WaitingTime;
