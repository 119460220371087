import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Grid, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import useToast from 'hooks/useToast';
import useNumber from 'hooks/useNumber';
import Toast from 'components/Toast';
import NumberRow from './Row';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '10px',
    marginBottom: '12px',
    backgroundColor: 'rgba(120, 144, 156, 0.1)',
  },
  body: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    minHeight: '570px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    marginRight: '4px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
}));

function NumberTable({ numbers, headers, page, handleOpenModal, numberOfPages, handleChangePage, paginationLimit }) {
  console.log(numberOfPages)
  const classes = useStyles();
  const { update } = useNumber();
  const [sort, setSort] = React.useState('number_id-0');
  const { toast, handleSetToast, handleCloseToast } = useToast();

  const sorting = (value) => {
    const temp = sort.split('-');
    if (value === temp[0]) {
      setSort(`${value}-${Number(!Number(temp[1]))}`);
    } else {
      setSort(`${value}-0`);
    }
  };

  const toggleActive = async (number) => {
    const { id, ...data } = number;
    try {
      await update(id, { ...data, active: !data.active });
      handleSetToast({
        open: true,
        type: 'success',
        message: !data.active ? 'Activate number successfully!' : 'Deactivate number successfully!',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: '100%', boxShadow: 'unset' }}>
      <Grid container direction="row" className={classes.header} alignItems="center">
        {headers.map((header, idx) => (
          <Grid
            item
            xs={header.width}
            key={header.label}
            style={{
              flex: `${idx === headers.length - 1 ? 'unset' : '1'}`,
              paddingLeft: `${idx === 0 ? '15px' : ''}`,
              paddingTop: '16px',
              paddingBottom: '16px',
              borderRadius: `${idx === 0 ? '10px 0 0 10px' : ''}`,
              justifyContent: `${idx === 0 ? 'flex-start' : 'center'}`,
              alignItems: 'center',
              display: 'flex',
              margin: '-7.5px 0',
              cursor: `${header.canSort ? 'pointer' : 'default'}`,
              width: `${idx === headers.length - 1 ? '30px' : ''}`,
            }}
            sx={{
              color: (theme) => theme.palette.text.black,
            }}
          >
            {header.canSort ? (
              <div className={classes.sortContainer} onClick={() => sorting(header.key)} role="presentation">
                <Iconify
                  style={{ marginBottom: '-11.5px' }}
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-up-rounded"
                  className={classes.sortIcon}
                  color={`${
                    sort.split('-').includes(header.key) && sort.split('-')[1] === '0' ? '#3e4345' : '#c6cacc'
                  }`}
                />
                <Iconify
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  className={classes.sortIcon}
                  color={`${
                    sort.split('-').includes(header.key) && sort.split('-')[1] === '1' ? '#3e4345' : '#c6cacc'
                  }`}
                />
              </div>
            ) : null}
            {header.label}
          </Grid>
        ))}
      </Grid>
      <div className={classes.body}>
        {numbers
          .sort((a, b) => {
            const temp = sort.split('-');
            return temp[1] === '0'
              ? a?.[temp[0]]?.toString().localeCompare(b?.[temp[0]])
              : b?.[temp[0]]?.toString().localeCompare(a?.[temp[0]]);
          }, 0)
          .map((number, index) => (
            <NumberRow
              sort={sort}
              key={number.id}
              {...number}
              no={index + 1 + paginationLimit * (page - 1)}
              handleOpenModal={handleOpenModal}
              handleSetToast={handleSetToast}
              toggleActive={() => toggleActive(number)}
            />
          ))}
      </div>

      {numberOfPages > 1 && (
        <Grid container direction={'row'} justifyContent="center" sx={{ marginTop: '15px' }}>
          <Pagination
            className={classes.pagination}
            count={numberOfPages}
            defaultPage={1}
            boundaryCount={1}
            onChange={handleChangePage}
          />
        </Grid>
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </Box>
  );
}

NumberTable.propTypes = {
  numbers: PropTypes.array,
  headers: PropTypes.array,
  handleOpenModal: PropTypes.func,
  numberOfPages: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
};

export default NumberTable;
