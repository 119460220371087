import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCommon from 'hooks/useCommon';
import LinkTab from 'components/LinkTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/*
 * Compare between /outbound/campaigns/6287/anything with /outbound/campaigns
 */
const isCampaignChildRoute = /^\/inbound\/campaigns(?!$)(?:\/(\d+)(?:\/[^/]+)?)?$/;

/*
 * Compare between /outbound/campaigns/6287/anything with /outbound/campaigns
 */
const isScenarioChildRoute = /^\/inbound\/scenarios(?!$)(?:\/(\d+)(?:\/[^/]+)?)?$/;

const InBound = () => {
  const { t } = useTranslation('common');
  const { setParentPage, setChildName } = useCommon();
  const [value, setValue] = useState(0);
  const tabs = [
    {
      idx: 0,
      pathname: '/inbound/scenarios',
      label: t('table:scenarios'),
    },
    {
      idx: 1,
      pathname: '/inbound/monitor',
      label: t('monitor'),
    },
  ];
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.pathname === '/inbound') {
      setValue(0);
    }
  }, [location]);

  useEffect(() => {
    setParentPage(null);
    setChildName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Outbound </title>
      </Helmet>

      {!isCampaignChildRoute.test(location.pathname) && !isScenarioChildRoute.test(location.pathname) && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="primary tabs example"
              TabIndicatorProps={{ style: { height: '3px' } }}
            >
              {tabs.map((tab) => (
                <LinkTab key={tab.pathname} label={tab.label} pathname={tab.pathname} {...a11yProps(tab.idx)} />
              ))}
            </Tabs>
          </Box>

          {/* Render panel to show component */}
          {tabs.map((tab) => (
            <TabPanel key={tab.label} value={value} index={tab.idx}>
              <Outlet />
            </TabPanel>
          ))}
        </>
      )}
    </>
  );
};

export default InBound;
