import {
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { DIALOG_STATUS } from 'constants/dialog';
import { useState } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import useAudioRecord from 'hooks/useAudioRecord';

const AudioRecordModal = ({ dialogStatus, values, openModal, handleCloseModal, handleSetToast }) => {
  const { t } = useTranslation('common');
  const [file, setFile] = useState(null);
  const { create, update, destroy } = useAudioRecord();
  const schema = yup
    .object({
      name: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: { name: '', note: '' },
    values,
    resolver: yupResolver(schema),
  });

  const generateSuccessToast = (message) => {
    handleSetToast({ open: true, type: 'success', message });
  };

  const onSubmit = (data) => {
    if (dialogStatus === DIALOG_STATUS.CREATE) {
      const formData = new FormData();
      formData.append('name', data?.name);
      formData.append('note', data?.note);
      formData.append('file', file);
      create(formData);
      generateSuccessToast(t('notify:addSuccess'));
    } else if (dialogStatus === DIALOG_STATUS.UPDATE) {
      update(data.id, data);
      generateSuccessToast(t('notify:updateSuccess'));
    } else {
      destroy(data.id);
      generateSuccessToast(t('notify:deletedSuccess'));
    }
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
    reset({ name: '', note: '' });
  };

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setValue('name', selectedFile?.name, { shouldTouch: true });
    const maxFileSize = 20 * 1024 * 1024;

    if (selectedFile?.size > maxFileSize) {
      handleSetToast({ open: true, type: 'error', message: 'The file size is too large!' });
      event.target.value = '';
    }
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth="true" maxWidth="xs">
      <DialogTitle>
        {dialogStatus === DIALOG_STATUS.CREATE && t('table:uploadAudio')}
        {dialogStatus === DIALOG_STATUS.UPDATE && t('table:editAudio')}
        {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteAudio')}
      </DialogTitle>
      {dialogStatus !== DIALOG_STATUS.DELETE && (
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  margin="dense"
                  label={t('table:audioName')}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            />

              <Controller
                name="note"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                    margin="dense"
                    label={t('table:note')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={2}
                  />
                )}
              />
            {dialogStatus === DIALOG_STATUS.CREATE && (
              <Box sx={{ marginTop: '8px' }}>
                <label htmlFor="upload-file-input" style={{ cursor: 'pointer' }}>
                  <div
                    style={{
                      border: '2px dashed rgb(120, 144, 156)',
                      borderRadius: '16px',
                      textAlign: 'center',
                      padding: '12px',
                    }}
                  >
                    <input
                      accept="audio/mpeg,audio/wav"
                      id="upload-file-input"
                      type="file"
                      autoComplete="off"
                      tabIndex="-1"
                      style={{ display: 'none' }}
                      onChange={handleFileSelect}
                    />
                    <Stack direction={'row'} gap={2}>
                      <PublishIcon sx={{ color: 'text.disabled' }} />
                      <Typography noWrap>{file?.name || 'Drag or upload'}</Typography>
                    </Stack>
                  </div>
                </label>
              </Box>
            )}
          </form>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {dialogStatus === DIALOG_STATUS.DELETE
            ? t('table:delete')
            : dialogStatus === DIALOG_STATUS.UPDATE
            ? t('table:update')
            : t('table:uploadAudio')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AudioRecordModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleSetToast: PropTypes.func,
};

export default AudioRecordModal;
