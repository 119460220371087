import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DateRangeSelector from 'components/DateRangeSelector';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import TimeRangeSelector from 'components/TimeRangeSelector';
import { Box } from '@mui/material';
import useCommon from 'hooks/useCommon';
import PropTypes from 'prop-types';

function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return moment(date).format('MM/DD/YYYY');
}

function formatTimeDisplay(time, defaultText) {
  if (!time) return defaultText;
  return moment(time).format('HH:mm');
}

const useStyles = makeStyles((theme) => ({
  timeRange: {
    border: '1px solid #c6cacc',
    borderRadius: '30px',
    padding: '6px 10px',
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#78909c',
    },
  },
  pr10: {
    paddingRight: '10px',
  },
  px10: {
    padding: '0 10px',
  },
  datePicker: {
    position: 'absolute',
    top: '40px',
    background: '#fff',
    boxShadow: '2px 5px 6px 3px #bdbdbd78',
    borderRadius: '8px',
    zIndex: '1000',
  },
  buttonGroup: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #d8d8d8',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
}));

const DateTimeRange = ({
  setTriggerFilterByDate,
  selectedTimeRange,
  setSelectedTimeRange,
  selectedDateRange,
  setSelectedDateRange,
}) => {
  const classes = useStyles();
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectDateMode, setSelectDateMode] = useState('date');

  const toggleMode = () => {
    const newMode = selectDateMode === 'date' ? 'time' : 'date';
    setSelectDateMode(newMode);
  };

  const onClickOK = () => {
    setTriggerFilterByDate((prev) => !prev);
    setOpenDateRange(false);
  };
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <span className={classes.timeRange} onClick={() => setOpenDateRange((prev) => !prev)}>
          <span className={classes.pr10}>{`${formatDateDisplay(selectedDateRange.startDate)}, ${formatTimeDisplay(
            selectedTimeRange.startTime
          )}`}</span>
          <span>to</span>
          <span className={classes.px10}>{`${formatDateDisplay(selectedDateRange.endDate)}, ${formatTimeDisplay(
            selectedTimeRange.endTime
          )}`}</span>
          <Iconify icon="material-symbols:calendar-today" color="#78909c" />
        </span>
        {openDateRange && (
          <div className={classes.datePicker}>
            {selectDateMode === 'time' && (
              <TimeRangeSelector
                pickedStart={selectedTimeRange.startTime}
                pickedEnd={selectedTimeRange.endTime}
                onChange={setSelectedTimeRange}
              />
            )}
            {selectDateMode === 'date' && (
              <DateRangeSelector selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
            )}
            <div className={classes.buttonGroup}>
              <button
                type="button"
                style={{ marginRight: '8px', color: '#78909c', background: '#fff' }}
                onClick={toggleMode}
                className={classes.button}
              >
                {selectDateMode === 'time' ? 'Select date' : 'Select time'}
              </button>
              <button type="button" onClick={onClickOK} className={classes.button}>
                OK
              </button>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

DateTimeRange.propTypes = {
  selectedTimeRange: PropTypes.object,
  selectedDateRange: PropTypes.object,
  setSelectedTimeRange: PropTypes.func,
  setSelectedDateRange: PropTypes.func,
  setTriggerFilterByDate: PropTypes.func,
};
export default DateTimeRange;
