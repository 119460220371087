// eslint-disable-next-line import/no-self-import
import axios from 'axios';
import { apiUrlDetect } from 'utils/domain';
import { getToken } from '../utils/auth';

const domain = window.location.hostname
const baseUrl = apiUrlDetect[domain];

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
