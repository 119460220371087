import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import useUser from 'hooks/useUser';
import { useMemo, useEffect } from 'react';
// @mui
import { Button, TextField, DialogTitle, Dialog, DialogContent, DialogActions, MenuItem } from '@mui/material';
// const
import { DIALOG_STATUS } from '../../../constants/dialog';

export default function UserModal({
  dialogStatus,
  values,
  openModal,
  handleCloseModal,
  handleSetToast,
  availableData,
}) {
  const { t } = useTranslation('common');
  const { create, update, destroy } = useUser();
  const roles = useMemo(() => availableData.roles, [availableData.roles]);
  const createSchema = yup
    .object({
      role: yup.string().required(t('error:roleNameRequired')),
      user_name: yup.string().required(t('error:usernameRequired')),
      email: yup.string().required(t('error:emailRequired')),
      password: yup.string().min(8, t('error:passwordLength')).required(t('error:passwordLength')),
    })
    .required();

  const updateSchema = yup.object().shape({
    role: yup.string().required(t('error:roleNameRequired')),
    email: yup.string().required(t('error:emailRequired')),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { role: '', user_name: '', password: '', name: '', email: '' },
    values,
    resolver: dialogStatus === DIALOG_STATUS.CREATE ? yupResolver(createSchema) : yupResolver(updateSchema),
  });

  const generateSuccessToast = (message) => {
    handleSetToast({ open: true, type: 'success', message });
  };

  const onSubmit = (data) => {
    if (dialogStatus === DIALOG_STATUS.CREATE) {
      create(data);
      generateSuccessToast(t('notify:addSuccess'));
    } else if (dialogStatus === DIALOG_STATUS.UPDATE) {
      update(data.id, data);
      generateSuccessToast(t('notify:updateSuccess'));
    } else {
      destroy(data.id);
      generateSuccessToast(t('notify:deletedSuccess'));
    }
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
    reset({ role: '', user_name: '', password: '', name: '', email: '' });
  };

  useEffect(() => {
    console.log('values', values);
  }, []);

  return (
    <Dialog open={openModal} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        {dialogStatus === DIALOG_STATUS.CREATE && t('table:createUser')}
        {dialogStatus === DIALOG_STATUS.UPDATE && t('table:updateUser')}
        {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteUser')}
      </DialogTitle>
      {dialogStatus !== DIALOG_STATUS.DELETE && (
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.role}
                  helperText={errors?.role?.message}
                  margin="dense"
                  label={t('table:roleName')}
                  select
                  fullWidth
                  variant="outlined"
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {dialogStatus === DIALOG_STATUS.CREATE && (
              <Controller
                name="user_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.user_name}
                    helperText={errors?.user_name?.message}
                    margin="dense"
                    label={t('table:username')}
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            )}
            {dialogStatus === DIALOG_STATUS.CREATE && (
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    margin="dense"
                    label={t('table:password')}
                    type="password"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            )}
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} margin="dense" label={t('table:name')} type="text" fullWidth variant="outlined" />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label={t('table:email')}
                  type="email"
                  fullWidth
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </form>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>{t('table:cancel')}</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {dialogStatus === DIALOG_STATUS.DELETE
            ? t('table:delete')
            : dialogStatus === DIALOG_STATUS.UPDATE
            ? t('table:update')
            : t('table:add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleSetToast: PropTypes.func,
  availableData: PropTypes.object,
};
