import { createContext, useReducer } from 'react';
import roleService from 'services/role.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  roles: [],
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ROLES': {
      const { roles, total } = action.payload;
      return { ...state, roles, total };
    }
    case 'CREATE_ROLE': {
      const { role } = action.payload;
      return { ...state, roles: state.roles.concat([role]), total: state.total + 1 };
    }
    case 'UPDATE_ROLE': {
      const { role } = action.payload;
      const updatedRoles = replaceItemInArrayByKey(state.roles, 'id', role);
      return { ...state, roles: updatedRoles };
    }
    case 'DELETE_ROLE': {
      const { id } = action.payload;
      return { ...state, roles: state.roles.filter((role) => role.id !== id), total: state.total - 1 };
    }

    default:
      return { ...state };
  }
};

const RoleContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const RoleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const { data: roles, meta } = await roleService.get(params);
      dispatch({
        type: 'SET_ROLES',
        payload: {
          roles,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: role } = await roleService.create(data);
      dispatch({
        type: 'CREATE_ROLE',
        payload: {
          role,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: role } = await roleService.update(id, data);
      dispatch({
        type: 'UPDATE_ROLE',
        payload: {
          role,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await roleService.delete(id);
      dispatch({
        type: 'DELETE_ROLE',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <RoleContext.Provider value={{ ...state, method: 'Role', get, create, update, destroy }}>
      {children}
    </RoleContext.Provider>
  );
};

RoleProvider.propTypes = {
  children: PropTypes.node,
};

export default RoleContext;
