import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { Card, Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, Chip } from '@mui/material';
// hooks
import useUser from 'hooks/useUser';
// components
import Scrollbar from 'components/scrollbar';
// sections
import { ListHead } from 'sections/@dashboard/user';
// const

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'package', label: 'Package', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Deposit() {
  const { t } = useTranslation('common');
  const { users, total, get: getUsers } = useUser();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = page > 1 ? Math.max(0, rowsPerPage - users.length) : 0;
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const handleGetUsers = async () => {
      await getUsers({ page, limit: rowsPerPage });
    };

    try {
      handleGetUsers();
    } catch (err) {
      console.log(err);
    }
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('deposit')} </title>
      </Helmet>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.map((row) => {
                  const { id, user_name, role, name, email } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{user_name}</TableCell>

                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">{email}</TableCell>

                      <TableCell align="left">
                        <Chip
                          label={role}
                          sx={{ color: '#fff', backgroundColor: id === 1 ? 'success.darker' : 'error.dark' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
