import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';

const useStyles = makeStyles(() => ({
  list: {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: '0',
    width: '228px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  item: {
    margin: 0,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
  },
}));

const Select = ({
  list,
  handleResetFilter,
  handleChooseFilterValue,
  selectedFilterValue,
  handleRemoveFilterValue,
  handleClose,
}) => {
  const classes = useStyles();

  const handleClick = (label) => {
    if (selectedFilterValue) {
      if (selectedFilterValue.value === label) {
        handleRemoveFilterValue(label);
      } else {
        handleChooseFilterValue(label);
      }
    } else {
      handleChooseFilterValue(label);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <List className={classes.list}>
        <ListItemButton key={'Reset Filter'} className={classes.item} onClick={handleResetFilter}>
          <ListItemText>Reset Filter</ListItemText>
        </ListItemButton>
        {list.map((l) => (
          <ListItemButton key={l.label} className={classes.item} onClick={() => handleClick(l.value)}>
            <ListItemText sx={{ color: `${selectedFilterValue?.value === l.value ? '#3366ff' : ''}` }}>
              {l.label}
            </ListItemText>
            {selectedFilterValue?.value === l.value && <Iconify icon="material-symbols:check-small" color="#3366ff" />}
          </ListItemButton>
        ))}
      </List>
    </ClickAwayListener>
  );
};

Select.propTypes = {
  list: PropTypes.array,
  selectedFilterValue: PropTypes.array,
  handleResetFilter: PropTypes.func,
  handleChooseFilterValue: PropTypes.func,
  handleRemoveFilterValue: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Select;
