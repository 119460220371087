import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
// import PieChart, {
//   Font,
//   Title,
//   Series,
//   Label,
//   Connector,
//   Size,
//   Export,
// } from 'devextreme-react/pie-chart';
import PropTypes from 'prop-types';

const rootContainerId = 'widget-container';

const ChartReport = ({ title, value }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const mappedValue = Object.keys(value).map((el) => ({ key: el, value: value[el] }));
    setData(mappedValue);
  }, [value]);

  return (
    <Paper
      id={rootContainerId}
      sx={{
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        height: '420px'
      }}
    >
      {/* <PieChart
        id="pie"
        dataSource={data}
        palette="Soft Pastel"
        type="doughnut"
      >
        <Title text={title}>
          <Font size={24} weight={500} color={'#000'}/>
        </Title>
        <Series
          argumentField="key"
          valueField="value"
        >
          <Label visible>
            <Connector visible width={1} />
          </Label>
        </Series>

        <Size width={520} />
        <Export enabled />
      </PieChart> */}
    </Paper>
  );
};

ChartReport.propType = {
  title: PropTypes.string,
  value: PropTypes.object,
}

export default ChartReport;
