import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { loggings } from '_mock/logging';

import DateTimeRange from 'components/DateTimeRange';
import NotFound from 'components/NotFound';
import { conversations } from '_mock/conversation';
import ConversationNotfound from 'assests/images/conversation_notfound.svg';
// import Statistics from '../CampaignReport/Statistics';
import LoggingTable from './LoggingTable';

const AuditLog = () => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container direction="row" xs={10}>
          <DateTimeRange
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </Grid>
        <Grid container item xs={12} sx={{ padding: '10px' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {conversations.length === 0 ? (
              <NotFound title="You don't have any Conversation yet!" image={ConversationNotfound} />
            ) : (
              <LoggingTable logs={loggings.events} maxHeight={'calc(100vh - 295px)'}/>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

AuditLog.propTypes = {};

export default AuditLog;
