import React from 'react';
import PropTypes from 'prop-types';
import Iconify from 'components/iconify/Iconify';
import { Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import moment from 'moment';
import { formatVndCurrency } from 'utils/currency';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px',
    background: '#f7f9fa',
    margin: '10px 10px 0 10px',
    marginTop: '12px',
    borderRadius: '12px',
  },
  item: {
    display: 'flex',
    height: '80px',
    padding: '8px 16px 16px 8px',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(8,35,48,0.1)',
    borderRadius: '8px',
    justifyContent: 'space-between',
    boxSizing: 'content-box',
  },
  statistic: {
    padding: '10px',
    minWidth: '60px',
    marginBottom: '5px',
  },
  statisticNum: {
    fontSize: '20px',
  },
  statisticLabel: {
    color: '#78909c',
    fontSize: '14px',
  },
}));

const Statistics = ({ contact_called, call_success, call_failed, contact_left, cost, duration }) => {
  const { t } = useTranslation('common');

  const classes = useStyles();
  const theme = useTheme();

  const formatAverage = (minute) => {
    let h;
    let m = minute;
    if (minute / 60 < 1) {
      h = 0;
    } else {
      h = Math.round(minute / 60);
    }
    m = minute - 60 * h;

    const date = new Date();
    date.setHours(h);
    date.setMinutes(m);

    return moment(date).format('HH:mm');
  };

  return (
    <>
      <Grid container direction="row" className={classes.container} justifyContent="space-between">
        <Grid item xs={5} className={classes.item}>
          <div style={{ padding: '10px' }}>
            <Iconify icon="material-symbols:bar-chart" width={24} height={24} color={theme.palette.text.grey} />
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.grey }}>
              {contact_called}
            </div>
            <div className={classes.statisticLabel}>{t('total')}</div>
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.green }}>
              {call_success}
            </div>
            <div className={classes.statisticLabel}>{t('success')}</div>
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.danger }}>
              {call_failed}
            </div>
            <div className={classes.statisticLabel}>{t('failed')}</div>
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.grey }}>
              {contact_left}
            </div>
            <div className={classes.statisticLabel}>{t('left')}</div>
          </div>
        </Grid>

        <Grid item xs={3} className={classes.item} sx={{ justifyContent: 'flex-start !important' }}>
          <div style={{ padding: '10px' }}>
            <Iconify icon="mdi:clock-outline" width={24} height={24} color={theme.palette.text.grey} />
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.grey }}>
              {duration}
            </div>
            <div className={classes.statisticLabel}>{t('average')}</div>
          </div>
        </Grid>

        <Grid item xs={3} className={classes.item} sx={{ justifyContent: 'flex-start !important' }}>
          <div style={{ padding: '10px' }}>
            <Iconify
              icon="material-symbols:account-balance-wallet"
              width={24}
              height={24}
              color={theme.palette.text.grey}
            />
          </div>
          <div className={classes.statistic}>
            <div className={classes.statisticNum} style={{ color: theme.palette.text.grey }}>
              {`${cost}đ` || '-'}
            </div>
            <div className={classes.statisticLabel}>{t('spent')}</div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

Statistics.propTypes = {
  call_information: PropTypes.object,
};

export default Statistics;
