import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import i18n from 'i18next';
import authService from 'services/auth.service';
import users from '_mock/user';
import account from '_mock/account';
import LoadingScreen from 'components/LoadingScreen';
import PropTypes from 'prop-types';
import userService from 'services/user.service';

const initialAppState = {
  isAuthenticated: false,
  isInitialised: false,
  language: 'en',
  user: account,
  users,
  permissions: [],
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return !decoded.exp || decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('my_app_token', accessToken);
  } else {
    localStorage.removeItem('my_app_token');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, language } = action.payload;
      const permissions = user ? user.permissions : [];

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        permissions,
        language,
      };
    }
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    case 'LOGIN': {
      const { user, isAuthenticated } = action.payload;
      const permissions = user ? user.permissions : [];
      return { ...state, user, isAuthenticated, permissions };
    }
    case 'LOGOUT': {
      return { ...state, user: null, isAuthenticated: false, permissions: [] };
    }

    default:
      return { ...state };
  }
};

const AuthContext = createContext({
  ...initialAppState,
  setLanguage: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const setLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    dispatch({ type: 'SET_LANGUAGE', payload: lang });
    localStorage.setItem('i18nDefaultLanguage', lang);
  };

  const login = async ({ user_name, password }) => {
    try {
      const response = await authService.login({ user_name, password });

      const { user, token } = response;

      if (isValidToken(token)) {
        setSession(token);
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          isAuthenticated: true,
        },
      });
    } catch (error) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
          isAuthenticated: false,
        },
      });

      throw new Error(error.message);
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  };

  const changePassword = async (data) => {
    await userService.changePassword(data);
  };

  const initialise = async () => {
    try {
      const accessToken = window.localStorage.getItem('my_app_token');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const { data: currentUser } = await authService.getCurrentUser();

        const currentLanguage = localStorage.getItem('i18nDefaultLanguage') || 'en';

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            user: currentUser,
            language: currentLanguage,
          },
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            language: 'en',
            user: null,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initialise();

    return () => {};
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider value={{ ...state, method: 'App', setLanguage, login, logout, changePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
