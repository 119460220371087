import React from 'react';
import Welcome from 'assests/images/call-center/intercall-mascot-2.png';

const Overview = () => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <img src={Welcome} alt="wecome" />
    <h2 style={{ fontWeight: 600, marginBottom: 0 }}>Welcome to Call Center</h2>
    <p style={{ fontSize: 21 }}>
      Build smart IVRs, launch automated call campaigns and make trigger outbound calls with our visual flow builder to
      improve customer experience in no time.
    </p>
  </div>
);

export default Overview;
