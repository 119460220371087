import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
// @mui
import { makeStyles } from '@mui/styles';
import { Grid, Stack, Button, Divider, List, ListItemButton, ListItemText, Popover, MenuItem } from '@mui/material';
// hooks
import useScenario from 'hooks/useScenario';
import useToast from 'hooks/useToast';

import { PAGINATION } from 'constants';
// components
import SearchBar from 'components/Search';
import Toast from 'components/Toast';
import Table from 'components/Table';
import Iconify from '../../components/iconify';
import NumberGroupModal from './NumberGroupModal';

import { DIALOG_STATUS } from '../../constants/dialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '7.5px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function NumberGroupPage() {
  const { t } = useTranslation('common');
  const { scenarios, total, get, create, update, destroy } = useScenario();
  const classes = useStyles();
  const [openAction, setOpenAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const [selectedRow, setSelectedRow] = useState(null);
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const headers = [
    {
      label: 'ID',
      canSort: true,
      width: 1,
    },
    {
      label: t('table:name'),
      canSort: true,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) setSelectedRow(null);
    setDialogStatus(status);
    setOpenModal(true);
  };

  const handleOpenMenu = (event, row) => {
    setSelectedRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenAction(null);
  };

  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const handleGetUsers = async () => {
      await get({ page: 0, limit: PAGINATION.LIMIT });
    };

    try {
      handleGetUsers();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{sentenceCase('manage-number-group')}</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchBar />
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
          onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
        >
          {t('table:add')}
        </Button>
      </Stack>

      <Table total={total} get={get} headers={headers}>
        {scenarios.map((scenario) => (
          <Grid
            key={scenario.id}
            container
            direction={'row'}
            alignContent={'center'}
            style={{ borderBottom: '#e3e5e6 1px solid' }}
          >
            <Grid item className={classes.row} xs={1}>
              <div className={classes.content}>{scenario?.id}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{scenario?.name}</div>
            </Grid>
            <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
              <Button className={classes.button} onClick={(event) => handleOpenMenu(event, scenario)}>
                <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </Table>

      <Popover
        open={openAction}
        anchorEl={openAction}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.UPDATE)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:update')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.DELETE)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      {openModal && (
        <NumberGroupModal
          dialogStatus={dialogStatus}
          openModal={openModal}
          values={selectedRow}
          handleCloseModal={handleCloseModal}
          handleSetToast={handleSetToast}
        />
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
