import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Pagination } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import { useTranslation } from 'react-i18next';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '10px',
    marginBottom: '12px',
    backgroundColor: 'rgba(120, 144, 156, 0.1)',
  },
  body: {
    width: '100%',
    maxHeight: 'calc(100vh - 505px)',
    minHeight: '425px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    marginRight: '4px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
}));

const LoggingTable = ({ maxHeight, logs }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const headers = [
    {
      label: 'ID',
      canSort: true,
      width: 1,
    },
    {
      label: t('table:createAt'),
      canSort: true,
    },
    {
      label: t('table:userId'),
      canSort: true,
    },
    {
      label: t('table:name'),
      canSort: false,
    },
    {
      label: t('table:eventType'),
      canSort: true,
    },
    {
      label: t('table:message'),
      canSort: false,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container direction="row" className={classes.header} alignItems="center">
        {headers.map((header, idx) => (
          <Grid
            item
            xs={header.width}
            key={header.label}
            style={{
              flex: `${idx === headers.length - 1 ? 'unset' : '1'}`,
              paddingLeft: `${idx === 0 ? '15px' : ''}`,
              paddingTop: '16px',
              paddingBottom: '16px',
              borderRadius: `${idx === 0 ? '10px 0 0 10px' : ''}`,
              justifyContent: `${idx === 0 ? 'flex-start' : 'center'}`,
              alignItems: 'center',
              display: 'flex',
              margin: '-7.5px 0',
              cursor: `${header.canSort ? 'pointer' : 'default'}`,
              width: `${idx === headers.length - 1 ? '30px' : ''}`,
            }}
            sx={{
              color: (theme) => theme.palette.text.black,
            }}
          >
            {header.canSort ? (
              <div className={classes.sortContainer}>
                <Iconify
                  style={{ marginBottom: '-11.5px' }}
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-up-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
                <Iconify
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
              </div>
            ) : null}
            {header.label}
          </Grid>
        ))}
      </Grid>
      <div className={classes.body} style={{ maxHeight }}>
        {logs.map((log) => (
          <Row key={log.event_id} {...log} />
        ))}
      </div>

      <Grid container direction={'row'} justifyContent="center" sx={{ marginTop: '15px' }}>
        <Pagination className={classes.pagination} count={1977} defaultPage={1} boundaryCount={1} />
      </Grid>
    </Box>
  );
};

LoggingTable.propTypes = {
  maxHeight: PropTypes.string,
  logs: PropTypes.array,
};

export default LoggingTable;
