import React, { useMemo } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'components/iconify/Iconify';
import { translateDayToVni } from '../../CampaignPage/CampaignModal/components/Summary';

const CampaignTooltip = ({
  index,
  openTooltip,
  handleTooltipClose,
  styles,
  handleTooltipOpen,
  data,
  formatUnixTime,
}) => {
  const {
    name,
    id,
    scenario,
    start_time,
    end_time,
    number,
    call_days,
    working_hours,
    working_hours_active,
    number_phone_lines,
    number_of_attempts,
    interval,
    end_time_active,
  } = data;

  const call_day_formatted = useMemo(() => {
    if (!call_days) return [];
    if (typeof call_days === 'string') {
      return JSON.parse(call_days).map((el) => translateDayToVni(el));
    }
    return call_days.map((el) => translateDayToVni(el));
  }, [call_days]);
  const working_hours_formatted = working_hours || {};

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openTooltip === index}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  width: '452px',
                  padding: '24px',
                  fontSize: '14px',
                  maxWidth: '600px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  backgroundColor: '#3e4345',
                },
              },
            }}
            title={
              <div
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                role="button"
                tabIndex="0"
              >
                <div>
                  <span className={styles.tooltipTitle}>{name}</span>
                </div>
                <div className={styles.tooltipSmall}>ID: {id}</div>
                <div className={styles.tooltipSmall}>
                  Scenario: <span>{scenario.name}</span>
                </div>
                <div>
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Start</div>
                    <div className={styles.tooltipContent}>{formatUnixTime(start_time)}</div>
                  </div>
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>End</div>
                    <div className={styles.tooltipContent}>
                      {end_time_active === 0 ? '-' : formatUnixTime(end_time)}
                    </div>
                  </div>
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Number of phone lines</div>
                    <div className={styles.tooltipContent}>{number_phone_lines}</div>
                  </div>
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Number of attempts</div>
                    <div className={styles.tooltipContent}>{number_of_attempts} - {interval}m</div>
                  </div>
                  {/* <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Interval</div>
                    <div className={styles.tooltipContent}>{interval}</div>
                  </div> */}
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Phone number</div>
                    <div className={styles.tooltipContent}>{number?.number || '-'}</div>
                  </div>
                  <div className={styles.tooltipInfo}>
                    <div className={styles.tooltipLabel}>Working hours</div>
                    <div className={styles.tooltipContent}>
                      {working_hours.length && !!working_hours_active
                        ? working_hours.map((item) => {
                            if (item?.join) {
                              return <div key={crypto.randomUUID()}>{item.join(' - ')}</div>;
                            }
                            return item;
                          })
                        : '-'}
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <Iconify
              icon="mdi:warning-circle-outline"
              sx={{ color: 'text.disabled', width: 20, height: 20, cursor: 'pointer', marginTop: '5px' }}
              onClick={(e) => handleTooltipOpen(e, index)}
            />
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

CampaignTooltip.propTypes = {
  index: PropTypes.number,
  openTooltip: PropTypes.bool,
  handleTooltipClose: PropTypes.func,
  styles: PropTypes.object,
  handleTooltipOpen: PropTypes.func,
  data: PropTypes.object,
  formatUnixTime: PropTypes.func,
};

export default CampaignTooltip;
