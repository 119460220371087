import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
// @mui
import {
  Button,
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { callPlan } from '_mock/callPlan';
import { callRule } from '_mock/callRule';
import { makeStyles } from '@mui/styles';
import useNumber from 'hooks/useNumber';
import { useEffect } from 'react';
import HelpText from '../HelpText';

const useStyles = makeStyles(() => ({
  selectChips: {
    width: '100%',
    '& .MuiSelect-select': {
      backgroundColor: '#fff',
    },
  },
  icon: {
    color: '#78909c',
    width: 15,
    height: 15,
    paddingLeft: 'unset',
    cursor: 'pointer',
  },
}));

const formControlSxProps = { marginTop: 1, marginBottom: 0.5, '& .MuiInputBase-root': { background: '#fff' } };

function CallPlan({ data, setData, handleNext, handleBack }) {
  const { t } = useTranslation('common');
  const { numbers, get: getPhoneNumbers } = useNumber();
  const classes = useStyles();

  const leftItems = [
    {
      name: 'call_plan_type',
      menuItems: callPlan,
      help: 'Call type',
    },
    {
      name: 'number_id',
      menuItems: numbers.map((item) => ({ value: item.id, label: item.number })),
      help: 'The phone number you bought and verified, will be used to call your customers.',
    },
  ];

  const rightItems = [
    {
      name: 'call_rule',
      menuItems: callRule,
      help: 'Call rule',
    },
    {
      name: 'sub_number_id',
      menuItems: numbers.map((item) => ({ value: item.id, label: item.number })),
      help: 'The sub phone number you bought and verified, will be used to call your customers.',
    },
  ];

  const planStepSchema = yup
    .object({
      call_plan_type: yup.string().required(t('error:required')),
      call_rule: yup.string().required(t('error:required')),
      number_id: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      call_rule: 0,
      call_plan_type: 0,
      ...data,
    },
    resolver: yupResolver(planStepSchema),
  });

  const handleChange = (event, name) => {
    const {
      target: { value },
    } = event;

    setValue(name, value);
    setData(name, value);
  };

  useEffect(() => {
    const handleGetPhoneNumbers = async () => {
      await getPhoneNumbers({ active: 1 });
    };

    handleGetPhoneNumbers();
  }, []);

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px' }}>
        <Typography sx={{ color: '#78909c' }} variant="subtitle2">
          {t(`table:callplan`)}
        </Typography>

        <form onSubmit={handleSubmit(handleNext)}>
          <Grid container spacing={8}>
            {/* Left column */}
            <Grid item xs={6} direction="column" alignItems="center" justifyContent="center">
              {leftItems.map((item) => {
                const { name, menuItems, help } = item;

                return (
                  <Grid key={name} item container xs={6} direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={11.3}>
                      <Controller
                        name={name}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={data[name] || ''}
                        render={({ field }) => (
                          <FormControl fullWidth size="normal" error={!!errors[name]} sx={formControlSxProps}>
                            <InputLabel id="demo-simple-select-label">{t(`table:${name}`)}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={t(`table:${name}`)}
                              {...field}
                              inputProps={{
                                name,
                                id: `${name}-helper-text`,
                              }}
                              defaultValue={''}
                              onChange={(event) => handleChange(event, name)}
                            >
                              {menuItems.map((mItem) => (
                                <MenuItem key={mItem.value} value={mItem.value}>
                                  {mItem.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{errors[name]?.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid
                      container
                      direction={'row'}
                      item
                      xs={0.7}
                      justifyContent={'end'}
                      sx={{ paddingLeft: 'unset !important' }}
                    >
                      <HelpText classes={classes} text={help} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            {/* Right column */}
            <Grid item xs={6} direction="column" alignItems="center" justifyContent="center">
              {rightItems.map((item) => {
                const { name, menuItems, help } = item;

                return (
                  <Grid key={name} item container xs={6} direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={11.3}>
                      <Controller
                        name={name}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={data[name] || ''}
                        render={({ field }) => (
                          <FormControl fullWidth size="normal" error={!!errors[name]} sx={formControlSxProps}>
                            <InputLabel id="demo-simple-select-label">{t(`table:${name}`)}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={t(`table:${name}`)}
                              {...field}
                              inputProps={{
                                name,
                                id: `${name}-helper-text`,
                              }}
                              defaultValue={''}
                              onChange={(event) => handleChange(event, name)}
                            >
                              {menuItems.map((mItem) => (
                                <MenuItem key={mItem.value} value={mItem.value}>
                                  {mItem.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{errors[name]?.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid
                      container
                      direction={'row'}
                      item
                      xs={0.7}
                      justifyContent={'end'}
                      sx={{ paddingLeft: 'unset !important' }}
                    >
                      <HelpText classes={classes} text={help} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box
        sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', textAlign: 'right' }}
      >
        <Button
          sx={{
            ':hover': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#d5d5d5',
            },
            backgroundColor: '#e0e0e0',
            marginRight: '10px',
            color: 'rgba(0, 0, 0, 0.87);',
          }}
          variant="contained"
          onClick={handleBack}
        >
          {t('back')}
        </Button>
        <Button variant="contained" type="submit" onClick={handleSubmit(handleNext)}>
          {t('next')}
        </Button>
      </Box>
    </>
  );
}

export default CallPlan;

CallPlan.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  setData: PropTypes.func,
};
