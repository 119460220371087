import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, List, ListItemButton, ListItemText, Switch } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { publication } from '_mock/scenario';
import Iconify from 'components/iconify/Iconify';
import EditScenario from '../ScenarioModal/EditScenario';
import ScenarioModal from '../ScenarioModal';
import Versions from '../ScenarioModal/Versions';
import Copy from '../ScenarioModal/Copy';
import ConfirmDelete from '../ScenarioModal/ConfirmDelete';
import Test from '../ScenarioModal/Test';

export const formatDate = (date) => moment(date).format('MM/DD/YYY, HH:mm');

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '7.5px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

const scenaioTypes = {
  0: 'Inbound',
  1: 'Outbound',
  2: 'Inbound/Outbound',
};

const upperOptions = [
  {
    name: 'Edit Scenario',
    title: 'editScenario',
    id: 1,
  },
  {
    name: 'Draw Scenario',
    title: 'drawScenario',
    id: 2,
  },
  // {
  //   name: 'Show versions',
  //   title: 'showVersions',
  //   id: 3,
  //   width: 388,
  //   height: 350,
  // },
  {
    name: 'Copy',
    title: 'copy',
    id: 4,
  },
];

const lowerOptions = [
  {
    name: 'Delete',
    title: 'delete',
    id: 5,
  },
];

const ScenarioRow = ({ id, name, scenario_publication, type, dialplan, status, handleEnable, handleSetToast }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [selectedOption, setSelectedOption] = useState();
  const [openAction, setOpenAction] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const handleClose = () => {
    setSelectedOption();
    setIsTesting(false);
  };

  const handleTestScenario = () => {
    setIsTesting(true);
  };

  const renderModal = useCallback(
    (option) => {
      switch (option) {
        case 'Edit Scenario': {
          return (
            <EditScenario data={{ id, name, type, dialplan }} onClose={handleClose} handleSetToast={handleSetToast} />
          );
        }
        case 'Draw Scenario': {
          return null;
        }
        case 'Show versions': {
          return (
            <Versions
              versions={publication.scenario_publications}
              onSubmit={(data) => {
                console.log('data');
              }}
              onClose={handleClose}
            />
          );
        }
        case 'Copy': {
          return <Copy data={{ name }} onClose={handleClose} handleSetToast={handleSetToast} />;
        }
        case 'Delete': {
          return <ConfirmDelete data={{ id, name, type }} onClose={handleClose} handleSetToast={handleSetToast} />;
        }
        default:
          return null;
      }
    },
    [name, type]
  );

  const modalTitle = useMemo(() => {
    if (isTesting) return 'testScenario';
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return upperOptions.find((el) => el.id === selectedOption).title;
    }
    return lowerOptions.find((el) => el.id === selectedOption).title;
  }, [selectedOption, isTesting]);

  const modalComponent = useMemo(() => {
    if (isTesting) return <Test id={id} onClose={handleClose} handleSetToast={handleSetToast} />;
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return renderModal(upperOptions.find((el) => el.id === selectedOption).name);
    }

    return renderModal(lowerOptions.find((el) => el.id === selectedOption).name);
  }, [renderModal, selectedOption, isTesting]);

  const modalWidth = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return upperOptions.find((el) => el.id === selectedOption).width;
    }
    return lowerOptions.find((el) => el.id === selectedOption).width;
  }, [selectedOption]);

  const modalHeight = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return upperOptions.find((el) => el.id === selectedOption).height;
    }
    return lowerOptions.find((el) => el.id === selectedOption).height;
  }, [selectedOption]);

  return (
    <>
      <Grid container direction={'row'} alignContent={'center'} style={{ borderBottom: '#e3e5e6 1px solid' }}>
        <Grid item className={classes.row} xs={1}>
          <div className={classes.content}>{id}</div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>{name}</div>
        </Grid>
        <Grid item className={classes.row} xs={2}>
          <div className={classes.content}>{scenaioTypes[type]}</div>
        </Grid>
        <Grid item className={classes.row} xs={1}>
          <div className={classes.content}>
            <Switch checked={status} onChange={handleEnable} />
          </div>
        </Grid>
        <Grid item className={classes.row} xs={2}>
          <div className={classes.content}>
            <Button
              variant="contained"
              color="info"
              sx={{ height: 28, fontWeight: 400, fontSize: 12 }}
              onClick={handleTestScenario}
            >
              {t('test')}
            </Button>
          </div>
        </Grid>
        <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
          <Button className={classes.button} onClick={() => setOpenAction((prev) => !prev)}>
            <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />

            {openAction ? (
              <ClickAwayListener onClickAway={() => setOpenAction((prev) => !prev)}>
                <List className={classes.listAction}>
                  {upperOptions.map((el) => (
                    <ListItemButton
                      key={el.name}
                      className={classes.listActionItem}
                      onClick={() => setSelectedOption(el.id)}
                    >
                      <ListItemText>{t(`table:${el.title}`)}</ListItemText>
                    </ListItemButton>
                  ))}
                  <Divider />
                  {lowerOptions.map((el) => (
                    <ListItemButton
                      key={el.name}
                      className={classes.listActionItem}
                      onClick={() => setSelectedOption(el.id)}
                    >
                      <ListItemText>{t(`table:${el.title}`)}</ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </ClickAwayListener>
            ) : null}
          </Button>
        </Grid>
      </Grid>

      {(!!selectedOption || isTesting) && (
        <ScenarioModal
          title={t(`table:${modalTitle}`)}
          openModal
          handleCloseModal={handleClose}
          width={modalWidth}
          maxHeight={modalHeight}
        >
          {modalComponent}
        </ScenarioModal>
      )}
    </>
  );
};

ScenarioRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  scenario_publication: PropTypes.string,
  dialplan: PropTypes.string,
  type: PropTypes.number,
  status: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  handleEnable: PropTypes.func,
};

export default ScenarioRow;
