import { useState, useRef, useEffect } from 'react';
import { FormControl, OutlinedInput, Box, Grid, InputAdornment, ClickAwayListener, MenuItem } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import Iconify from '../iconify';

const TimePicker = ({ verticalAlign = 'bottom', right, value, setValue }) => {
  const [open, setOpen] = useState(false);
  const [boxLeft, setBoxLeft] = useState(0);
  const boxRef = useRef(null);
  const inputRef = useRef(null);
  const inputHeight = inputRef.current?.getBoundingClientRect().height;
  const hours = [...Array(24).keys()];
  const minutes = [...Array(60).keys()];

  const handleOpen = () => {
    if (!inputRef.current.disabled) {
      setOpen(!open);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (boxRef.current) {
      setBoxLeft(boxRef.current.offsetWidth / 2);
    }
  }, [open]);

  return (
    <div style={{ display: 'inline-block', cursor: 'pointer' }}>
      <ClickAwayListener onClickAway={handleClose}>
        <FormControl sx={{ m: 1 }} variant="outlined" ref={inputRef}>
          <OutlinedInput
            readOnly
            onClick={handleOpen}
            sx={{
              bgcolor: `${inputRef.current?.disabled ? 'action.disabledBackground' : '#fff'}`,
              height: '46px',
              input: {
                cursor: 'pointer',
              },
            }}
            value={`${value?.hour < 10 ? `0${value?.hour}` : value?.hour}h${value?.minute < 10 ? `0${value?.minute}` : value?.minute}m`}
            endAdornment={
              <InputAdornment position="end">
                <Iconify
                  icon="material-symbols:nest-clock-farsight-analog-outline"
                  sx={{ color: 'text.disabled', width: 17, height: 17 }}
                />
              </InputAdornment>
            }
          />
        </FormControl>
      </ClickAwayListener>

      {open && (
        <Box
          sx={{
            right: `${right}`,
            zIndex: '100',
            position: 'absolute',
            background: '#fff',
            boxShadow: '2px 5px 6px 3px #bdbdbd78',
            borderRadius: '8px',
            height: '272px',
            padding: '10px',
            width: '160px',
          }}
          ref={boxRef}
        >
          <Grid
            container
            spacing={2}
            sx={{ height: '100%', width: '100%', margin: 0, overflow: 'hidden', '&>.MuiGrid-item': { padding: 0 } }}
          >
            <Grid item xs={6} sx={{ height: '100%', overflowY: 'auto' }}>
              {hours.map((hour) => (
                <MenuItem
                  key={hour}
                  value={hour}
                  sx={{ justifyContent: 'center' }}
                  onClick={() => {
                    setValue({
                      ...value,
                      hour,
                    });
                  }}
                >
                  {hour}
                </MenuItem>
              ))}
            </Grid>
            <Grid item xs={6} sx={{ height: '100%', overflowY: 'auto' }}>
              {minutes.map((minute) => (
                <MenuItem
                  key={minute}
                  value={minute}
                  sx={{ justifyContent: 'center' }}
                  onClick={() => {
                    setValue({
                      ...value,
                      minute,
                    });
                  }}
                >
                  {minute}
                </MenuItem>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  verticalAlign: PropTypes.oneOf(['top', 'bottom']),
  right: PropTypes.string,
  value: PropTypes.object, 
  setValue: PropTypes.func,
};

export default TimePicker;
