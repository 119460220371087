import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Iconify from 'components/iconify';
import { useTranslation } from 'react-i18next';

import WorkingHours from './WorkingHours';

const ToggleDuration = ({ startTime, endTime, setStartTime, setEndTime, classes, state, setState, help }) => {
  const { t } = useTranslation('common');

  const handleSetStartTime = (idx, timeObj) => {
    const newData = startTime.map((el) => {
      if (el.index === idx) {
        return { index: idx, h: timeObj.hour, m: timeObj.minute };
      }

      return el;
    });

    setStartTime(newData);
  };

  const handleSetEndTime = (idx, timeObj) => {
    const newData = endTime.map((el) => {
      if (el.index === idx) {
        return { index: idx, h: timeObj.hour, m: timeObj.minute };
      }

      return el;
    });

    setEndTime(newData);
  };

  const handleAddWorking = () => {
    const currentItems = startTime.length;
    const newStartList = startTime.concat([{ index: currentItems + 1, h: '00', m: '00' }]);
    const newEndList = endTime.concat([{ index: currentItems + 1, h: '00', m: '00' }]);

    setStartTime(newStartList);
    setEndTime(newEndList);
  };

  const handleRemoveWorking = (idx) => {
    const newStartList = startTime.filter((el) => el.index !== idx);
    const newEndList = endTime.filter((el) => el.index !== idx);

    setStartTime(newStartList);
    setEndTime(newEndList);
  };

  return (
    <div>
      <Grid
        container
        item
        xs={12}
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ marginTop: '5px' }}
      >
        <Grid item xs={12} className={classes.heading}>
          {t('table:workingHours')}
        </Grid>

        {startTime.map((el) => (
          <Grid
            container
            key={el.index}
            item
            xs={12}
            className={classes.listItem}
            alignItems="center"
            justifyContent="space-between"
          >
            <WorkingHours
              index={el.index}
              startTime={startTime}
              endTime={endTime}
              setStartTime={handleSetStartTime}
              setEndTime={handleSetEndTime}
              classes={classes}
              removeWorking={handleRemoveWorking}
              state={state}
              setState={setState}
              help={help}
            />
          </Grid>
        ))}

        <Grid container item xs={12} direction="row" justifyContent={'flex-end'}>
          <Button onClick={handleAddWorking} className={classes.addButton} disabled={!state}>
            <Iconify icon="mdi-plus" sx={{ color: '#fff' }} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

ToggleDuration.propTypes = {
  startTime: PropTypes.array,
  endTime: PropTypes.array,
  classes: PropTypes.object,
  setStartTime: PropTypes.func,
  setEndTime: PropTypes.func,
  state: PropTypes.bool,
  setState: PropTypes.func,
  help: PropTypes.string,
};

export default ToggleDuration;
