export const replaceItemInArrayByKey = (array, key, newItem) => {
  const index = array.findIndex((item) => item[key] === newItem[key]);
  if (index !== -1) {
    const newArray = [...array];
    newArray[index] = newItem;
    return newArray;
  }
  return array;
};

export const getUniqueIndexes = (array) => array.reduce((result, cur, ind, arr) => {
    if (ind + 1 === arr.length || cur !== arr[ind + 1]) {
      result.push(ind);
    }
    return result;
  }, []);
