import { createContext, useReducer } from 'react';
import CostService from 'services/cost.service';
import PropTypes from 'prop-types';

const initialAppState = {
  costs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COST': {
      const { costs } = action.payload;
      return { ...state, costs };
    }
    default:
      return { ...state };
  }
};

const CostContext = createContext({
  ...initialAppState,
  get: () => {},
});

export const CostProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const costs = await CostService.get(params);
      dispatch({
        type: 'SET_COST',
        payload: {
          costs
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <CostContext.Provider value={{ ...state, method: 'Cost', get }}>
      {children}
    </CostContext.Provider>
  );
};

CostProvider.propTypes = {
  children: PropTypes.node,
};

export default CostContext;
