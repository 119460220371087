export const conversations = [
  {
    attempt_conversations: [
      {
        conversation_id: '20230420102730-d45a3f11-44fa-4089-96e3-764061e933a4',
        source_conversation_id: '20230419214647-ffffd431-b14c-4ede-9e5c-3948fa0fc8a9',
        call_at: 1681961250704,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681961250773,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0859566992',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681962462551,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-ffffd431-b14c-4ede-9e5c-3948fa0fc8a9',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0859566992',
    hangup_at: 1681962465626,
    pickup_at: 1681962465626,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420144146-be4ddae8-d236-4d3b-ba81-23299259ea95',
        source_conversation_id: '20230419214647-ffff89ef-a905-4b2e-ab70-3001d7fea93c',
        call_at: 1681976506848,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681976506914,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0987885396',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681978564168,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-ffff89ef-a905-4b2e-ab70-3001d7fea93c',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0987885396',
    hangup_at: 1681978564237,
    pickup_at: 1681978564237,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420105253-89ab332a-de97-4ef9-8128-2adf0ee00608',
        source_conversation_id: '20230419214647-fffe1815-5d5f-4cb2-ae87-51fc11c01c06',
        call_at: 1681962773827,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681962773895,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0912468954',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681974157258,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fffe1815-5d5f-4cb2-ae87-51fc11c01c06',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0912468954',
    hangup_at: 1681974157326,
    pickup_at: 1681974157326,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420144032-294f032f-0e34-4999-8133-02269c1ad77f',
        source_conversation_id: '20230419214647-fffc6bec-2291-4ea6-914a-01df9c5a49ad',
        call_at: 1681976432318,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681976432385,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0986796870',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681978497811,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fffc6bec-2291-4ea6-914a-01df9c5a49ad',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0986796870',
    hangup_at: 1681978497879,
    pickup_at: 1681978497879,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420105912-b26d54b9-2703-4cc9-90fa-54a7df255a61',
        source_conversation_id: '20230419214647-fff89162-9440-4bb7-bf06-d4f7a32d448e',
        call_at: 1681963152345,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681963152434,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0916027279',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681974359780,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fff89162-9440-4bb7-bf06-d4f7a32d448e',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0916027279',
    hangup_at: 1681974364637,
    pickup_at: 1681974364637,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420143852-2db74087-0d79-445f-9d91-c356a46640d6',
        source_conversation_id: '20230419214647-fff5f8c2-ca87-4f9b-bc7f-ba601a154c8d',
        call_at: 1681976331644,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681976332236,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0985461555',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681978408044,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fff5f8c2-ca87-4f9b-bc7f-ba601a154c8d',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0985461555',
    hangup_at: 1681978408134,
    pickup_at: 1681978408134,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420142929-3e6057f7-f9e6-464d-83bd-700f9e113b59',
        source_conversation_id: '20230419214647-fff44d6f-63ce-479f-819a-8906b095ec64',
        call_at: 1681975769704,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681975769774,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0976861210',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681977970990,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fff44d6f-63ce-479f-819a-8906b095ec64',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0976861210',
    hangup_at: 1681977971060,
    pickup_at: 1681977971060,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420143029-3b1d80e9-1c17-4134-9d68-1316c40fbe5b',
        source_conversation_id: '20230419214647-fff09190-a414-4d42-88ab-041d2df78008',
        call_at: 1681975829850,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681975829918,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0977622367',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681978008439,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-fff09190-a414-4d42-88ab-041d2df78008',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0977622367',
    hangup_at: 1681978009509,
    pickup_at: 1681978009509,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420094331-db6652ae-96a7-4496-9e31-9b8c604478a4',
        source_conversation_id: '20230419214647-ffeb172c-b66b-4449-8ab1-7e4ff4b15e67',
        call_at: 1681958611145,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681958611233,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0368437739',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681959822572,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-ffeb172c-b66b-4449-8ab1-7e4ff4b15e67',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0368437739',
    hangup_at: 1681959822662,
    pickup_at: 1681959822662,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
  {
    attempt_conversations: [
      {
        conversation_id: '20230420142738-457c8f79-3223-4e72-8519-49b826d67c41',
        source_conversation_id: '20230419214647-ffdedf0a-de4f-447a-a0a2-83a45aa40523',
        call_at: 1681975658216,
        create_at: 1681956000000,
        pickup_at: 0,
        hangup_at: 1681975658284,
        conversation_status_code: 103,
        attempt_level: 1,
        callcenter_phone: '0598329111',
        customer_phone: '0975393234',
        conversation_type: 1,
      },
    ],
    attempt_level: 2,
    call_at: 1681977895257,
    call_price: 0,
    callcenter_phone: '0598329111',
    conversation_id: '20230419214647-ffdedf0a-de4f-447a-a0a2-83a45aa40523',
    conversation_status: {
      conversation_status_code: 103,
      conversation_status_name: '',
    },
    conversation_status_code: 103,
    conversation_type: 1,
    customer_phone: '0975393234',
    hangup_at: 1681977897737,
    pickup_at: 1681977897737,
    scenario: {
      create_at: 1664961254874,
      draft_at: 1675936198646,
      scenario_id: 1683,
      scenario_name: '[P] Khóa Học - V2',
      scenario_type: 2,
    },
    scenario_publication: {
      published_at: 1675936270311,
      scenario_publication_comment: 'undefined',
      scenario_publication_id: 8119,
      scenario_publication_name: 'v09_02_2023',
    },
  },
];

export const conversationContent = {
  attempt_conversations: [
    {
      conversation_id: '20230420144454-1a74fe8c-0742-4ac8-abf3-290fe26476cd',
      source_conversation_id: '20230419214647-0528a330-82ed-431c-aec5-f247fa10bc34',
      call_at: 1681976691074,
      create_at: 1681956000000,
      pickup_at: 0,
      hangup_at: 1681976694369,
      conversation_status_code: 103,
      attempt_level: 1,
      callcenter_phone: '0598329111',
      customer_phone: '0997852534',
      conversation_type: 1,
    },
  ],
  attempt_level: 2,
  audio_url: null,
  call_at: 1681978689633,
  call_duration: null,
  callcenter_phone: '0598329111',
  campaign: {
    campaign_id: 6115,
    campaign_name: '[30] [QM] MUA BÁN TRAO ĐỔI Ô TÔ CŨ MỚI - TƯ VẤN MUA BÁN - UY TÍN HÀNG ĐẦU 1 to 6',
    campaign_status: 6,
  },
  conversation_content: [],
  conversation_id: '20230419214647-0528a330-82ed-431c-aec5-f247fa10bc34',
  conversation_status_code: 103,
  conversation_type: 1,
  create_at: 1681979889633,
  customer_phone: '0997852534',
  hangup_at: 1681978689702,
  msg: 'Success',
  pickup_at: 0,
  ring_duration: null,
  scenario: {
    created_at: 1664961254874,
    scenario_id: 1683,
    scenario_name: '[P] Khóa Học - V2',
    scenario_type: 2,
  },
  scenario_publication: {
    published_at: 1675936270311,
    scenario_publication_id: 8119,
    scenario_publication_name: 'v09_02_2023',
  },
  sip_code: 480,
  status: 0,
};