import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import RecordNotfound from 'assests/images/record_notfound.svg';

import DateTimeRange from 'components/DateTimeRange';
import MonitorChart from 'components/MonitorChart';
import NotFound from 'components/NotFound';

const useStyle = makeStyles((theme) => ({
  box: {
    padding: '10px',
    width: '100%',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  button: {
    color: theme.palette.text.dark,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 14,
  },
}));

const filters = {
  peak: 'peak',
  day: 'day',
  month: 'month',
};

const MonitorItem = ({ data, layout }) => {
  const classes = useStyle();
  const [open, toggleOpen] = useState(false);
  const [filter, setFilter] = useState(filters.peak);
  const [chartData, setChartData] = useState(data);
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleClick = (type) => {
    toggleOpen(true);
    setFilter(type);
  };

  const handleFilter = (xArr, yArr, condition) => {
    const filteredArrays = xArr.reduce(
      (acc, curr, index) => {
        if (!condition(curr)) {
          acc[0].push(curr);
          acc[1].push(yArr[index]);
        }
        return acc;
      },
      [[], []]
    );

    return {
      x: filteredArrays[0],
      y: filteredArrays[1],
    };
  };

  useEffect(() => {
    // const handleFilter = () => {
    //   switch(filter) {
    //     case filters.peak:
    //       setChartData(data);
    //       break;
    //     case filters.day:
    //       break;
    //     case filters.month:
    //       return;
    //     default:
    //       break;
    //   }
    // }
  }, []);

  return (
    <Paper className={classes.box}>
      <Grid container direction={'row'}>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            <div>
              <Button
                className={classes.button}
                sx={{
                  background: `${filter === filters.peak ? '#3366FF !important' : '#fff'}`,
                  color: `${filter === filters.peak ? '#fff !important' : '#3366FF'}`,
                  marginRight: '5px',
                }}
                onClick={() => handleClick(filters.peak)}
              >
                PEAK
              </Button>
              <Button
                className={classes.button}
                onClick={() => handleClick(filters.day)}
                sx={{
                  background: `${filter === filters.day ? '#3366FF !important' : '#fff'}`,
                  color: `${filter === filters.day ? '#fff !important' : '#3366FF'}`,
                  marginRight: '5px',
                }}
              >
                24 HOUR
              </Button>
              <Button
                className={classes.button}
                onClick={() => handleClick(filters.month)}
                sx={{
                  background: `${filter === filters.month ? '#3366FF !important' : '#fff'}`,
                  color: `${filter === filters.month ? '#fff !important' : '#3366FF'}`,
                }}
              >
                30 DAY
              </Button>
            </div>
            <DateTimeRange
              selectedTimeRange={selectedTimeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
            />
          </Stack>
        </Grid>
        {open && (
          <Grid item xs={12}>
            {!data ? (
              <NotFound title="You don't have any recordData yet!" image={RecordNotfound} />
            ) : (
              <MonitorChart open={open} data={chartData} layout={layout} />
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

MonitorItem.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  layout: PropTypes.object,
};

export default MonitorItem;
