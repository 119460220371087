import PropTypes from 'prop-types';
// @mui
import { DialogTitle, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiPaper-root': {
      color: '#3e4345',
      padding: '25px 25px 20px 25px',
      boxShadow: '0 6px 12px rgba(8, 35, 48, 0.04), 0px 16px 24px rgba(8, 35, 48, 0.06)',
      backgroundColor: 'white',
    },
  },
}));

export default function ScenarioModal({ title, openModal, handleCloseModal, children, width, maxHeight }) {
  const classes = useStyles();

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth="true"
      maxWidth="sm"
      className={classes.container}
      sx={{
        '& .MuiPaper-root': {
          width: `${width ? `${width}px` : 'calc(100% - 64px)'}`,
          maxHeight: `${maxHeight ? `${maxHeight}px` : 'calc(100% - 64px)'}`,
        },
      }}
    >
      <DialogTitle sx={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
        <div role="presentation" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
          <Iconify icon="ic:baseline-close" />
        </div>
      </DialogTitle>
      <>{children}</>
    </Dialog>
  );
}

ScenarioModal.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  children: PropTypes.node,
  maxHeight: PropTypes.number,
};
