import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const LogDetail = ({ open, detail, onClose }) => {
  const { t } = useTranslation('table');

  const classes = useStyles();

  return (
    <>
      <Dialog
        title={t('detail')}
        open={open}
        onClose={onClose}
        maxWidth="lg"
        sx={{
          '& .MuiPaper-root': {
            padding: '25px 25px 20px 25px',
            width: '1000px',
          },
          '& .MuiStepConnector-root': {
            display: 'none',
          },
          '& .MuiStep-root': {
            paddingLeft: '5px !important',
            paddingRight: '5px !important',
            flex: 'unset !important',
          },
          '& .MuiStepLabel-alternativeLabel': {
            marginTop: 'unset !important',
            textAlign: 'left',
          },
        }}
      >
        <div style={{ width: '100%' }}>
          <div className={classes.header}>
            <Typography
              sx={{
                paddingBottom: '16px',
                color: '#3e4345',
                margin: 0,
                fontSize: '22px',
                fontWeight: 600,
              }}
            >
              {t('detail')}
            </Typography>

            <Button
              sx={{
                top: '17px',
                color: '#78909c',
                right: '17px',
                width: '28px',
                height: '28px',
                padding: 0,
                position: 'absolute',
                minWidth: '28px',
                minHeight: '28px',
                borderRadius: '50%',
              }}
              onClick={onClose}
            >
              <Iconify icon="material-symbols:close" />
            </Button>
          </div>
          <div>
            <TextField
              id="outlined-multiline-static"
              label={t('detail')}
              multiline
              rows={8}
              defaultValue={JSON.stringify(detail, null, 2)}
              sx={{ width: '100%', marginBottom: '16px' }}
            />
          </div>
          <div className={classes.footer}>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

LogDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  detail: PropTypes.object,
};

export default LogDetail;
