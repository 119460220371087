import React, { useState, useEffect } from 'react';
import { campaigns, scenarioReport1 } from '_mock/campaign';
import { Grid, Button } from '@mui/material';
import moment from 'moment';

import DateRangeSelector from 'components/DateRangeSelector';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import TimeRangeSelector from 'components/TimeRangeSelector';
import { useParams } from 'react-router-dom';
import useCommon from 'hooks/useCommon';
import ChartReport from 'components/ChartReport';
import FilterSection from '../CampaignReport/FilterSection';
import { allAttempList, allVersionList } from '../CampaignReport/FilterSection/data';
import UpdateSetting from './UpdateSetting';

function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return moment(date).format('MM/DD/YYYY');
}

function formatTimeDisplay(time, defaultText) {
  if (!time) return defaultText;
  return moment(time).format('HH:mm');
}

const filterList = [
  {
    icon: 'material-symbols:check-circle-outline-rounded',
    label: 'Attempt result',
    filterLabel: 'All Attempt results',
    value: 'attempResult',
    menuList: allAttempList,
  },
  {
    icon: 'mingcute:version-fill',
    label: 'Version',
    filterLabel: 'All Versions',
    value: 'version',
    menuList: allVersionList,
  },
  {
    icon: 'streamline:interface-login-dial-pad-1-dial-pad-dot',
    label: 'Number',
    filterLabel: 'All Numbers',
    value: 'number',
  },
  {
    icon: 'bxs:conversation',
    label: 'Conversation',
    filterLabel: 'All Conversations',
    value: 'conversation',
  },
];

const useStyles = makeStyles((theme) => ({
  timeRange: {
    border: '1px solid #c6cacc',
    borderRadius: '30px',
    padding: '6px 10px',
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#78909c',
    },
  },
  pr10: {
    paddingRight: '10px',
  },
  px10: {
    padding: '0 10px',
  },
  datePicker: {
    position: 'absolute',
    top: '40px',
    background: '#fff',
    boxShadow: '2px 5px 6px 3px #bdbdbd78',
    borderRadius: '8px',
  },
  buttonGroup: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #d8d8d8',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '500  ',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '& .MuiChip-avatar': {
      width: '20px',
      height: '20px',
      color: '#78909c',
    },

    '& .MuiSvgIcon-root': {
      display: 'none',
      color: '#78909c',
      position: 'absolute',
      right: '0',
      zIndex: '10',
      width: '15px',
      height: '15px',
      background: 'linear-gradient(270deg, #f1f1f1 71.88%, rgba(247, 249, 250, 0))',
    },
  },
  filterItem: {
    fontSize: '14px',
    color: '#78909c',
    cursor: 'pointer',
    position: 'relative',
    marginRight: '16px',
    '&:hover': {
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
  },
  itemWrapper: {
    position: 'relative',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  exportButton: {
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    textTransform: 'unset',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  },
  mainButton: {
    textTransform: 'unset',
    height: '38px',
    color: '#fff',
    background: theme.palette.primary.main,
    padding: '6px 16px',
    borderRadius: '10px',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  }
}));

const initialKeys = [
  {
    key: 'system.scenario_name',
    name: 'Tên kịch bản',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'system.call_at',
    name: 'Thời điểm gọi',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'system.customer_phone',
    name: 'Số điện thoại khách hàng',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'system.conversation_status_code',
    name: 'Trạng thái',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'system.call_duration',
    name: 'Thời lượng',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'system.customer_phone',
    name: 'SĐT KH',
    isVisualized: false,
    type: 'events',
  },
  {
    key: 'reports.KH',
    name: 'Cuộc gọi',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.KH99',
    name: 'Thoại Cuộc gọi',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.KH94',
    name: 'Hỏi đầu tư chưa',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.KH90',
    name: 'Hỏi KH đang dùng zalo số này',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.KH79',
    name: 'SĐT ZALO KH dùng',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.KH69',
    name: 'KH nói giờ gọi lại',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'reports.name',
    name: 'Tên KH trong data',
    isVisualized: true,
    type: 'events',
  },
  {
    key: 'system.link_public',
    name: 'Link cuộc gọi',
    isVisualized: true,
    type: 'events',
  }
]

const ScenarioAnalyze = ({ page }) => {
  const { setParentPage, setChildName } = useCommon();
  const classes = useStyles();
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [selectDateMode, setSelectDateMode] = useState('date');
  const [openSelectFilter, setOpenSelectFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [openSetting, setOpenSetting] = useState(false);
  const [keys, setKeys] = useState(initialKeys);
  const [charts, setCharts] = useState(scenarioReport1.charts);

  useEffect(() => {
    if(!id) return;
    let item;
    switch(page) {
      case 'scenario':
        item = campaigns.find((el) => el.scenario.scenario_id.toString() === id);
        setCampaign(item);
        break;
      case 'campaign':
        item = campaigns.find((el) => el.campaign_id.toString() === id);
        setCampaign(item);
        break;
      default:
        break;
    }
    if (id) {
      const c = campaigns.find((el) => el.campaign_id.toString() === id);
      setCampaign(c);
    }
  }, [id, page]);

  useEffect(() => {
    setParentPage('outbound');
    if (campaign) {
      setChildName(campaign.campaign_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const onClickClear = () => {
    setOpenDateRange(false);
  };

  const toggleMode = () => {
    const newMode = selectDateMode === 'date' ? 'time' : 'date';
    setSelectDateMode(newMode);
  };

  const handleSelectFilter = (value) => {
    setSelectedFilter((prev) => [...prev, value]);
  };

  const handleDeleteFilter = (filter) => () => {
    setSelectedFilter((data) => data.filter((chip) => chip.value !== filter.value));
  };

  const handleClearFilter = () => {
    setSelectedFilter([]);
    setOpenSelectFilter(false);
  };
  
  const handleOpenSetting = () => setOpenSetting(true);
  const handleCloseSetting = () => setOpenSetting(false);

  // if (!campaign) return <div>Loading</div>;

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container direction="row" xs={10}>
          <Grid item sx={{ position: 'relative' }}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <span className={classes.timeRange} onClick={() => setOpenDateRange((prev) => !prev)}>
              <span className={classes.pr10}>{`${formatDateDisplay(selectedDateRange.startDate)}, ${formatTimeDisplay(
                selectedTimeRange.startTime
              )}`}</span>
              <span>to</span>
              <span className={classes.px10}>{`${formatDateDisplay(selectedDateRange.endDate)}, ${formatTimeDisplay(
                selectedTimeRange.endTime
              )}`}</span>
              <Iconify icon="material-symbols:calendar-today" color="#78909c" />
            </span>
            {openDateRange && (
              <div className={classes.datePicker}>
                {selectDateMode === 'time' && (
                  <TimeRangeSelector
                    pickedStart={selectedTimeRange.startTime}
                    pickedEnd={selectedTimeRange.endTime}
                    onChange={setSelectedTimeRange}
                  />
                )}
                {selectDateMode === 'date' && (
                  <DateRangeSelector
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                )}
                <div className={classes.buttonGroup}>
                  <button
                    type="button"
                    style={{ marginRight: '8px', color: '#78909c', background: '#fff' }}
                    onClick={toggleMode}
                    className={classes.button}
                  >
                    {selectDateMode === 'time' ? 'Select date' : 'Select time'}
                  </button>
                  <button type="button" onClick={onClickClear} className={classes.button}>
                    OK
                  </button>
                </div>
              </div>
            )}
          </Grid>

          <Button className={classes.mainButton} sx={{ marginLeft: '10px' }} onClick={handleOpenSetting}>
            <Iconify
              icon="material-symbols:settings-applications-rounded"
              color="#fff"
              width={24}
              height={24}
              sx={{ marginRight: '3px' }}
            />
            Setting Report
          </Button>

          <FilterSection
            classes={classes}
            filterList={filterList}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            handleDeleteFilter={handleDeleteFilter}
            openSelectFilter={openSelectFilter}
            setOpenSelectFilter={setOpenSelectFilter}
            handleSelectFilter={handleSelectFilter}
            handleClearFilter={handleClearFilter}
          />
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <Grid item xs={12}>
            <div className={classes.exportButtons}>
              <Button className={classes.exportButton}>
                <Iconify icon="ic:sharp-cloud-download" sx={{ marginRight: '5px' }} color="#fff" />
                Export report
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'} item xs={12} sx={{ padding: '10px' }} spacing={2}>
          {charts.map((chart) => (
            <Grid key={chart.metadata.key} item xs={4}>
              <ChartReport title={chart.metadata.name} value={chart.values}/>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <UpdateSetting open={openSetting} onClose={handleCloseSetting} keys={keys} />
    </div>
  );
};

export default ScenarioAnalyze;
