import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { UserProvider } from 'contexts/UserContext';
import { RoleProvider } from 'contexts/RoleContext';
import { PermissionProvider } from 'contexts/PermissionContext';
// import { ScenarioFlowProvider } from 'contexts/ScenarioFlowContext';
import { AuthProvider } from 'contexts/AuthContext';
import { CommonProvider } from 'contexts/CommonContext';
import { ScenarioProvider } from 'contexts/ScenarioContext';
import { CampaignProvider } from 'contexts/CampaignContext';
import { AudioRecordProvider } from 'contexts/AudioRecordContext';
import { NumberProvider } from 'contexts/NumberContext';
import { ContactGroupProvider } from 'contexts/ContactGroupContext';
import { BlacklistProvider } from 'contexts/BlacklistContext';
import { BlacklistContactProvider } from 'contexts/BlacklistContactContext';
import { CostProvider } from 'contexts/CostContext';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import Router from './routers/routes';
import { StyledChart } from './components/chart';

const providers = [
  { Provider: AuthProvider },
  { Provider: UserProvider },
  { Provider: RoleProvider },
  { Provider: NumberProvider },
  { Provider: ContactGroupProvider },
  { Provider: BlacklistProvider },
  { Provider: BlacklistContactProvider },
  { Provider: PermissionProvider },
  { Provider: CampaignProvider },
  // { Provider: ScenarioFlowProvider },
  { Provider: AudioRecordProvider },
  { Provider: HelmetProvider },
  { Provider: BrowserRouter },
  { Provider: LocalizationProvider, props: { dateAdapter: AdapterMoment } },
  { Provider: ThemeProvider },
  { Provider: CommonProvider },
  { Provider: ScenarioProvider },
  { Provider: CostProvider}
];

function CombinedProviders({ children }) {
  return providers.reduceRight((kids, { Provider, props = {} }) => (
    <Provider {...props}>{kids}</Provider>
  ), children);
}

export default function App() {
  return (
    <CombinedProviders>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </CombinedProviders>
  );
}
