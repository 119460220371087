import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select, Switch } from '@mui/material';
import Iconify from 'components/iconify';
import { useTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';

const ColumnVariable = ({ control, header, value, columns, includes, switchInclude, mappedValue, handleMapValue }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    handleMapValue(header, event.target.value);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          minWidth: '225px',
          marginRight: '10px',
          gap: '10px',
        }}
      >
        <div>
          <div
            style={{
              width: '200px',
              padding: '0 8px',
              background: '#fff',
              boxShadow: '0 2px 4px rgba(8, 35, 48, 0.12)',
              borderRadius: '8px',
            }}
          >
            <div style={{ display: 'flex', padding: '10px 8px 8px', alignItems: 'center' }}>
              <img
                src="/assets/icons/xlsIcon.svg"
                alt="xls icon"
                width={24}
                height={24}
                style={{
                  filter: 'invert(66%) sepia(12%) saturate(600%) hue-rotate(155deg) brightness(82%) contrast(84%)',
                  marginRight: '10px',
                }}
              />
              {header}
            </div>
            <div
              style={{
                color: '#e3e5e6',
                height: '45px',
                padding: '10px 8px',
                borderTop: '1px solid #e3e5e6',
                boxSizing: 'border-box',
              }}
            >
              {value}
            </div>
          </div>
        </div>

        <Iconify icon="heroicons-solid:switch-vertical" color="#c6cacc" />

        <Controller
          name={header}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <Select
                {...field}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ boxShadow: '0 2px 4px rgba(8, 35, 48, 0.12)', backgroundColor: '#fff' }}
                onChange={handleChange}
                defaultValue=""
                value={mappedValue ? mappedValue.key : null}
              >
                {columns.map((col) => {
                  if (col === '')
                    return (
                      <MenuItem key={col} value={''}>
                        None
                      </MenuItem>
                    );
                  return (
                    <MenuItem key={col} value={col}>
                      {col}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          <span style={{ color: theme.palette.text.grey, fontSize: '14px' }}>
            {includes ? 'Exclude Column' : 'Include Column'}
          </span>
          <Switch checked={includes} onChange={() => switchInclude(includes, header)} />
        </div>
      </div>
    </>
  );
};

ColumnVariable.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  columns: PropTypes.array,
  includes: PropTypes.bool,
  switchInclude: PropTypes.func,
  mappedValue: PropTypes.string,
  handleMapValue: PropTypes.func,
  control: PropTypes.object,
};

export default ColumnVariable;
