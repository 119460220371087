import { useState } from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

const MonitorChart = ({ data, layout }) => {
  const [scatter, setScatter] = useState({
    x: [1, 2, 3],
    y: [2, 5, 3],
    type: 'scatter',
    mode: 'lines+markers',
    marker: { color: 'red' },
  });

  const handleChange = (i, j, e) => {
    let newScatter = { ...scatter };
    newScatter = parseInt(e.target.value, 10);
    setScatter(newScatter);
  };

  return (
    <>
      <Plot
        style={{ width: '100%' }}
        data={data}
        layout={layout}
        responsive
      />
    </>
  );
};

MonitorChart.propTypes = {
  open: PropTypes.bool, 
  data: PropTypes.array,
  title: PropTypes.string,
  layout: PropTypes.object,
}

export default MonitorChart;
