export const campaignChildrenTabs = (params) => [
  {
    idx: 0,
    pathname: `/outbound/campaigns/${params}/report`,
    label: 'report',
  },
  {
    idx: 1,
    pathname: `/outbound/campaigns/${params}/monitor`,
    label: 'monitor',
  },
  {
    idx: 2,
    pathname: `/outbound/campaigns/${params}/scenarioAnalyze`,
    label: 'scenarioAnalyze',
  },
];

export const scenariohildrenTabs = (params) => [
  {
    idx: 0,
    pathname: `/outbound/scenarios/${params}/history`,
    label: 'History',
  },
  {
    idx: 1,
    pathname: `/outbound/scenarios/${params}/campaigns`,
    label: 'Campaigns',
  },
  {
    idx: 2,
    pathname: `/outbound/scenarios/${params}/auto-campaigns`,
    label: 'Auto Campaigns',
  },
  {
    idx: 3,
    pathname: `/outbound/scenarios/${params}/monitor`,
    label: 'Monitor',
  },
  {
    idx: 4,
    pathname: `/outbound/scenarios/${params}/scenarioAnalyze`,
    label: 'Scenario Analyze',
  },
];

export const inboundScenarioTabs = (params) => [
  {
    idx: 0,
    pathname: `/inbound/scenarios/${params}/history`,
    label: 'History',
  },
  {
    idx: 1,
    pathname: `/inbound/scenarios/${params}/monitor`,
    label: 'Monitor',
  },
  {
    idx: 2,
    pathname: `/inbound/scenarios/${params}/scenarioAnalyze`,
    label: 'Scenario Analyze',
  },
];
