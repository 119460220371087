import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialCommonState = {
  currentTab: 'users',
  parentPage: null,
  childName: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_TAB': {
      const { tab } = action.payload;
      return {
        ...state,
        currentTab: tab,
      };
    }

    case 'SET_PARENT_PAGE': {
      const { page } = action.payload;
      return {
        ...state,
        parentPage: page,
      };
    }

    case 'SET_CHILD_NAME': {
      const { name } = action.payload;
      return {
        ...state,
        childName: name,
      };
    }

    default:
      return { ...state };
  }
};

const CommonContext = createContext({
  ...initialCommonState,
  updateTab: () => {},
  setParentPage: () => {},
});

export const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCommonState);

  const updateTab = (tab) => {
    dispatch({ type: 'UPDATE_TAB', payload: { tab } });
  };

  const setParentPage = (page) => {
    dispatch({ type: 'SET_PARENT_PAGE', payload: { page } });
  };

  const setChildName = (name) => {
    dispatch({ type: 'SET_CHILD_NAME', payload: { name } });
  };

  return (
    <CommonContext.Provider value={{ ...state, method: 'Common', updateTab, setParentPage, setChildName }}>
      {children}
    </CommonContext.Provider>
  );
};

CommonProvider.propTypes = {
  children: PropTypes.node,
};

export default CommonContext;
