import React, { useCallback, useState, useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { useTranslation } from 'react-i18next';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Dialog } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UploadContact from 'pages/OutBound/components/CampaignPage/CampaignModal/components/UploadContact';
import MapFile from 'pages/OutBound/components/CampaignPage/CampaignModal/components/MapFile';

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => {
  const getColor = () => {
    const { active, completed } = ownerState;
    if (completed) {
      return '#7acc90';
    }
    if (active) {
      return '#78909c';
    }

    return '#cdd1d2';
  };
  return {
    zIndex: 1,
    color: getColor(),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

const getStepForm = [
  {
    title: 'loadingContactsDetails',
    step: 1,
  },
  {
    title: 'Map',
    step: 2,
  }
];

function ColorlibStepIcon (props) {
  const { active, completed, className } = props;
  const icons = {
    active: <CheckCircleOutlinedIcon />,
    completed: <CheckCircleIcon />,
  };

  const getIconComp = ({ completed, active }) => {
    if (completed || active) {
      return icons.completed;
    }

    return icons.active;
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {getIconComp({ completed, active })}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const UploadModal = ({
  initialModalData,
  openModal,
  handleCloseModal,
  onSubmit,
}) => {
  const { t } = useTranslation('common');
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isConfirmPolicy, setIsConfirmPolicy] = useState(true);
  const [data, setData] = useState(initialModalData);
  const [mappedArr, setMapptedArr] = useState([]);
  const [activeCols, setActiveCols] = useState([]);
  const [mapContent, setMapContent] = useState([]);

  const setField = useCallback(
    (field, value) => {
      setData({
        ...data,
        [field]: value,
      });
    },
    [data]
  );

  const resetData = useCallback(() => setData(initialModalData), [initialModalData]);

  useEffect(() => {
    resetData();
  }, [initialModalData]);

  const handleUploadData = async () => {
    const formData = new FormData();
    const fullNameIdx = mapContent.findIndex((el) => el.key === 'name');
    const phoneIdx = mapContent.findIndex((el) => el.key === 'phone');

    const formattedContact = mappedArr
      .map((el, idx) => {
        if (idx === 0) return null;
        return { name: el[fullNameIdx] || '', phone_number: el[phoneIdx] || '' };
      })
      .filter((item) => item !== null);
    formData.append('contacts', JSON.stringify(formattedContact));

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in data) {
      if (typeof data[key] === 'boolean') {
        formData.append(key, +data[key]);
      } else if (Array.isArray(data[key])) {
        data[key].map((value) => formData.append(`${key}[]`, value));
      } else if (data[key] === null) {
        formData.append(key, '');
      } else {
        formData.append(key, data[key]);
      }
    }

    try {
      onClose();
      await onSubmit(formData);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const onClose = () => {
    handleCloseModal();
    resetData();
    setActiveStep(0);
    setIsConfirmPolicy(false);
  };

  const steps = ['Loading contacts details', 'Map'];

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const renderProps = (step) => {
    switch (step) {
      case 'Loading contacts details':
        return {
          data,
          handleNext,
          isConfirmPolicy,
          setIsConfirmPolicy,
          setData: setField,
        };
      case 'Map':
        return {
          data,
          handleNext: handleUploadData,
          handleBack,
          mappedArr,
          setMapptedArr,
          activeCols,
          setActiveCols,
          mapContent,
          setMapContent,
          setData: setField,
        };
      default:
        return {
          data,
          handleNext,
          handleBack,
        };
    }
  };

  const renderStepComp = (step, props) => {
    switch (step) {
      case 'Loading contacts details':
        return <UploadContact {...props} />;
      case 'Map':
        return <MapFile {...props} />;
      default:
        return <UploadContact />;
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      maxWidth="md"
      sx={{
        '& .MuiPaper-root': {
          padding: '25px 25px 20px 25px',
          width: '850px',
        },
        '& .MuiStepConnector-root': {
          display: 'none',
        },
        '& .MuiStep-root': {
          paddingLeft: '5px !important',
          paddingRight: '5px !important',
          flex: 'unset !important',
        },
        '& .MuiStepLabel-alternativeLabel': {
          marginTop: 'unset !important',
          textAlign: 'left',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Stepper sx={{ alignItems: 'unset', justifyContent: 'space-around' }} alternativeLabel activeStep={activeStep}>
          {getStepForm.map(({ title: label }) => (
            <Step
              key={label}
              sx={{
                '& .MuiStepper-root': {
                  display: 'flex',
                },
                '& .MuiStepLabel-root': {
                  display: 'flex',
                  flexDirection: 'row !important',
                },
                '& .MuiStepLabel-label': {
                  marginTop: 'unset',
                },
                '& .MuiStepLabel-labelContainer': {
                  textAlign: 'left',
                  marginLeft: '8px',
                },
                '& .MuiStepLabel-labelContainer span': {
                  fontSize: 16,
                  fontWeight: 500,
                },
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>{t(`table:${label}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
          {renderStepComp(steps[activeStep], renderProps(steps[activeStep]))}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default memo(UploadModal);

UploadModal.propTypes = {
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  initialModalData: PropTypes.object,
};
