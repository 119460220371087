export const loggings = {
  events: [
    {
      create_at: 1685288587965,
      detail: {
        conversation_id: '20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
      },
      event_id: 377062,
      event_type: {
        en: 'Callout conversation',
        vi: 'Thực hiện cuộc gọi',
      },
      message: {
        en: 'User 375 has callout conversation 20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã thực hiện cuộc gọi 20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685288587832,
      detail: {
        conversation_id: '20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
        msg: 'Success',
        status: 0,
      },
      event_id: 377061,
      event_type: {
        en: 'Create conversation',
        vi: 'Tạo cuộc gọi mới',
      },
      message: {
        en: 'User 375 has created conversation 20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã tạo cuộc gọi mới 20230528224307-c78936f8-fdab-4f79-a4db-c98222c87787',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685288554283,
      detail: {
        conversation_id: '20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
      },
      event_id: 377060,
      event_type: {
        en: 'Callout conversation',
        vi: 'Thực hiện cuộc gọi',
      },
      message: {
        en: 'User 375 has callout conversation 20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã thực hiện cuộc gọi 20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685288554166,
      detail: {
        conversation_id: '20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
        msg: 'Success',
        status: 0,
      },
      event_id: 377059,
      event_type: {
        en: 'Create conversation',
        vi: 'Tạo cuộc gọi mới',
      },
      message: {
        en: 'User 375 has created conversation 20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã tạo cuộc gọi mới 20230528224234-ef7cb161-b505-4fb2-987c-b32129f727fb',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685246648685,
      detail: {
        scenario_id: 2614,
        scenario_name: 'Testing 1',
      },
      event_id: 376973,
      event_type: {
        en: 'Create scenario',
        vi: 'Tạo kịch bản',
      },
      message: {
        en: 'User 375 has create scenario Testing 1',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã tạo kịch bản Testing 1',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685246638576,
      detail: {
        scenario: {
          scenario_id: 2521,
          scenario_name: 'hello 1',
        },
      },
      event_id: 376972,
      event_type: {
        en: 'Delete Scenario',
        vi: 'Xóa kịch bản',
      },
      message: {
        en: 'User 375 has deleted scenario hello 1',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã xóa kịch bản hello 1',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1685160007651,
      detail: {
        new_scenario: {
          scenario_id: 2604,
          scenario_name: 'Copy of Khóa Học v4 23:05:06',
        },
      },
      event_id: 376579,
      event_type: {
        en: 'Update scenario',
        vi: 'Sửa kịch bản',
      },
      message: {
        en: 'User 375 has update scenario Copy of Khóa Học v4 23:05:06',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã sửa kịch bản Copy of Khóa Học v4 23:05:06',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1684926439791,
      detail: {
        scenario: {
          scenario_id: 1683,
          scenario_name: '[P] Khóa Học - V2',
        },
      },
      event_id: 375374,
      event_type: {
        en: 'Save scenario content',
        vi: 'Lưu lại chỉnh sửa kịch bản',
      },
      message: {
        en: 'User 375 has saved new scenario content for scenario [P] Khóa Học - V2',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã lưu lại trạng thái mới cho kịch bản [P] Khóa Học - V2',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1684926436934,
      detail: {
        scenario: {
          scenario_id: 1683,
          scenario_name: '[P] Khóa Học - V2',
        },
      },
      event_id: 375373,
      event_type: {
        en: 'Save scenario content',
        vi: 'Lưu lại chỉnh sửa kịch bản',
      },
      message: {
        en: 'User 375 has saved new scenario content for scenario [P] Khóa Học - V2',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã lưu lại trạng thái mới cho kịch bản [P] Khóa Học - V2',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
    {
      create_at: 1684858541126,
      detail: {
        new_scenario: {
          scenario_id: 2605,
          scenario_name: 'Copy of Kịch bản - KH Đầu Tư Vốn Nhỏ 2 23:15:39',
        },
        old_scenario: {
          scenario_id: 2562,
          scenario_name: 'Kịch bản - KH Đầu Tư Vốn Nhỏ 2',
        },
      },
      event_id: 375094,
      event_type: {
        en: 'Clone scenario',
        vi: 'Sao chép kịch bản',
      },
      message: {
        en: 'User 375 has copy scenario Kịch bản - KH Đầu Tư Vốn Nhỏ 2 to scenario Copy of Kịch bản - KH Đầu Tư Vốn Nhỏ 2 23:15:39',
        user: {
          name: 'DKCAM',
          role: {
            name: 'Admin',
            role_id: 605,
          },
          user_id: 375,
        },
        vi: 'Người dùng 375 đã sao chép kịch bản Kịch bản - KH Đầu Tư Vốn Nhỏ 2 sang kịch bản Copy of Kịch bản - KH Đầu Tư Vốn Nhỏ 2 23:15:39',
      },
      organization_id: 145,
      user: {
        name: 'DKCAM',
        user_id: 375,
      },
    },
  ],
  metadata: {
    current_page: 1,
    per_page: 10,
    total_count: 19,
    total_page: 2,
  },
  msg: 'Success',
  status: 0,
};
