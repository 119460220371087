import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, List, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import moment from 'moment';
import Toast from 'components/Toast';
import LogDetail from '../Detail';

export const formatDate = (date) => moment(date).format('MM/DD/YYY, HH:mm');

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '12px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '0px',
    top: 'calc(100%)',
    width: '118px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

const Row = ({ event_id, create_at, user, event_type, message, detail }) => {
  const classes = useStyles();
  const [openLog, setOpenlog] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });

  const handleCloseToast = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };

  const handleClose = () => setOpenlog(false);
  const handleOpen = () => setOpenlog(true);
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }} xs={1}>
          <div
            className={classes.content}
            style={{ justifyContent: 'flex-start', paddingTop: '16px', paddingLeft: '15px', paddingBottom: '16px' }}
          >
            {event_id}
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{formatDate(create_at)}</div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{user.user_id}</div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{user.name}</div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{event_type.en}</div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{message.en}</div>
        </Grid>
        <Grid
          item
          className={classes.row}
          style={{ borderBottom: '#e3e5e6 1px solid' }}
          sx={{ width: '30px', flex: 'unset !important' }}
        >
          <Button className={classes.button} onClick={() => setOpenAction((prev) => !prev)}>
            <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />

            {openAction ? (
                <List className={classes.listAction}>
                  <ListItemButton className={classes.listActionItem} onClick={handleOpen}>
                    <ListItemText>Detail</ListItemText>
                  </ListItemButton>
                </List>
              ) : null}
          </Button>
        </Grid>
      </Grid>

      <LogDetail open={openLog} onClose={handleClose} detail={detail} />

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
};

Row.propTypes = {
  event_id: PropTypes.string, 
  create_at: PropTypes.number, 
  user: PropTypes.object, 
  event_type: PropTypes.object, 
  message: PropTypes.object,
  detail: PropTypes.object
};

export default Row;
