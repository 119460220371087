import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Grid, Switch } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { makeStyles } from '@mui/styles';

import Iconify from 'components/iconify';
import moment from 'moment';
import HelpText from '../../../HelpText';

const useStyles = makeStyles(() => ({
  datePicker: {
    width: '100%',
    backgroundColor: '#fff',
    '& .MuiInputBase-root': {
      height: '46px',
    },
  },
  icon: {
    color: '#78909c',
    width: 15,
    height: 15,
    float: 'left',
    cursor: 'pointer',
  },
}));

const ToggleDate = ({ name, state, setState, title, control, value, setValue, help }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sx={{ fontSize: '12px', color: '#78909c', marginBottom: '5px' }}>
        {title}
      </Grid>
      <Grid container item xs={12} direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={2}>
          <Switch
            checked={state}
            onChange={(e) => {
              setState(e.target.checked);
              setValue(moment().format('YYYY-MM-DD HH:mm:ss'), name);
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={() =>
              state ? (
                <DateTimePicker
                  format="DD/MM/YYYY HH:mm"
                  defaultValue={value ? moment(value) : moment()}
                  className={classes.datePicker}
                  onChange={(value) => setValue(value.format('YYYY-MM-DD HH:mm:ss'), name)}
                />
              ) : (
                <DateTimePicker
                  format="DD/MM/YYYY HH:mm"
                  defaultValue={moment()}
                  className={classes.datePicker}
                  disabled
                />
              )
            }
          />
        </Grid>
        <Grid
          item
          xs={0.6}
          sx={{ height: '100%', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}
        >
          <HelpText classes={classes} text={help} />
        </Grid>
      </Grid>
    </>
  );
};

ToggleDate.propTypes = {
  name: PropTypes.string,
  state: PropTypes.bool,
  title: PropTypes.string,
  setState: PropTypes.func,
  control: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.object,
  help: PropTypes.string,
};

export default ToggleDate;
