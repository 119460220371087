import { createContext, useReducer } from 'react';
import BlacklistService from 'services/blacklist.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  blacklists: [],
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BLACK_LIST': {
      const { blacklists, total } = action.payload;
      return { ...state, blacklists, total };
    }
    case 'CREATE_BLACK_LIST': {
      const { blacklist } = action.payload;
      return { ...state, blacklists: [blacklist].concat(state.blacklists), total: state.total + 1 };
    }
    case 'UPDATE_BLACK_LIST': {
      const { blacklist } = action.payload;
      const updatedRoles = replaceItemInArrayByKey(state.blacklists, 'id', blacklist);
      return { ...state, blacklists: updatedRoles };
    }
    case 'DELETE_BLACK_LIST': {
      const { id } = action.payload;
      return { ...state, blacklists: state.blacklists.filter((blacklist) => blacklist.id !== id), total: state.total - 1 };
    }

    default:
      return { ...state };
  }
};

const BlacklistContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const BlacklistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const { data: blacklists, meta } = await BlacklistService.get(params);
      dispatch({
        type: 'SET_BLACK_LIST',
        payload: {
          blacklists,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: blacklist } = await BlacklistService.create(data);
      dispatch({
        type: 'CREATE_BLACK_LIST',
        payload: {
          blacklist,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: blacklist } = await BlacklistService.update(id, data);
      dispatch({
        type: 'UPDATE_BLACK_LIST',
        payload: {
          blacklist,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await BlacklistService.delete(id);
      dispatch({
        type: 'DELETE_BLACK_LIST',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <BlacklistContext.Provider value={{ ...state, method: 'Blacklist', get, create, update, destroy }}>
      {children}
    </BlacklistContext.Provider>
  );
};

BlacklistProvider.propTypes = {
  children: PropTypes.node,
};

export default BlacklistContext;
