import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.dark,
    cursor: 'pointer',
    fontSize: '16px',
    '&:hover': {
      color: theme.palette.text.blue,
      textDecoration: 'underline',
    },
  },
}));

const StyledLink = ({ to, label }) => {
  const classes = useStyles();
  return (
    <Link to={to} className={classes.link} onClick={() => {}}>
      {label}
    </Link>
  );
};

StyledLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
}

export default StyledLink;
