import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Chip,
  Button,
  Grid,
  Paper,
  Stack,
} from '@mui/material';

// hooks
import useUser from 'hooks/useUser';
// components
import DateTimeRange from 'components/DateTimeRange';
import Iconify from 'components/iconify/Iconify';
import Scrollbar from 'components/scrollbar';
// sections
import { ListHead } from 'sections/@dashboard/user';
// const

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'conversation', label: 'Conversation', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'cost', label: 'Cost', alignRight: false },
  { id: 'remain', label: 'Remain', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyle = makeStyles((theme) => ({
  box: {
    padding: '10px',
    width: '100%',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  button: {
    color: theme.palette.text.dark,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 14,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  exportButton: {
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    textTransform: 'unset',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
}));

const filters = {
  week: 'week',
  month: 'month',
};

export default function Deposit() {
  const { t } = useTranslation('common');
  const classes = useStyle();
  const { users, total, get: getUsers } = useUser();
  const [filter, setFilter] = useState(filters.week);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const emptyRows = page > 1 ? Math.max(0, rowsPerPage - users.length) : 0;
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleClick = (type) => {
    setFilter(type);
  };

  useEffect(() => {
    const handleGetUsers = async () => {
      await getUsers({ page, limit: rowsPerPage });
    };

    try {
      handleGetUsers();
    } catch (err) {
      console.log(err);
    }
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('deposit')} </title>
      </Helmet>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginY={1}>
        <Stack direction={'row'} spacing={2}>
          <div>
            <Button
              className={classes.button}
              sx={{
                background: `${filter === filters.week ? '#3366FF !important' : '#fff'}`,
                color: `${filter === filters.week ? '#fff !important' : '#3366FF'}`,
                marginRight: '5px',
              }}
              onClick={() => handleClick(filters.week)}
            >
              7 DAYS
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleClick(filters.month)}
              sx={{
                background: `${filter === filters.month ? '#3366FF !important' : '#fff'}`,
                color: `${filter === filters.month ? '#fff !important' : '#3366FF'}`,
              }}
            >
              30 DAYS
            </Button>
          </div>
          <DateTimeRange
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </Stack>
        <div className={classes.exportButtons}>
          <Button className={classes.exportButton}>
            <Iconify icon="ic:sharp-cloud-download" sx={{ marginRight: '5px' }} color="#fff" />
            Export report
          </Button>
        </div>
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.map((row) => {
                  const { id, user_name, role, name, email } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{user_name}</TableCell>

                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">
                        <Chip
                          label={role}
                          sx={{ color: '#fff', backgroundColor: id === 1 ? 'success.darker' : 'error.dark' }}
                        />
                      </TableCell>

                      <TableCell align="left">{email}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
