import React, { useState } from 'react';
import { Button, Chip, Box } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import PropTypes from 'prop-types';

import FilterList from '../FilterList';
import Select from './components/Select';
import Search from './components/Search';

const FilterSection = ({
  classes,
  filterList,
  selectedFilter,
  handleDeleteFilter,
  openSelectFilter,
  setOpenSelectFilter,
  handleSelectFilter,
  handleClearFilter,
  selectedFilterValue,
  setSelectedFilterValue,
}) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleClick = (value) => {
    if (openMenu === value) {
      setOpenMenu(null);
    } else {
      setOpenMenu(value);
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleChooseFilterValue = (newValue) => {
    const isExist = selectedFilterValue.find((el) => el.type === openMenu);
    if (isExist) {
      setSelectedFilterValue((prev) =>
        prev.map((el) => {
          if (el.type === openMenu)
            return {
              ...el,
              value: newValue,
            };
          return el;
        })
      );
    } else {
      setSelectedFilterValue((prev) =>
        prev.concat([
          {
            type: openMenu,
            value: newValue,
          },
        ])
      );
    }
  };

  const handleRemoveFilterValue = (value) => {
    setSelectedFilterValue((prev) =>
      prev.map((el) => {
        if (el.type === openMenu)
          return {
            ...el,
            value,
          };
        return el;
      })
    );
  };

  const handleResetFilter = () => {
    setSelectedFilterValue((prev) =>
      prev.map((el) => {
        if (el.type === openMenu)
          return {
            ...el,
            value: null,
          };
        return el;
      })
    );
  };

  return (
    <>
      <ul className={classes.filterList}>
        {selectedFilter.map((filter) => (
          <div key={filter.value} className={classes.itemWrapper}>
            <Chip
              avatar={<Iconify icon={filter.icon} width={15} height={15} color="#78909c" />}
              label={filter.label}
              onDelete={() => handleDeleteFilter(filter.value)}
              className={classes.filterItem}
              onClick={() => handleClick(filter.value)}
            />
            {openMenu === filter.value &&
              (filter.menuList ? (
                <Select
                  list={filter.menuList}
                  selectedFilterValue={selectedFilterValue.find((el) => el.type === openMenu)}
                  handleResetFilter={handleResetFilter}
                  handleChooseFilterValue={handleChooseFilterValue}
                  handleRemoveFilterValue={handleRemoveFilterValue}
                  handleClose={handleCloseMenu}
                />
              ) : (
                <Search />
              ))}
          </div>
        ))}
      </ul>
      {selectedFilter.length < filterList.length && (
        <Box sx={{ position: 'relative', paddingLeft: '8px' }}>
          <Button onClick={() => setOpenSelectFilter((prev) => !prev)}>
            <Iconify icon="mdi:plus-circle" color="#3366ff" width={20} height={20} />
            <span style={{ paddingLeft: '8px' }}>Add filter</span>
          </Button>
          {openSelectFilter && (
            <FilterList
              list={filterList.filter((el) => !selectedFilter.find((item) => item.value === el.value))}
              setSelectedFilter={handleSelectFilter}
            />
          )}
        </Box>
      )}
      {selectedFilter.length > 0 && (
        <Button onClick={handleClearFilter}>
          <span style={{ paddingLeft: '8px' }}>Clear</span>
        </Button>
      )}
    </>
  );
};

FilterSection.propTypes = {
  classes: PropTypes.object,
  filterList: PropTypes.array,
  selectedFilter: PropTypes.object,
  handleClearFilter: PropTypes.func,
  handleDeleteFilter: PropTypes.func,
  openSelectFilter: PropTypes.bool,
  setOpenSelectFilter: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  selectedFilterValue: PropTypes.array,
  setSelectedFilterValue: PropTypes.func,
};

export default FilterSection;
