// component
import { SvgIcon } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';
import PaymentIcon from '@mui/icons-material/Payment';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import BarChartIcon from '@mui/icons-material/BarChart';
import DialpadIcon from '@mui/icons-material/Dialpad';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

// ----------------------------------------------------------------------
const icon = (name) => <SvgIcon component={name} inheritViewBox />;

const navConfig = [
  {
    title: 'users',
    path: '/users',
    icon: icon(PermContactCalendarIcon),
    heading: 'Users',
    permission: 'get_user',
  },
  // TODO: add permissions
  {
    title: 'roles',
    path: '/roles',
    icon: icon(PeopleIcon),
    heading: 'Roles',
  },
  {
    title: 'cost-estimate',
    path: '/cost-estimate',
    icon: icon(CreditCardOutlinedIcon),
    heading: 'Cost Estimate',
  },
  {
    title: 'monitor',
    path: '/monitor',
    icon: icon(TimelineIcon),
    heading: 'Monitor',
  },
  {
    title: 'logging',
    path: '/logging',
    icon: icon(CalendarMonthIcon),
    heading: 'Audit Log',
    lastOfSection: true,
  },
  {
    title: 'overview',
    path: '/overview',
    icon: icon(BarChartIcon),
    heading: 'Overview',
  },
  {
    title: 'campaign',
    path: '/campaigns',
    icon: icon(PhoneForwardedIcon),
  },
  {
    title: 'scenario',
    path: '/scenario',
    icon: icon(ListIcon),
  },
  {
    title: 'manage-number-group',
    path: '/manage-number-group',
    icon: icon(PhonelinkSetupIcon),
  },
  {
    title: 'manage-contact-group',
    path: '/manage-contact-group',
    icon: icon(StickyNote2Icon),
    lastOfSection: true,
  },
  // {
  //   title: 'outbound',
  //   path: '/outbound',
  //   icon: icon(PhoneForwardedIcon),
  // },
  // {
  //   title: 'inbound',
  //   path: '/inbound',
  //   icon: icon(PhoneCallbackIcon),
  //   lastOfSection: true,
  // },
  {
    title: 'numbers',
    path: '/numbers',
    icon: icon(DialpadIcon),
  },
  {
    title: 'billing',
    path: '/billing',
    icon: icon(PaymentIcon),
  },
  {
    title: 'manage-api-key',
    path: '/manage-api-key',
    icon: icon(DeviceHubIcon),
  },
  {
    title: 'blacklists',
    path: '/blacklists',
    icon: icon(PhonelinkEraseIcon),
  },
  {
    title: 'audio-record',
    path: '/audio-record',
    icon: icon(SpatialAudioOffIcon),
  },
];

export default navConfig;
