import { apiUrlDetect } from 'utils/domain';
import axiosInstance from '../config/axios';

class APIService {
  constructor(baseURL) {
    this.axios = axiosInstance;

    const domain = window.location.hostname
    const apiUrl = apiUrlDetect[domain];

    this.baseURL = baseURL ? apiUrl + baseURL : apiUrl;
  }

  async get (params = {}) {
    try {
      const response = await this.axios.get(this.baseURL, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch data');
    }
  }

  async getByID (id) {
    try {
      const response = await this.axios.get(`${this.baseURL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch data');
    }
  }

  async create (data) {
    try {
      const response = await this.axios.post(this.baseURL, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to post data');
    }
  }

  async update (id, data) {
    try {
      const response = await this.axios.patch(`${this.baseURL}/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to put data');
    }
  }

  async delete (id) {
    try {
      const response = await this.axios.delete(`${this.baseURL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to delete data');
    }
  }
}

export default APIService;
