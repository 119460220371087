import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
  },
  stopped: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: theme.palette.text.grey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: '3px',
  },
  playing: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: '3px',
  },
}));

const PlaySwitch = ({ status, handleChangeStatus }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {status === 'stopped' ? (
        <div className={classes.stopped} onClick={handleChangeStatus} role="presentation">
          <Iconify icon="solar:play-bold" color="#fff" width={12} height={12} />
        </div>
      ) : (
        <div className={classes.playing} onClick={handleChangeStatus} role="presentation">
          <Iconify icon="line-md:play-filled-to-pause-transition" color="#fff" width={12} height={12} />
        </div>
      )}
    </div>
  );
};

PlaySwitch.propTypes = {
  status: PropTypes.string,
  handleChangeStatus: PropTypes.func,
};

export default PlaySwitch;
