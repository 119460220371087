import React, { useCallback, useState, useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { useTranslation } from 'react-i18next';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Dialog } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import stepForm from './config';

import CallPlan from './components/CallPlan';
import Schedule from './components/Schedule';
import CallStragety from './components/CallStragety';
import UploadContact from './components/UploadContact';
import MapFile from './components/MapFile';
import Summary from './components/Summary';

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => {
  const getColor = () => {
    const { active, completed } = ownerState;
    if (completed) {
      return '#7acc90';
    }
    if (active) {
      return '#78909c';
    }

    return '#cdd1d2';
  };
  return {
    zIndex: 1,
    color: getColor(),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    active: <CheckCircleOutlinedIcon />,
    completed: <CheckCircleIcon />,
  };

  const getIconComp = ({ completed, active }) => {
    if (completed || active) {
      return icons.completed;
    }

    return icons.active;
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {getIconComp({ completed, active })}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const MINUTES_CONVERSION = {
  DAY: 1440,
  HOUR: 60,
  MINUTE: 1,
};

const getMinutesFromObj = (data) => {
  const { day = 0, hour, minute } = data;
  return day * MINUTES_CONVERSION.DAY + hour * MINUTES_CONVERSION.HOUR + minute * MINUTES_CONVERSION.MINUTE;
};

const getObjFromMinutes = (minutes) => {
  const day = Math.floor(minutes / (60 * 24));
  const hour = Math.floor((minutes % (60 * 24)) / 60);
  const remainingMinutes = minutes % 60;

  return {
    day,
    hour,
    minute: remainingMinutes,
  };
};

const CampaignModal = ({
  initialModalData,
  setLoading,
  openModal,
  handleCloseModal,
  steps,
  onSubmit,
  availableContacts,
}) => {
  const { t } = useTranslation('common');
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isConfirmPolicy, setIsConfirmPolicy] = useState(true);

  const [data, setData] = useState(initialModalData);

  const workingHoursParsed = useMemo(() => {
    if (data?.working_hours?.length) {
      return {
        start_time: data.working_hours.map((workingHour, index) => {
          const time = workingHour[0].split(':');
          return {
            index,
            h: time[0],
            m: time[1],
          };
        }),
        end_time: data.working_hours.map((workingHour, index) => {
          const time = workingHour[1].split(':');
          return {
            index,
            h: time[0],
            m: time[1],
          };
        }),
      };
    }
    return {
      start_time: [
        {
          index: 0,
          h: '08',
          m: '30',
        },
        {
          index: 1,
          h: '14',
          m: '00',
        },
      ],
      end_time: [
        {
          index: 0,
          h: '11',
          m: '30',
        },
        {
          index: 1,
          h: '17',
          m: '00',
        },
      ],
    };
  }, [data]);

  const waitingTimeParsed = useMemo(() => {
    const result = {};
    if (data?.min_waiting_time) {
      const numberWinWaitingTime = parseInt(data.min_waiting_time, 10);
      result.min_waiting = getObjFromMinutes(numberWinWaitingTime);
    } else {
      result.min_waiting = {
        day: 1,
        hour: 0,
        minute: 0,
      };
    }
    if (data?.max_waiting_time) {
      const numberMaxWaitingTime = parseInt(data.max_waiting_time, 10);
      result.max_waiting = getObjFromMinutes(numberMaxWaitingTime);
    } else {
      result.max_waiting = {
        day: 1,
        hour: 0,
        minute: 0,
      };
    }

    return result;
  }, [data]);

  const intervalTimeParsed = useMemo(() => {
    if (data?.interval) {
      return getObjFromMinutes(data.interval);
    }
    return {
      day: 0,
      hour: 0,
      minute: 15,
    };
  }, [data]);

  const [startTime, setStartTime] = useState(workingHoursParsed.start_time);
  const [endTime, setEndTime] = useState(workingHoursParsed.end_time);
  const [intervalDay, setIntervalDay] = useState(intervalTimeParsed.day);
  const [intervalTime, setIntervalTime] = useState({
    hour: intervalTimeParsed.hour,
    minute: intervalTimeParsed.minute,
  });
  const [minWaiting, setMinWaiting] = useState(waitingTimeParsed.min_waiting);
  const [maxWaiting, setMaxWaiting] = useState(waitingTimeParsed.max_waiting);
  const [mappedArr, setMapptedArr] = useState([]);
  const [activeCols, setActiveCols] = useState([]);
  const [mapContent, setMapContent] = useState([]);
  const [checkedStartCampaign, setCheckedStartCampaign] = useState(!!+initialModalData?.start_time_active);
  const [checkedEndCampaign, setCheckedEndCampaign] = useState(!!+initialModalData?.end_time_active);
  const [checkedWorkingHours, setCheckedWorkingHours] = useState(false);

  const setField = useCallback(
    (field, value) => {
      setData({
        ...data,
        [field]: value,
      });
    },
    [data]
  );

  const resetData = () => setData(initialModalData);

  const handleCreateCampaign = async () => {
    const formData = new FormData();
    const fullNameIdx = mapContent.findIndex((el) => el.key === 'name');
    const phoneIdx = mapContent.findIndex((el) => el.key === 'phone');

    if (!initialModalData.id) {
      const formattedContact = mappedArr
        .map((el, idx) => {
          if (idx === 0) return null;
          return { name: el[fullNameIdx] || '', phone_number: el[phoneIdx] || '' };
        })
        .filter((item) => item !== null);
      formData.append('contacts', JSON.stringify(formattedContact));
    }

    // Working hour
    // const working_hours = {
    //   daily_start: startTime.map((timeObj) => `${timeObj.m} ${timeObj.h} * * *`),
    //   daily_end: endTime.map((timeObj) => `${timeObj.m} ${timeObj.h} * * *`),
    // };
    const working_hours_temp = {
      daily_start: startTime.map((timeObj) => `${timeObj.h}:${timeObj.m}`),
      daily_end: endTime.map((timeObj) => `${timeObj.h}:${timeObj.m}`),
    };
    const working_hours_values = Object.values(working_hours_temp);
    const working_hours = working_hours_values[0].map((start, index) => [start, working_hours_values[1][index]]);
    delete data.daily_start;
    delete data.daily_end;
    // formData.append('working_hours', JSON.stringify(working_hours));
    working_hours.map((value, index) => value.map((v, i) => formData.append(`working_hours[${index}][${i}]`, v)));

    // Waiting time
    delete data.min_waiting_time;
    delete data.max_waiting_time;
    formData.append('min_waiting_time', getMinutesFromObj(minWaiting));
    formData.append('max_waiting_time', getMinutesFromObj(maxWaiting));

    // Interval time
    delete data.interval;
    formData.append(
      'interval',
      getMinutesFromObj({
        day: intervalDay,
        ...intervalTime,
      })
    );

    // Start campaign & End campaign
    /* 
      This hack is to convert boolean value to number. 
      Ex: +false = 0, +true = 1 
    */
    data.start_time_active = +checkedStartCampaign;
    data.end_time_active = +checkedEndCampaign;

    if (!initialModalData.id) {
      formData.append('contact_detail_file', data.contact_detail_file);
    }
    delete data.contact_detail_file;

    // TODO: check why status automatically convert to null
    if (data?.status === null) {
      data.status = 1;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (key !== 'working_hours') {
        if (typeof data[key] === 'boolean') {
          formData.append(key, +data[key]);
        } else if (Array.isArray(data[key])) {
          data[key].map((value) => formData.append(`${key}[]`, value));
          // formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      onClose();
      setLoading(true);
      if (initialModalData.id) {
        formData.append('_method', 'PUT');
        await onSubmit(initialModalData.id, formData);
      } else {
        await onSubmit(formData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      resetData();
    }
  };

  const onClose = () => {
    handleCloseModal();
    resetData();
    setActiveStep(0);
    setIsConfirmPolicy(false);
  };

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (newActiveStep === 4 && data.contact_group_ids.length) {
      setActiveStep(newActiveStep + 1);
    } else {
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep - 1 === 4 && data.contact_group_ids.length) return prevActiveStep - 2;
      return prevActiveStep - 1;
    });
  };
  const renderProps = (step) => {
    switch (step) {
      case 'Define call strategy':
        return {
          data,
          setData: setField,
          handleNext,
        };
      case 'Call plan':
        return {
          data,
          setData: setField,
          handleNext,
          handleBack,
        };
      case 'Schedule':
        return {
          data,
          setData: setField,
          startTime,
          setStartTime,
          endTime,
          setEndTime,
          intervalDay,
          setIntervalDay,
          intervalTime,
          setIntervalTime,
          minWaiting,
          setMinWaiting,
          maxWaiting,
          setMaxWaiting,
          checkedStartCampaign,
          setCheckedStartCampaign,
          checkedEndCampaign,
          setCheckedEndCampaign,
          checkedWorkingHours,
          setCheckedWorkingHours,
          handleNext,
          handleBack,
        };
      case 'Loading contacts details':
        return {
          data,
          handleNext,
          handleBack,
          isConfirmPolicy,
          setIsConfirmPolicy,
          setData: setField,
          isAvailableContacts: true,
          availableContacts,
        };
      case 'Map':
        return {
          data,
          handleNext,
          handleBack,
          mappedArr,
          setMapptedArr,
          activeCols,
          setActiveCols,
          mapContent,
          setMapContent,
          setData: setField,
        };
      case 'Summary':
        return {
          data,
          intervalDay,
          intervalTime,
          minWaiting,
          maxWaiting,
          checkedStartCampaign,
          checkedEndCampaign,
          startTime,
          endTime,
          handleNext: handleCreateCampaign,
          handleBack,
        };
      default:
        return {
          data,
          handleNext,
          handleBack,
        };
    }
  };

  const renderStepComp = (step, props) => {
    switch (step) {
      case 'Define call strategy':
        return <CallStragety {...props} />;
      case 'Call plan':
        return <CallPlan {...props} />;
      case 'Schedule':
        return <Schedule {...props} />;
      case 'Loading contacts details':
        return <UploadContact {...props} />;
      case 'Map':
        return <MapFile {...props} />;
      case 'Summary':
        return <Summary {...props} />;
      default:
        return <CallPlan />;
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      maxWidth="md"
      sx={{
        '& .MuiPaper-root': {
          padding: '25px 25px 0px 25px',
          width: '850px',
        },
        '& .MuiStepConnector-root': {
          display: 'none',
        },
        '& .MuiStep-root': {
          paddingLeft: '5px !important',
          paddingRight: '5px !important',
          flex: 'unset !important',
        },
        '& .MuiStepLabel-alternativeLabel': {
          marginTop: 'unset !important',
          textAlign: 'left',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Stepper sx={{ alignItems: 'unset', justifyContent: 'space-around' }} alternativeLabel activeStep={activeStep}>
          {stepForm.map(({ title: label }) => (
            <Step
              key={label}
              sx={{
                '& .MuiStepper-root': {
                  display: 'flex',
                },
                '& .MuiStepLabel-root': {
                  display: 'flex',
                  flexDirection: 'row !important',
                },
                '& .MuiStepLabel-label': {
                  marginTop: 'unset',
                },
                '& .MuiStepLabel-labelContainer': {
                  textAlign: 'left',
                  marginLeft: '8px',
                },
                '& .MuiStepLabel-labelContainer span': {
                  fontSize: 16,
                  fontWeight: 500,
                },
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>{t(`table:${label}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
          {renderStepComp(steps[activeStep], renderProps(steps[activeStep]))}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default memo(CampaignModal);

CampaignModal.propTypes = {
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  steps: PropTypes.string,
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  initialModalData: PropTypes.object,
  availableContacts: PropTypes.array,
};
