import { useState } from 'react';
import { produce } from 'immer';

const useToast = () => {
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });

  const handleCloseToast = () => {
    setToast(
      produce((draft) => {
        draft.open = false;
      })
    );
  };

  const handleSetToast = (newToast) => {
    setToast(
      produce((draft) => {
        draft.open = newToast.open || false;
        draft.type = newToast.type || 'success';
        draft.message = newToast.message || '';
      })
    );
  };

  return {
    toast,
    handleCloseToast,
    handleSetToast,
  };
};

export default useToast;
