import { conversations } from '_mock/conversation';

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button, Dialog, Grid, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import Row from '../Row';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '10px',
    marginBottom: '12px',
    backgroundColor: 'rgba(120, 144, 156, 0.1)',
  },
  body: {
    width: '100%',
    maxHeight: 'calc(100vh - 505px)',
    minHeight: '425px',
    overflowY: 'auto',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    marginRight: '4px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
  addButton: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    textTransform: 'unset'
  },
}));

function UpdateSetting({ open, onClose, keys }) {
  const classes = useStyles();
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const headers = [
    {
      label: 'key',
    },
    {
      label: 'name',
    },
    {
      label: 'isVisualized',
      width: 2,
    },
    {
      label: 'type',
    },
    {
      label: '',
      width: 1,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{
        '& .MuiPaper-root': {
          padding: '25px 25px 20px 25px',
          width: '1000px',
        },
        '& .MuiStepConnector-root': {
          display: 'none',
        },
        '& .MuiStep-root': {
          paddingLeft: '5px !important',
          paddingRight: '5px !important',
          flex: 'unset !important',
        },
        '& .MuiStepLabel-alternativeLabel': {
          marginTop: 'unset !important',
          textAlign: 'left',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <div style={{ padding: '10px' }} >
          <Grid container direction="row" className={classes.header} alignItems="center">
            {headers.map((header, idx) => (
              <Grid
                item
                xs={header.width}
                key={header.label}
                style={{
                  flex: '1',
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  borderRadius: `${idx === 0 ? '10px 0 0 10px' : ''}`,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  cursor: `${header.canSort ? 'pointer' : 'default'}`,
                }}
                sx={{
                  color: (theme) => theme.palette.text.black,
                }}
              >
                {header.canSort ? (
                  <div className={classes.sortContainer}>
                    <Iconify
                      style={{ marginBottom: '-11.5px' }}
                      width={22}
                      height={22}
                      icon="material-symbols:keyboard-arrow-up-rounded"
                      className={classes.sortIcon}
                      color="#c6cacc"
                    />
                    <Iconify
                      width={22}
                      height={22}
                      icon="material-symbols:keyboard-arrow-down-rounded"
                      className={classes.sortIcon}
                      color="#c6cacc"
                    />
                  </div>
                ) : null}
                {header.label}
              </Grid>
            ))}
          </Grid>
          <div className={classes.body}>
            {keys.map((el) => (
              <Row key={el.key} propKey={el.key} name={el.name} type={el.type} isVisualized={el.isVisualized} />
            ))}
          </div>
        </div>
        <Grid container item>
          <Button className={classes.addButton}>+</Button>
        </Grid>
        <Grid container item justifyContent='flex-end' sx={{ marginTop: '10px'}}>
          <Button onClick={onClose} sx={{ marginRight: '10px', color: '#78909c' }}>Cancel</Button>
          <Button className={classes.button}>updateSetting</Button>
        </Grid>
      </Box>
    </Dialog>
  );
}

UpdateSetting.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  keys: PropTypes.object,
};

export default UpdateSetting;
