import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  search: {
    borderRadius: '50%',
  },
}));

const SearchBar = ({ setFilter, placeholder }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [showClearIcon, setShowClearIcon] = useState('none');

  const handleChange = (event) => {
    setShowClearIcon(event.target.value === '' ? 'none' : 'flex');
    setFilter(event.target.value);
  };

  return (
    <FormControl className={classes.search}>
      <TextField
        size="small"
        variant="outlined"
        placeholder={placeholder || t('search')}
        onChange={handleChange}
        InputProps={{
          style: {
            borderRadius: '20px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="ic:baseline-search" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ display: showClearIcon }}>
              <Iconify icon="ic:baseline-search" />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

SearchBar.propTypes = {
  setFilter: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchBar;
