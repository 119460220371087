import { createContext, useReducer } from 'react';
import scenarioService from 'services/scenario.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialScenarioState = {
  scenarios: [],
  currentScenario: null,
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SCENARIOS': {
      const { scenarios, total } = action.payload;
      return { ...state, scenarios, total };
    }
    case 'CREATE_SCENARIO': {
      const { scenario } = action.payload;
      console.log('aaaa', [scenario].concat(state.scenarios));
      return { ...state, scenarios: [scenario].concat(state.scenarios), total: state.total + 1 };
    }
    case 'UPDATE_SCENARIO': {
      const { scenario } = action.payload;
      const updatedScenarios = replaceItemInArrayByKey(state.scenarios, 'id', scenario);
      return { ...state, scenarios: updatedScenarios };
    }
    case 'DELETE_SCENARIO': {
      const { id } = action.payload;
      return {
        ...state,
        scenarios: state.scenarios.filter((scenario) => scenario.id !== id),
        total: state.total - 1,
      };
    }

    default:
      return { ...state };
  }
};

const ScenarioContext = createContext({
  ...initialScenarioState,
});

export const ScenarioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialScenarioState);

  const get = async (params) => {
    try {
      const { data: scenarios, meta } = await scenarioService.get(params);
      dispatch({
        type: 'SET_SCENARIOS',
        payload: {
          scenarios,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: scenario } = await scenarioService.create(data);
      dispatch({
        type: 'CREATE_SCENARIO',
        payload: {
          scenario,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: scenario } = await scenarioService.update(id, data);
      dispatch({
        type: 'UPDATE_SCENARIO',
        payload: {
          scenario,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await scenarioService.delete(id);
      dispatch({
        type: 'DELETE_SCENARIO',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const testScenario = async (id, phoneNumber) => {
    await scenarioService.testScenario(id, phoneNumber);
  };

  return (
    <ScenarioContext.Provider value={{ ...state, method: 'Scenario', get, create, update, destroy, testScenario }}>
      {children}
    </ScenarioContext.Provider>
  );
};

ScenarioProvider.propTypes = {
  children: PropTypes.node,
};

export default ScenarioContext;
