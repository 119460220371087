import React, {useMemo} from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';

import { ccuDashboard, ccuDashboardByScenario, ccuDashboardByConversation, ccuDashboardByConversationStatus } from '_mock/ccuDashboard';
import MonitorItem from './MonitorItem';


const layout = {
  title: {
    text:'Plot Title',
    font: {
      size: 26
    },
    y: 0.9,
    yanchor: 'top',
    automargin: true,
    xref: 'paper',
  },
  yaxis: {
    title: {
      text: 'CCU',
      font: {
        size: 16,
        color: 'rgba(0, 0, 0, 0.87)'
      },
      standoff: 30
    },
  },
  xaxis: {
    title: {
      text: 'DateTime',
      font: {
        size: 16,
        color: 'rgba(0, 0, 0, 0.87)'
      },
      standoff: 30
    },
    tickformatstops: [
      { enabled: true, dtickrange: [null, 20000], value: '%H:%M<br>%b %d, %Y' },
      { enabled: true, dtickrange: [20000, null], value: '%b %d' }
    ],
  },
}

const Monitor = () => {
  const xAxis = useMemo(() => ccuDashboard.vertical_points_list.map(el => new Date(el.timepoint)), []);
  const yAxis = useMemo(() => ccuDashboard.vertical_points_list.map(el => el.value), []);

  const xScenarioAxis = ccuDashboardByScenario.vertical_points_list.map(el => moment(el.timepoint).format('HH:MM MMM D, YYYY'));
  const scenarioChart = ccuDashboardByScenario.scenarios.map(el => {
    const data = ccuDashboardByScenario.vertical_points_list.map(v => v[el.scenario_id]);
    return {
      x: xScenarioAxis,
      y: data,
      fill: 'tozeroy',
      type: 'scatter',
      name: el.scenario_name
    }
  })

  const xConversationAxis = ccuDashboardByConversation.vertical_points_list.map(el => moment(el.timepoint).format('HH:MM MMM D, YYYY'));
  const conversationChart = ccuDashboardByConversation.conversation_types.map(el => {
    const data = ccuDashboardByConversation.vertical_points_list.map(v => v[el.conversation_type]);
    return {
      x: xConversationAxis,
      y: data,
      fill: 'tozeroy',
      type: 'scatter',
      name: el.conversation_describe
    }
  });

  const xConversationStatusAxis = ccuDashboardByConversation.vertical_points_list.map(el => moment(el.timepoint).format('HH:MM MMM D, YYYY'));
  const conversationStatusChart = ccuDashboardByConversationStatus.conversation_status_code.map(el => {
    const data = ccuDashboardByConversationStatus.vertical_points_list.map(v => v[el.conversation_status_code]);
    return {
      x: xConversationStatusAxis,
      y: data,
      fill: 'tozeroy',
      type: 'scatter',
      name: el.conversation_status_name
    }
  })

  return (
    <Box>
      <Grid container direction={'row'}>
        <Grid item xs={12} sx={{ marginTop: '16px !important' }}>
          <MonitorItem
            title='CCU Dashboard'
            data={[
              {
                x: xAxis,
                y: yAxis,
                fill: 'tozeroy',
                type: 'scatter',
              },
            ]}
            layout={{
              ...layout,
              title: {
                ...layout.title,
                text: 'CCU Dashboard',
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '16px !important' }}>
          <MonitorItem
            title='CCU Dashboard By Scenario'
            data={scenarioChart}
            layout={{
              ...layout,
              title: {
                ...layout.title,
                text: 'CCU Dashboard By Scenario'
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '16px !important' }}>
          <MonitorItem
            data={conversationChart}
            layout={{
              ...layout,
              title: {
                ...layout.title,
                text: 'CCU Dashboard By Conversation'
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '16px !important' }}>
          <MonitorItem
            data={conversationStatusChart}
            layout={{
              ...layout,
              title: {
                ...layout.title,
                text: 'CCU Dashboard By Conversation Status'
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Monitor;
