const navScenarioConfig = [
  {
    title: 'all',
    // component:
  },
  {
    title: 'outbound',
    // component:
  },
  {
    title: 'inbound',
    // component:
  },
];

export default navScenarioConfig;
