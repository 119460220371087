// @mui
import { Alert, Snackbar } from '@mui/material';

import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

export default function Toast({
  children,
  openToast,
  handleClose,
  vertical = 'top',
  horizontal = 'right',
  type = 'success',
  duration = 3000,
}) {
  return (
    <>
      <Snackbar
        open={openToast}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {children}
        </Alert>
      </Snackbar>
    </>
  );
}

Toast.propTypes = {
  children: PropTypes.node,
  openToast: PropTypes.bool,
  handleClose: PropTypes.func,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  type: PropTypes.string,
  duration: PropTypes.number,
};
