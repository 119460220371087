const navCampaignConfig = [
  {
    title: 'all',
  },
  {
    title: 'draft',
  },
  {
    title: 'processing',
  },
  {
    title: 'ready',
  },
  {
    title: 'scheduled',
  },
  {
    title: 'ongoing',
  },
  {
    title: 'paused',
  },
  {
    title: 'completed',
  },
];

export default navCampaignConfig;
