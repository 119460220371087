import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Toast from 'components/Toast';

import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';

// ----------------------------------------------------------------------

const avatarList = [
  '/assets/images/avatars/avatar_default.jpg',
  '/assets/images/avatars/avatar_1.jpg',
  '/assets/images/avatars/avatar_2.jpg',
  '/assets/images/avatars/avatar_3.jpg',
  '/assets/images/avatars/avatar_4.jpg',
  '/assets/images/avatars/avatar_5.jpg',
];
const randomAvatar = avatarList[Math.floor(Math.random() * 6)];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation('common');
  const { user, logout, changePassword } = useAuth();
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(null);
  // random avatar

  const schema = yup.object().shape({
    old_password: yup.string().required(t('error:required')),
    new_password: yup.string().required(t('error:required')),
    confirm_password: yup.string().oneOf([yup.ref('new_password')], t('error:passwordMustMatch')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { old_password: '', new_password: '' },
    resolver: yupResolver(schema),
  });

  const handleCloseModal = () => {
    setOpenModal(false);
    reset({ old_password: '', new_password: '' });
  };

  const handleOpenModal = () => {
    handleClose();
    setOpenModal(true);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    logout();
  };

  const onSubmit = async (data) => {
    try {
      await changePassword(data);
      handleSetToast({ open: true, type: 'success', message: t('notify:updateSuccess') });
    } catch {
      handleSetToast({ open: true, type: 'error', message: t('notify:updateFail') });
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={randomAvatar} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {/* {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))} */}
          <MenuItem onClick={handleOpenModal}>{t('changePassword')}</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('logout')}
        </MenuItem>
      </Popover>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xs">
        <DialogTitle>{t('changePassword')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="old_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.old_password}
                  helperText={errors?.old_password?.message}
                  margin="dense"
                  label={t('oldPassword')}
                  type="password"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="new_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.new_password}
                  helperText={errors?.new_password?.message}
                  margin="dense"
                  label={t('newPassword')}
                  type="password"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.confirm_password}
                  helperText={errors?.confirm_password?.message}
                  margin="dense"
                  label={t('confirmPassword')}
                  type="password"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('table:cancel')}</Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {t('table:update')}
          </Button>
        </DialogActions>
      </Dialog>

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
