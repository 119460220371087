import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'my_app_token';

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function isAuthenticated() {
  const token = getToken();
  // TODO: check user info bằng token
  return !!token;
}

export function hasPermission(permission = '', permissionsArray = []) {
  return permission ? permissionsArray.indexOf(permission) !== -1 : true;
}

export function filterRoutes(routes, permissions) {
  return routes.filter((route) => {
    if (route.children) {
      route.children = filterRoutes(route.children, permissions);
      return route.children.length > 0;
    }
    return hasPermission(route.permission, permissions);
  });
}

export const isValidToken = () => {
  if (!getToken()) {
    return false;
  }

  const decoded = jwtDecode(getToken());
  const currentTime = Date.now() / 1000;

  return !decoded.exp || decoded.exp > currentTime;
};
