import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { blackList } from '_mock/blackList';
import { callRule } from '_mock/callRule';
import useScenario from 'hooks/useScenario';
import useNumber from 'hooks/useNumber';
import { useTranslation } from 'react-i18next';

import Item from './Item';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: '#78909c',
    fontSize: '12px',
    textAlign: 'left',
  },
}));

export const translateDayToVni = (day) => {
  switch (day) {
    case 'Monday':
      return 'Thứ 2';
    case 'Tuesday':
      return 'Thứ 3';
    case 'Wednesday':
      return 'Thứ 4';
    case 'Thursday':
      return 'Thứ 5';
    case 'Friday':
      return 'Thứ 6';
    case 'Saturday':
      return 'Thứ 7';
    case 'Sunday':
      return 'Chủ nhật';
    default:
      return '';
  }
};

const parseWorkingHours = (startTime, endTime) =>
  startTime.map((value, idx) => `${value.h}:${value.m} - ${endTime[idx].h}:${endTime[idx].m}`);

const Summary = ({
  data,
  intervalDay,
  intervalTime,
  minWaiting,
  maxWaiting,
  startTime,
  endTime,
  checkedStartCampaign,
  checkedEndCampaign,
  handleNext,
  handleBack,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const {
    name,
    scenario_id,
    number_id,
    number_phone_lines,
    call_days,
    call_rule,
    blacklist_ids,
    start_time,
    end_time,
    number_of_attempts,
    duplicate,
    max_waiting_time_active,
    min_waiting_time_active,
    contact_type
  } = data;
  const { scenarios } = useScenario();
  const { numbers } = useNumber();
  const parsedCallDays = useMemo(() => {
    if (typeof call_days === 'string') {
      return JSON.parse(call_days);
    }
    return call_days;
  }, [call_days]);

  const contactTypeLabel = useMemo(() => {
    switch (contact_type) {
      case '1':
        return 'Only Viettel';
      case '2':
        return 'No Viettel';
      default:
          return 'All';
    }
  }, [contact_type])

  return (
    <>
      <Box
        sx={{
          padding: '10px',
          background: '#f6f9fa',
          borderRadius: '8px',
          marginBottom: '10px',
          maxHeight: '600px',
          overflowY: 'scroll',
        }}
      >
        <Typography variant="h6" color={'#3e4345'} align="center" marginY={2}>
          Campaign summary
        </Typography>
        <Typography sx={{ textAlign: 'center !important' }} className={classes.label}>
          You can go back and make changes at any step.
        </Typography>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Item icon={'material-symbols:flag'} classes={classes} label={t('table:campaign_name')} value={name} />
          </Grid>
          <Grid item xs={6}>
            <Item
              icon={'fa6-solid:diagram-project'}
              classes={classes}
              label={t('scenario')}
              value={scenarios.find((scenario) => scenario.id === scenario_id)?.name || '--'}
            />
          </Grid>
          <Grid item xs={6}>
            <Item
              icon={'mdi:phone-outgoing'}
              classes={classes}
              label={t('table:phoneNumber')}
              value={numbers.find((number) => number.id === number_id)?.number || '--'}
            />
          </Grid>
          <Grid item xs={6}>
            <Item
              icon={'ph:line-segments-fill'}
              classes={classes}
              label={t('table:number_phone_lines')}
              value={number_phone_lines}
            />
          </Grid>
          <Grid item xs={6}>
            <Item
              icon={'heroicons:calendar-days-solid'}
              classes={classes}
              label={t('table:callDays')}
              value={parsedCallDays && parsedCallDays?.map && parsedCallDays?.map((el) => el).join(', ')}
            />
          </Grid>
          <Grid item xs={6}>
            <Item
              icon={'ri:exchange-fill'}
              classes={classes}
              label={t('table:call_rule')}
              value={
                callRule.find((s) => s.value === call_rule) ? callRule.find((s) => s.value === call_rule).label : ''
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Item
              icon={'mdi:phone-cancel'}
              classes={classes}
              label={t('table:blacklists')}
              value={
                blacklist_ids
                  ? blackList
                      .filter((b) => blacklist_ids.includes(b.value))
                      .map((el) => el.label)
                      .join(', ')
                  : []
              }
            />
          </Grid> */}
          <Grid item xs={6}>
            <Item
              icon={'ri:contacts-book-3-line'}
              classes={classes}
              label={t('table:contactType')}
              value={contactTypeLabel}
            />
          </Grid>
        </Grid>

        <hr />

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color={'#3e4345'} align="left" marginY={1}>
              Schedule
            </Typography>
          </Grid>
          <Grid container item xs={6} direction="row">
            <Grid item xs={12}>
              <Item
                icon={'akar-icons:schedule'}
                classes={classes}
                label={t('table:campaign_start')}
                value={checkedStartCampaign ? start_time : '--'}
              />
            </Grid>
            <Grid item xs={12}>
              <Item
                icon={'akar-icons:schedule'}
                classes={classes}
                label={t('table:campaign_end')}
                value={checkedEndCampaign ? end_time : '--'}
              />
            </Grid>
            <Grid item xs={12}>
              <Item
                icon={'mdi:clock-time-four-outline'}
                classes={classes}
                label={t('table:workingHours')}
                value={parseWorkingHours(startTime, endTime).map((workingHour) => (
                  <div>{workingHour}</div>
                ))}
              />
            </Grid>
          </Grid>

          <Grid container item xs={6} direction="row" sx={{ background: '#fff' }}>
            <Grid item xs={12}>
              <Item classes={classes} label={t('table:numberAttempt')} value={number_of_attempts} />
            </Grid>
            <Grid item xs={12}>
              <Item
                classes={classes}
                label={t('table:interval')}
                value={`${intervalDay * 24 * 60 + intervalTime.hour * 60 + intervalTime.minute} minutes `}
              />
            </Grid>
            <Grid item xs={12}>
              <Item classes={classes} label={t('table:checkDuplicate')} value={duplicate ? 'Yes' : 'No'} />
            </Grid>
            <Grid item xs={12}>
              <Item
                classes={classes}
                label={t('table:minWaitingTime')}
                value={
                  min_waiting_time_active
                    ? `${minWaiting.day * 24 * 60 + minWaiting.hour * 60 + minWaiting.minute} minutes`
                    : '--'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Item
                classes={classes}
                label={t('table:maxWaitingTime')}
                value={
                  max_waiting_time_active
                    ? `${maxWaiting.day * 24 * 60 + maxWaiting.hour * 60 + maxWaiting.minute} minutes`
                    : '--'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', textAlign: 'right' }}
      >
        <Grid container direction={'row'}>
          <Grid container item xs={6} alignItems="left">
            {/* <Button variant="contained" type="submit" onClick={handleNext}>
              {t('saveAsDraft')}
            </Button> */}
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                ':hover': {
                  boxShadow:
                    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                  backgroundColor: '#d5d5d5',
                },
                backgroundColor: '#e0e0e0',
                marginRight: '10px',
                color: 'rgba(0, 0, 0, 0.87);',
              }}
              variant="contained"
              onClick={handleBack}
            >
              {t('back')}
            </Button>
            <Button variant="contained" type="submit" onClick={handleNext}>
              {t('run')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Summary.propTypes = {
  data: PropTypes.object,
  intervalDay: PropTypes.number,
  intervalTime: PropTypes.object,
  minWaiting: PropTypes.object,
  maxWaiting: PropTypes.object,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  checkedStartCampaign: PropTypes.bool,
  checkedEndCampaign: PropTypes.bool,
};

export default Summary;
