import React from 'react';
import Iconify from 'components/iconify';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const HelpText = ({ text, classes }) => (
    <Tooltip title={text} placement="top">
      <Iconify icon="mdi:warning-circle-outline" className={classes.icon} />
    </Tooltip>
  );

HelpText.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
};

export default HelpText;
