import SearchBar from 'components/Search';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { Stack, Button, Popover, MenuItem, Box, Grid } from '@mui/material';
import useToast from 'hooks/useToast';
import useNumber from 'hooks/useNumber';
import { TELECOM } from 'constants';
// components
import Iconify from 'components/iconify/Iconify';
import Toast from 'components/Toast';
import numberService from 'services/number.service';
import ManageModal from '../ManageModal';
import navList from '../nav';
import NumberTable from '../NumberTable';
import BuyNumber from '../CreateNumber';

export default function NumberList () {
  const { t } = useTranslation('common');
  const { numbers, total, create, get } = useNumber();
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const [filter, setFilter] = useState('');
  const [open, setOpen] = useState(null);
  const [telecomType, setTelecomType] = useState(navList[0].title);
  const [page, setPage] = useState(1);
  const PAGINATION_LIMIT = 20;
  const headers = [
    {
      label: 'No',
      canSort: true,
      width: 1,
    },
    {
      label: t('table:number'),
      canSort: true,
    },
    {
      label: t('table:telecom'),
      canSort: false,
    },
    {
      label: 'Viettel',
      canSort: false,
    },
    {
      label: 'Vinaphone',
      canSort: false,
    },
    {
      label: 'Mobifone',
      canSort: false,
    },
    {
      label: t('table:note'),
      canSort: false,
    },
    {
      label: t('active'),
      canSort: false,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleCreateNumber = async (data) => {
    handleCloseModal();
    await create(data);
    handleSetToast({ open: true, type: 'success', message: t('notify:addSuccess') });
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onCreate = () => {
    handleOpenModal();
  };

  const onCheckNumber = async () => {
    numberService.checkNumber();
    await get({ page, limit: PAGINATION_LIMIT });
    handleSetToast({ open: true, type: 'success', message: t('notify:checkedNumber') });
  };

  const onUpdate = () => {
    handleOpenModal();
  };

  const numberOfPages = useMemo(
    () => (total % PAGINATION_LIMIT === 0 ? total / PAGINATION_LIMIT : Math.floor(total / PAGINATION_LIMIT) + 1),
    [total]
  );

  const handleChangePage = async (event, page) => {
    await get({ page, limit: PAGINATION_LIMIT });
    setPage(page);
  };

  useEffect(() => {
    const handleGetNumbers = async () => {
      const telecomFilter = telecomType === 'active' ? { active: 1 } : { telecom: TELECOM[telecomType] };
      await get({ page, limit: PAGINATION_LIMIT, keyword: filter, ...telecomFilter });
    };

    handleGetNumbers();
  }, [page, filter, telecomType]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchBar setFilter={setFilter} placeholder={t('search')} />
        <div>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" color="#fff" />} onClick={onCreate}>
            {t('table:add')}
          </Button>
          <Button variant="contained"
            sx={{
              bgcolor: 'rgb(141 32 177)',
              height: 36,
              '&:hover': {
                bgcolor: 'rgb(113 29 141)'
              },
              ml: 2
            }}
            onClick={onCheckNumber}><Iconify icon="streamline:arrow-reload-horizontal-1-solid" color="#fff" />
          </Button>
        </div>
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{
            '&>.MuiGrid-item:first-of-type': {
              paddingTop: 0,
            },
          }}
        >
          <Grid item xs={4} md={1.5}>
            <Box>
              <Stack direction={'column'} alignItems={'start'} p={1} gap={0.5}>
                {navList.map(({ title }) => (
                  <Button
                    color="inherit"
                    key={title}
                    sx={{
                      py: 0.5,
                      px: 1,
                      width: '100%',
                      justifyContent: 'start',
                      color: (theme) =>
                        telecomType === title ? theme.palette.primary.main : theme.palette.text.disabled,
                    }}
                    onClick={() => setTelecomType(title)}
                  >
                    {t(title)}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={8} md={10.5}>
            <NumberTable
              numbers={numbers}
              headers={headers}
              handleOpenModal={() => { }}
              numberOfPages={numberOfPages}
              handleChangePage={handleChangePage}
              page={page}
              paginationLimit={PAGINATION_LIMIT}
            />
          </Grid>
        </Grid>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={onUpdate}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:update')}
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      <ManageModal title={t('table:createNumber')} openModal={openModal} handleCloseModal={handleCloseModal}>
        <BuyNumber onClose={handleCloseModal} onSubmit={handleCreateNumber} />
      </ManageModal>

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
