import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ConfirmDelete = ({ onClose, onSubmit }) => {
  const { t } = useTranslation('common');
  const handleClose = () => {
    onClose();
  };

  const { handleSubmit } = useForm();

  return (
    <div>
      <DialogContent sx={{ padding: 0 }}> </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleClose} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: '#fff',
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {t('table:delete')}
        </Button>
      </DialogActions>
    </div>
  );
};

ConfirmDelete.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmDelete;
