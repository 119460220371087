import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Iconify from 'components/iconify/Iconify';
import moment from 'moment';
import NotFound from 'components/NotFound';
import VersionNotfound from 'assests/images/version_notfound.svg'

const useStyles = makeStyles(() => ({
  versionList: {
    listStyleType: 'none',
    width: '338px',
    height: '350px',
  },
  item: {
    padding: '17px 10px',
    borderBottom: '1px solid #e3e5e6',
  },
  label: {
    marginBlockStart: 'unset !important',
    marginBlockEnd: '12px !important',
  }
}))

const Versions = ({ versions }) => {
  const classes = useStyles();

  if(versions.length === 0 ) return <NotFound title='This Scenario has no version yet!' image={VersionNotfound} />

  return (
    <ul className={classes.versionList}>
      {versions
        .map((item, idx) => versions[versions.length - 1 - idx])
        .map((ver) => (
          <li key={ver.published_at} className={classes.item}>
            <Grid container direction="row">
              <Grid item container direction="column" xs={8}>
                <h3 className={classes.label}>{ver.scenario_publication_name}</h3>
                <div>{moment(ver.published_at).format('MM/DD/YYYY, h:mm:ss A')}</div>
              </Grid>
              {ver.is_current_publication && (
                <Grid xs={4} item container justifyContent="flex-end" alignItems="center">
                  <Iconify icon="material-symbols:check-circle-outline" />
                </Grid>
              )}
            </Grid>
          </li>
        ))}
    </ul>
  );
};

Versions.propTypes = {
  versions: PropTypes.array
}

export default Versions