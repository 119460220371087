import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ccuDashboard,
  ccuDashboardByScenario,
  ccuDashboardByConversation,
  ccuDashboardByConversationStatus,
} from '_mock/ccuDashboard';
// @mui
import { Grid, Box, TableBody, TableCell, TableContainer, TablePagination, Chip } from '@mui/material';
// hooks

// components
import TimelineChart from './TimelineChart';
// sections

// const

const layout = {
  title: {
    text: 'Plot Title',
    font: {
      size: 26,
    },
    y: 0.9,
    yanchor: 'top',
    automargin: true,
    xref: 'paper',
  },
  yaxis: {
    title: {
      text: 'CCU',
      font: {
        size: 16,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      standoff: 30,
    },
  },
  xaxis: {
    title: {
      text: 'DateTime',
      font: {
        size: 16,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      standoff: 30,
    },
    tickformatstops: [
      { enabled: true, dtickrange: [null, 20000], value: '%H:%M<br>%b %d, %Y' },
      { enabled: true, dtickrange: [20000, null], value: '%b %d' },
    ],
  },
};

export default function Overview() {
  const { t } = useTranslation('common');
  const xAxis = useMemo(() => ccuDashboard.vertical_points_list.map((el) => new Date(el.timepoint)), []);
  const yAxis = useMemo(() => ccuDashboard.vertical_points_list.map((el) => el.value), []);

  const xScenarioAxis = ccuDashboardByScenario.vertical_points_list.map((el) =>
    moment(el.timepoint).format('HH:MM MMM D, YYYY')
  );
  const scenarioChart = ccuDashboardByScenario.scenarios.map((el) => {
    const data = ccuDashboardByScenario.vertical_points_list.map((v) => v[el.scenario_id]);
    return {
      x: xScenarioAxis,
      y: data,
      fill: 'tozeroy',
      type: 'scatter',
      name: el.scenario_name,
    };
  });

  return (
    <>
      <Helmet>
        <title> {sentenceCase('overview')} </title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Box sx={{ fontWeight: 'medium' }}>Billing ID:</Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ fontStyle: 'italic' }}>#319</Box>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ fontWeight: 'medium' }}>Plan:</Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ fontStyle: 'italic' }}>CALL_SUBCRIPTION</Box>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ fontWeight: 'medium' }}>CCU:</Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ fontStyle: 'italic' }}>55</Box>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ fontWeight: 'medium' }}>Call remain:</Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ fontStyle: 'italic' }}>3700</Box>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ fontWeight: 'medium' }}>Expire date:</Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ fontStyle: 'italic' }}>31/05/2023</Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '16px !important' }}>
        <TimelineChart
          title="CCU Dashboard"
          data={scenarioChart}
          layout={{
            ...layout,
            title: {
              ...layout.title,
              text: 'CCU Dashboard',
            },
          }}
        />
      </Grid>
    </>
  );
}
