import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
// @mui
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Autocomplete,
  Popover,
  MenuItem,
} from '@mui/material';
// hooks
import useContactGroup from 'hooks/useContactGroup';
import useCampaign from 'hooks/useCampaign';
import useToast from 'hooks/useToast';

import { PAGINATION } from 'constants';
// components
import SearchBar from 'components/Search';
import Toast from 'components/Toast';
import Table from 'components/Table';
import Iconify from '../../components/iconify';
import ContactGroupModal from './ContactGroupModal';

import { DIALOG_STATUS } from '../../constants/dialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '7.5px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const initialModalData = {
  name: '',
  campaign_id: '',
  note: '',
  contact_detail_file: 0,
  contact_type: 0,
};

export default function NumberGroupPage() {
  const { t } = useTranslation('common');
  const { contacts, total, get, create, update, destroy } = useContactGroup();
  const { campaigns, get: getCampaigns } = useCampaign();
  const classes = useStyles();
  const [openAction, setOpenAction] = useState(null);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [page, setPage] = useState(1);
  const [campaignFilter, setCampaignFilter] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const [selectedRow, setSelectedRow] = useState(null);
  const { toast, handleSetToast, handleCloseToast } = useToast();

  const headers = [
    {
      label: 'ID',
      canSort: false,
      width: 1,
    },
    {
      label: t('table:name'),
      canSort: false,
    },
    {
      label: t('table:totalContact'),
      canSort: false,
    },
    {
      label: t('table:campaignName'),
      canSort: false,
    },
    {
      label: t('table:note'),
      canSort: false,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) setSelectedRow(null);
    setDialogStatus(status);
    setOpenModal(true);
    handleCloseMenu();
  };

  const handleOpenMenu = (event, row) => {
    setSelectedRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenAction(null);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleDeleteContactGroup = async () => {
    destroy(selectedRow.id);
    handleCloseModal();
  };

  useEffect(() => {
    const handleGetCampaigns = async () => {
      await getCampaigns();
    };

    try {
      handleGetCampaigns();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const handleGetContacts = async () => {
      await get({ page: 0, limit: PAGINATION.LIMIT, keyword: keywordFilter, campaign_id: campaignFilter?.id });
    };

    try {
      handleGetContacts();
    } catch (err) {
      console.log(err);
    }
  }, [campaignFilter, keywordFilter]);

  return (
    <>
      <Helmet>
        <title>{sentenceCase('manage-number-group')}</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <div
          style={{
            display: 'flex',
            gap: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '550px',
            flex: '1',
          }}
        >
          <SearchBar setFilter={setKeywordFilter} />
          <Autocomplete
            value={campaignFilter}
            size="small"
            id="campaigns-filter"
            options={campaigns}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            onChange={(event, newValue) => setCampaignFilter(newValue)}
            renderInput={(params) => <TextField {...params} label={t('searchByCampaign')} />}
          />
        </div>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
          onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
        >
          {t('table:add')}
        </Button>
      </Stack>

      <Table total={total} get={get} headers={headers}>
        {contacts.map((contact) => (
          <Grid
            key={contact.id}
            container
            direction={'row'}
            alignContent={'center'}
            style={{ borderBottom: '#e3e5e6 1px solid' }}
          >
            <Grid item className={classes.row} xs={1}>
              <div className={classes.content}>{contact?.id}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{contact?.name}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{contact?.total_contact}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{contact?.campaign_name}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content} style={{ whiteSpaceCollapse: 'break-spaces' }}>
                {contact?.note}
              </div>
            </Grid>
            <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
              <Button className={classes.button} onClick={(event) => handleOpenMenu(event, contact)}>
                <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </Table>

      <Popover
        open={openAction}
        anchorEl={openAction}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.UPDATE)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:edit')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.DELETE)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      {openModal && dialogStatus !== DIALOG_STATUS.DELETE && (
        <ContactGroupModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          steps={dialogStatus === DIALOG_STATUS.CREATE ? ['Loading contacts details', 'Map', 'Summary'] : ['Summary']}
          initialModalData={dialogStatus === DIALOG_STATUS.CREATE ? initialModalData : selectedRow}
          onSubmit={dialogStatus === DIALOG_STATUS.CREATE ? create : update}
          campaigns={campaigns}
        />
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>

      <Dialog open={openModal && dialogStatus === DIALOG_STATUS.DELETE} onClose={handleCloseModal} maxWidth="xs">
        <DialogTitle>{t('table:deleteContactGroup')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('table:cancel')}</Button>
          <Button type="submit" onClick={handleDeleteContactGroup}>
            {t('table:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
