import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const EditNumber = ({ data, onClose, onSubmit }) => {
  const { t } = useTranslation('common');

  const telecoms = [
    { name: 'None', value: 0 },
    { name: 'Viettel', value: 1 },
    { name: 'Mobifone', value: 2 },
    { name: 'Vinaphone', value: 3 },
  ];

  const schema = yup.object().shape({
    number: yup.string().required(t('error:roleNameRequired')),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <div>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="number"
            control={control}
            defaultValue={data.number}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.number}
                helperText={errors?.number?.message}
                margin="dense"
                label={t('table:number')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />

          <Controller
            name="telecom"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth sx={{ marginTop: '10px' }}>
                <InputLabel id="demo-simple-select-label">{t('table:telecom')}</InputLabel>
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t('table:telecom')}
                  defaultValue={data.telecom || 0}
                >
                  {telecoms.map((telecom) => (
                    <MenuItem key={telecom.value} value={telecom.value}>
                      {telecom.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="note"
            control={control}
            defaultValue={data.note}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.note}
                helperText={errors?.note?.message}
                margin="dense"
                label={t('table:note')}
                fullWidth
                variant="outlined"
                multiline
                rows={2}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleClose} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: '#fff',
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {t('table:update')}
        </Button>
      </DialogActions>
    </div>
  );
};

EditNumber.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default EditNumber;
