import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TimePicker from './TimePicker';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  result: {
    textAlign: 'center',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TimeRangeSelector = ({ pickedStart, pickedEnd, onChange }) => {
  const classes = useStyles();

  const handleChangeStart = (time) => {
    const clone = pickedStart;
    clone.setHours(time.h, time.m);
    onChange((data) => ({
      ...data,
      startTime: clone,
    }));
  };

  const handleChangeEnd = (time) => {
    const clone = pickedEnd;
    clone.setHours(time.h, time.m);
    onChange((data) => ({
      ...data,
      endTime: clone,
    }));
  };

  return (
    <div className={classes.container}>
      <div>
        <div>
          <div className={classes.result}>{`${
            pickedStart?.getHours() < 10 ? `0${pickedStart?.getHours()}` : pickedStart?.getHours()
          }:${pickedStart?.getMinutes() < 10 ? `0${pickedStart?.getMinutes()}` : pickedStart?.getMinutes()}`}</div>
          <TimePicker pickedTime={pickedStart} handleChange={handleChangeStart} />
        </div>
      </div>
      <span style={{ width: '1px', background: '#dcdcdc' }} />
      <div>
        <div>
          <div className={classes.result}>{`${
            pickedEnd?.getHours() < 10 ? `0${pickedEnd?.getHours()}` : pickedEnd?.getHours()
          }:${pickedEnd?.getMinutes() < 10 ? `0${pickedEnd?.getMinutes()}` : pickedEnd?.getMinutes()}`}</div>
          <TimePicker pickedTime={pickedEnd} handleChange={handleChangeEnd} />
        </div>
      </div>
    </div>
  );
};

TimeRangeSelector.propTypes = {
  onChange: PropTypes.func,
  pickedStart: PropTypes.object,
  pickedEnd: PropTypes.object,
};

export default TimeRangeSelector;
