/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { language } = useAuth();
  const locale = useMemo(() => (language === 'en' ? 'enUS' : 'viVN'), [language]);

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const themeWithLocale = createTheme(themeOptions, locales[locale]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
