import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import moment from 'moment';
import CampaignNotfound from 'assests/images/campaign_notfound.svg';
import LoadingScreen from 'components/LoadingScreen';

// @mui
import { Stack, Button, Pagination, Grid, Box, InputAdornment, TextField, CircularProgress } from '@mui/material';
import Iconify from 'components/iconify';
import { CAMPAIGN_STATUS } from 'constants';

import useToast from 'hooks/useToast';
import Toast from 'components/Toast';
import useCampaign from 'hooks/useCampaign';
import useContactGroup from 'hooks/useContactGroup';
import { makeStyles } from '@mui/styles';
// import { campaigns } from '_mock/campaign';
import navCampaignConfig from './config';
import CampaignModal from './CampaignModal';
import CampaignItem from '../CampaiginItem';
import NotFound from '../../../../components/NotFound';

const initialModalData = {
  name: '',
  scenario_id: '',
  scenario_publication_id: 0,
  number_of_attempts: 1,
  interval: 5,
  contact_detail_file: 0,
  number_phone_lines: 150,
  start_time_active: 0,
  start_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  end_time_active: 0,
  end_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  blacklist_ids: [],
  working_hours_active: 0,
  daily_start: ['0 1 * * *', '0 3 * * *', '0 0 * * *', '0 0 * * *'],
  daily_end: ['0 23 * * *', '0 20 * * *', '0 15 * * *', '0 15 * * *'],
  callback_url: '',
  voice_id: '',
  duplicate: 1,
  check_duplicate_interval: 1440,
  max_waiting_time: 0,
  max_waiting_time_active: 0,
  min_waiting_time: 0,
  min_waiting_time_active: 0,
  validate_phone_number: 1,
  number_id: '',
  sub_number_id: '',
  call_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  call_rule: 0,
  call_center: 0,
  call_plan_type: 0,
  contact_group_ids: [],
  contact_type: 0,
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.dark,
    cursor: 'pointer',
    fontSize: '16px',
    '&:hover': {
      color: theme.palette.text.blue,
      textDecoration: 'underline',
    },
  },
  tooltipTitle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  titleSmall: {
    color: '#fff',
    fontSize: '14px',
  },
  tooltipInfo: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    letterSpacing: '0.44px',
    overflow: 'hidden',
    display: 'flex',
    marginBottom: '12px',
    marginTop: '16px',
  },
  tooltipLabel: {
    color: '#c6cacc',
    flex: 1,
  },
  tooltipContent: {
    flex: 1,
  },
  navItem: {
    padding: '5px 10px',
    width: '100%',
    justifyContent: 'start',
    color: theme.palette.text.disabled,
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
}));

const isCampaignChildRoute = /^\/outbound\/campaigns(?!$)(?:\/(\d+)(?:\/[^/]+)?)?$/;
function CampaignPage() {
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const { contacts: availableContacts, get: getAvailableContactGroups } = useContactGroup();
  const { get, campaigns, create, total } = useCampaign();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [filter, setFilter] = useState('');
  const [openTooltip, setOpenTooltip] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const CAMPAIGN_PAGINATION_LIMIT = 10;

  const numberOfPages = useMemo(
    () =>
      total % CAMPAIGN_PAGINATION_LIMIT === 0
        ? total / CAMPAIGN_PAGINATION_LIMIT
        : Math.floor(total / CAMPAIGN_PAGINATION_LIMIT) + 1,
    [total]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await get({ page, limit: CAMPAIGN_PAGINATION_LIMIT, keyword: filter, status: CAMPAIGN_STATUS[filterType] });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filter, page, filterType]);

  useEffect(() => {
    const handleGetContactGroups = async () => {
      await getAvailableContactGroups();
    };
    try {
      handleGetContactGroups();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  const handleTooltipOpen = (e, index) => {
    e.stopPropagation();
    setOpenTooltip(index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (title) => {
    setPage(1);
    setFilterType(title);
  };

  const handleSetFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };

  // if (loading) return <LoadingScreen />;

  if (isCampaignChildRoute.test(location.pathname))
    return (
      <>
        <Outlet />
      </>
    );

  return (
    <>
      <Stack sx={{ padding: '0 20px' }} direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <TextField
          id="outlined-start-adornment"
          sx={{
            m: 1,
            width: '478px',
            '&>.MuiInputBase-root': {
              borderRadius: '50px',
            },
          }}
          placeholder={t('search')}
          size="small"
          onChange={handleSetFilter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />

        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" color="#fff" />} onClick={handleOpenModal}>
          {t('createCampaign')}
        </Button>
      </Stack>
      {!loading ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{
                '&>.MuiGrid-item:first-of-type': {
                  paddingTop: 0,
                },
              }}
            >
              <Grid item xs={4} md={1.5}>
                <Box>
                  <Stack direction={'column'} alignItems={'start'} p={1} gap={0.5}>
                    {navCampaignConfig.map(({ title }) => (
                      <Button
                        color="inherit"
                        key={title}
                        className={classes.navItem}
                        onClick={() => handleFilterByName(title)}
                        sx={{ color: `${filterType === title ? '#2065d1 !important' : '#919EAB !important'}` }}
                      >
                        {t(title)}
                      </Button>
                    ))}
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={8} md={10.5}>
                <Grid
                  container
                  rowSpacing={2}
                  sx={{
                    maxHeight: `calc(100vh - 300px)`,
                    overflowY: 'scroll',
                    display: 'block',
                    paddingRight: 1.5,
                    '&>.MuiGrid-item': {
                      paddingTop: 0,
                      paddingBottom: 2,
                    },
                  }}
                >
                  {campaigns.length > 0 ? (
                    campaigns.map((campaign, index) => (
                      <CampaignItem
                        key={campaign.id}
                        index={index}
                        openTooltip={openTooltip}
                        handleTooltipClose={handleTooltipClose}
                        styles={classes}
                        handleTooltipOpen={handleTooltipOpen}
                        setLoading={setLoading}
                        handleSetToast={handleSetToast}
                        data={campaign}
                      />
                    ))
                  ) : (
                    <NotFound title="Campaign not found!" image={CampaignNotfound} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {campaigns.length > 0 && (
            <Grid container direction={'row'} justifyContent="center" sx={{ marginTop: '15px' }}>
              <Pagination
                count={numberOfPages}
                page={page}
                size="large"
                shape="rounded"
                onChange={handleChangePage}
                className={classes.pagination}
              />
            </Grid>
          )}
        </>
      ) : (
        <Box sx={{ display: 'flex', height: '400px', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={55} />
        </Box>
      )}

      <CampaignModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        steps={['Define call strategy', 'Call plan', 'Schedule', 'Loading contacts details', 'Map', 'Summary']}
        setLoading={setLoading}
        initialModalData={initialModalData}
        onSubmit={create}
        availableContacts={availableContacts}
      />

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}

export default CampaignPage;
