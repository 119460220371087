import * as XLSX from 'xlsx';

function excelExport(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // eslint-disable-next-line array-callback-return
      // const idxOfKeepColumn = rows[0].map((el,idx) => {
      //   if(columnsToKeep.includes(el)) {
      //     return idx;
      //   } return null;
      // }).filter(el => el !== null);

      // const filteredColumns = rows.map((el) => idxOfKeepColumn.map((item) => el[item]));
      
      resolve(rows);
    };

    reader.onerror = (event) => {
      reject(event.target.error);
    };

    reader.readAsArrayBuffer(file);
  });
    
}

export default excelExport;
