const navList = [
  {
    title: 'all',
  },
  {
    title: 'viettel',
  },
  {
    title: 'mobifone',
  },
  {
    title: 'vinaphone',
  },
  {
    title: 'active',
  },
];

export default navList;
