import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    minWidth: '200px',
    padding: '8px',
    background: '#fff',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
  },
  item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(120, 144, 156, 0.1) !important',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    textDecoration: 'none',
    color: theme.palette.text.black,
    fontWeight: '500',
    fontSize: '16px'
  },
}));

const FilterList = ({ list, setSelectedFilter }) => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {list.map((el) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <li key={el.value} className={classes.item} onClick={() => {
          setSelectedFilter(el)
        }}>
          <Iconify icon={el.icon} width={20} color='#78909c'/>
          <span style={{ marginLeft: '8px' }}>{el.label}</span>
        </li>
      ))}
    </ul>
  );
};

FilterList.propTypes = {
  list: PropTypes.array,
  setSelectedFilter: PropTypes.func,
}

export default FilterList;
