/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, Switch } from '@mui/material';
import Iconify from 'components/iconify';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import HelpText from '../../../../HelpText';

const WorkingHours = ({
  index,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  classes,
  removeWorking,
  state,
  setState,
  help,
}) => (
  <>
    {index === 1 ? (
      <Grid item xs={2}>
        <Switch checked={state} onChange={(e) => setState(e.target.checked)} />
      </Grid>
    ) : (
      <Grid item xs={2}>
        {' '}
      </Grid>
    )}
    <Grid container item xs={10} spacing={1.5}>
      <Grid container item xs={11.3} spacing={1.5}>
        <Grid item xs={6}>
          <TimePicker
            className={classes.autoComplete}
            defaultValue={moment(`2022-04-17T${startTime[index]?.h || '00'}:${startTime[index]?.m || '00'}`)}
            sx={{ input: { height: '29px' } }}
            slotProps={{ textField: { size: 'small' } }}
            disabled={!state}
            ampm={false}
            onChange={(value) => {
              setStartTime(index, {
                hour: value.format('HH'),
                minute: value.format('mm'),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            defaultValue={moment(`2022-04-17T${endTime[index]?.h || '00'}:${endTime[index]?.m || '00'}`)}
            className={classes.autoComplete}
            sx={{ input: { height: '29px' } }}
            slotProps={{ textField: { size: 'small' } }}
            disabled={!state}
            ampm={false}
            onChange={(value) => {
              setEndTime(index, {
                hour: value.format('HH'),
                minute: value.format('mm'),
              });
            }}
          />
        </Grid>
      </Grid>

      {index === 1 ? (
        <Grid container direction={'row'} item xs={0.7} justifyContent={'end'} sx={{ paddingLeft: 'unset !important' }}>
          <HelpText classes={classes} text={help} />
        </Grid>
      ) : (
        <Grid container direction={'row'} item xs={0.7} justifyContent={'end'} sx={{ paddingLeft: 'unset !important' }}>
          <button
            type="button"
            onClick={() => removeWorking(index)}
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
              padding: 0,
              font: 'inherit',
              cursor: 'pointer',
              outline: 'inherit',
            }}
          >
            <Iconify icon="mdi-close-circle-outline" className={classes.icon} />
          </button>
        </Grid>
      )}
    </Grid>
  </>
);

WorkingHours.propTypes = {
  index: PropTypes.number,
  startTime: PropTypes.array,
  setStartTime: PropTypes.func,
  endTime: PropTypes.array,
  setEndTime: PropTypes.func,
  removeWorking: PropTypes.func,
  classes: PropTypes.object,
  state: PropTypes.bool,
  setState: PropTypes.func,
  help: PropTypes.string,
};

export default WorkingHours;
