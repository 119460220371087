import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, List, ListItemButton, ListItemText, Switch } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import useNumber from 'hooks/useNumber';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { TELECOM, NUMBER_STATUS } from 'constants';
import Iconify from 'components/iconify/Iconify';
import ManageModal from '../../ManageModal';
import EditNumber from '../../EditNumber';
import ConfirmDelete from '../../ConfirmDelete';

export const formatDate = (date) => moment(date).format('MM/DD/YYY, HH:mm');

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '7.5px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

const upperOptions = [
  {
    name: 'edit',
    title: 'updateNumber',
    id: 1,
  },
];

const lowerOptions = [
  {
    name: 'delete',
    title: 'deleteNumber',
    id: 2,
  },
];

const NumberRow = ({
  no,
  id,
  number,
  telecom,
  active,
  status_vina,
  status_viettel,
  status_mobi,
  note,
  handleSetToast,
  toggleActive,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [selectedOption, setSelectedOption] = useState();
  const [openAction, setOpenAction] = useState(false);
  const { destroy, update } = useNumber();

  const handleClose = () => {
    setSelectedOption();
  };

  const handleDeleteNumber = async () => {
    try {
      handleClose();
      await destroy(id);
      handleSetToast({ open: true, type: 'success', message: t('notify:deletedSuccess') });
    } catch (e) {
      handleSetToast({ open: true, type: 'error', message: e.mesage });
    }
  };

  const handleUpdateNumber = async (data) => {
    try {
      handleClose();
      await update(id, data);
      handleSetToast({ open: true, type: 'success', message: t('notify:updateSuccess') });
    } catch (e) {
      handleSetToast({ open: true, type: 'error', message: e.mesage });
    }
  };

  const renderModal = useCallback(
    (option) => {
      switch (option) {
        case 'edit': {
          return <EditNumber data={{ number, telecom, note }} onClose={handleClose} onSubmit={handleUpdateNumber} />;
        }
        case 'delete': {
          return <ConfirmDelete onClose={handleClose} onSubmit={handleDeleteNumber} />;
        }
        default:
          return null;
      }
    },
    [number, telecom, note]
  );

  const modalTitle = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return t(`table:${upperOptions.find((el) => el.id === selectedOption).title}`);
    }
    return t(`table:${lowerOptions.find((el) => el.id === selectedOption).title}`);
  }, [selectedOption]);

  const modalComponent = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return renderModal(upperOptions.find((el) => el.id === selectedOption).name);
    }

    return renderModal(lowerOptions.find((el) => el.id === selectedOption).name);
  }, [renderModal, selectedOption]);

  const modalWidth = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return upperOptions.find((el) => el.id === selectedOption).width;
    }
    return lowerOptions.find((el) => el.id === selectedOption).width;
  }, [selectedOption]);

  const modalHeight = useMemo(() => {
    if (!selectedOption) return null;
    if (upperOptions.find((el) => el.id === selectedOption)) {
      return upperOptions.find((el) => el.id === selectedOption).height;
    }
    return lowerOptions.find((el) => el.id === selectedOption).height;
  }, [selectedOption]);

  return (
    <>
      <Grid container direction={'row'} alignContent={'center'} style={{ borderBottom: '#e3e5e6 1px solid' }}>
        <Grid item className={classes.row} xs={1}>
          <div className={classes.content}>{no}</div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>{number}</div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>
            {telecom === TELECOM.all && '-'}
            {telecom === TELECOM.viettel && 'Viettel'}
            {telecom === TELECOM.mobifone && 'Mobifone'}
            {telecom === TELECOM.vinaphone && 'Vinaphone'}
          </div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>
            <div
              style={{
                backgroundColor:
                  status_viettel === NUMBER_STATUS.STATUS_BLOCKED
                    ? '#FF4842'
                    : status_viettel === NUMBER_STATUS.STATUS_VALID
                    ? '#54D62C'
                    : '#919EAB',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                marginRight: '5px',
              }}
            />
          </div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>
            <div
              style={{
                backgroundColor:
                  status_vina === NUMBER_STATUS.STATUS_BLOCKED
                    ? '#FF4842'
                    : status_vina === NUMBER_STATUS.STATUS_VALID
                    ? '#54D62C'
                    : '#919EAB',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                marginRight: '5px',
              }}
            />
          </div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>
            <div
              style={{
                backgroundColor:
                  status_mobi === NUMBER_STATUS.STATUS_BLOCKED
                    ? '#FF4842'
                    : status_mobi === NUMBER_STATUS.STATUS_VALID
                    ? '#54D62C'
                    : '#919EAB',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                marginRight: '5px',
              }}
            />
          </div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content} style={{whiteSpaceCollapse: 'break-spaces'}}>{note}</div>
        </Grid>
        <Grid item className={classes.row}>
          <div className={classes.content}>
            <Switch checked={active} onChange={toggleActive} />
          </div>
        </Grid>
        <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
          <Button className={classes.button} onClick={() => setOpenAction((prev) => !prev)}>
            <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />

            {openAction ? (
              <ClickAwayListener onClickAway={() => setOpenAction((prev) => !prev)}>
                <List className={classes.listAction}>
                  {upperOptions.map((el) => (
                    <ListItemButton
                      key={el.name}
                      className={classes.listActionItem}
                      onClick={() => setSelectedOption(el.id)}
                    >
                      <ListItemText>{t(`table:${el.name}`)}</ListItemText>
                    </ListItemButton>
                  ))}
                  <Divider />
                  {lowerOptions.map((el) => (
                    <ListItemButton
                      key={el.name}
                      className={classes.listActionItem}
                      onClick={() => setSelectedOption(el.id)}
                    >
                      <ListItemText>{t(`table:${el.name}`)}</ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </ClickAwayListener>
            ) : null}
          </Button>
        </Grid>
      </Grid>

      {!!selectedOption && (
        <ManageModal
          title={modalTitle}
          openModal
          handleCloseModal={handleClose}
          width={modalWidth}
          maxHeight={modalHeight}
        >
          {modalComponent}
        </ManageModal>
      )}
    </>
  );
};

NumberRow.propTypes = {
  no: PropTypes.number,
  id: PropTypes.string,
  number: PropTypes.string,
  telecom: PropTypes.object,
  active: PropTypes.string,
  status_vina: PropTypes.string,
  status_viettel: PropTypes.string,
  status_mobi: PropTypes.string,
  note: PropTypes.string,
  handleSetToast: PropTypes.func,
  toggleActive: PropTypes.func,
};

export default NumberRow;
