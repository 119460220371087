import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Button, TextField, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';

import useScenario from 'hooks/useScenario';
import { DIALOG_STATUS } from '../../../constants/dialog';

export default function UserModal({ dialogStatus, values, openModal, handleCloseModal, handleSetToast }) {
  const { t } = useTranslation('common');
  const { create, update, destroy } = useScenario();
  const schema = yup
    .object({
      name: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: '' },
    values,
    resolver: yupResolver(schema),
  });

  const generateSuccessToast = (message) => {
    handleSetToast({ open: true, type: 'success', message });
  };

  const onSubmit = (data) => {
    if (dialogStatus === DIALOG_STATUS.CREATE) {
      create(data);
      generateSuccessToast(t('notify:addSuccess'));
    } else if (dialogStatus === DIALOG_STATUS.UPDATE) {
      update(data.id, data);
      generateSuccessToast(t('notify:updateSuccess'));
    } else {
      destroy(data.id);
      generateSuccessToast(t('notify:deletedSuccess'));
    }
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
    reset({ name: '' });
  };

  useEffect(() => {
    console.log('values', values);
  }, []);
  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth="true" maxWidth="xs">
      <DialogTitle>
        {dialogStatus === DIALOG_STATUS.CREATE && t('table:createNumberGroup')}
        {dialogStatus === DIALOG_STATUS.UPDATE && t('table:updateNumberGroup')}
        {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteNumberGroup')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors?.name?.message}
                margin="dense"
                label={t('table:numberGroupName')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>{t('table:cancel')}</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {t('table:submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleSetToast: PropTypes.func,
};
