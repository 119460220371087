import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { copyToClipboard } from 'utils/copyToClipboard';

const Copy = ({ data, onClose, handleSetToast }) => {
  const { t } = useTranslation('common');

  const schema = yup.object().shape({
    name: yup.string().required(t('error:roleNameRequired')),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleCopy = ({ name }) => {
    copyToClipboard(name);
    handleSetToast({ open: true, type: 'success', message: t('notify:copySuccess') });
    onClose();
  };

  return (
    <div>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(handleCopy)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors?.name?.message}
                margin="dense"
                label={t('table:newName')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleClose} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(handleCopy)}
          sx={{
            color: '#fff',
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {t('table:copy')}
        </Button>
      </DialogActions>
    </div>
  );
};

Copy.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  handleSetToast: PropTypes.func,
};

export default Copy;
