const stepForm = [
  {
    title: 'defineCallStrategy',
    step: 1,
  },
  {
    title: 'callplan',
    step: 2,
  },
  {
    title: 'schedule',
    step: 3,
  },
  {
    title: 'loadingContactsDetails',
    step: 4,
  },
  {
    title: 'Map',
    step: 5,
  },
  {
    title: 'summary',
    step: 6,
  },
];

export default stepForm;
