import SearchBar from 'components/Search';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import LinkTab from 'components/LinkTab';
import { useTranslation } from 'react-i18next';
// @mui
import { Box, Tab, Tabs, Typography } from '@mui/material';
// mock
import useScenario from 'hooks/useScenario';
import { DIALOG_STATUS, PAGINATION } from 'constants';
import SCENARIO_GROUP from '../../_mock/scenario';
import ScenarioList from './ScenarioList';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ScenarioPage() {
  const { t } = useTranslation('common');
  const { scenarios, total, get, create, update, destroy } = useScenario();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const filteredUsers = applySortFilter(SCENARIO_GROUP, getComparator(order, orderBy), filterName);
  const [loading, setLoading] = useState(false);
  const tabs = [
    {
      idx: 0,
      pathname: '/outbound/scenarios',
      label: t('table:scenarios'),
    },
    {
      idx: 1,
      pathname: '/outbound/campaigns',
      label: t('table:templates'),
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        await get({ limit: PAGINATION.LIMIT });
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const onSubmit = (data) => console.log(data);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = SCENARIO_GROUP.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCreateUser = () => {
    setDialogStatus(DIALOG_STATUS.CREATE);
    handleOpenModal();
  };

  const handleUpdateUser = () => {
    setDialogStatus(DIALOG_STATUS.UPDATE);
    handleOpenModal();
  };

  if (loading) return <>Loading...</>;

  return (
    <>
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
            aria-label="primary tabs example"
            TabIndicatorProps={{ style: { height: '3px' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.pathname}
                component={Typography}
                label={tab.label}
                {...a11yProps(tab.idx)}
                sx={{ fontSize: 16 }}
              />
            ))}
          </Tabs>
        </Box>

        {/* Render panel to show component */}
        <TabPanel value={value} index={0}>
          <ScenarioList
            scenarios={scenarios}
            get={get}
            create={create}
            update={update}
            destroy={destroy}
            total={total}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <></>
        </TabPanel>
      </>
    </>
  );
}
