import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { hasPermission } from 'utils/auth';
// @mui
import { Box, List, ListItemText, Divider } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { t } = useTranslation('common');
  const { permissions } = useAuth();

  return (
    <Box {...other}>
      <Divider />
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          if (hasPermission(item?.permission, permissions)) {
            return <NavItem key={item.title} title={t(item.title)} item={item} lastOfSection={item?.lastOfSection} />;
          }
          return null;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  lastOfSection: PropTypes.bool,
};

function NavItem({ item, title, lastOfSection }) {
  const location = useLocation();
  const { path, icon, info } = item;

  const active = location.pathname.split('/')[1].toLowerCase() === item.title.toLowerCase();

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.main',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
        className={`${active ? 'active' : ''}`}
      >
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}

        <ListItemText disableTypography primary={title} />

        {info && info}
      </StyledNavItem>
      {lastOfSection && <Divider sx={{ margin: '10px 0' }} />}
    </>
  );
}
