export const TELECOM = {
  all: '',
  viettel: 1,
  mobifone: 2,
  vinaphone: 3,
};

export const NUMBER_STATUS = {
  STATUS_INVALID: 0,
  STATUS_VALID: 1,
  STATUS_BLOCKED: 2,
};
