export const allAttempList = [
  {
    label: 'Init',
    value: 'init',
  },
  {
    label: 'Preparing',
    value: 'preparing',
  },
  {
    label: 'Calling',
    value: 'calling',
  },
  {
    label: 'Timeout',
    value: 'timeout',
  },
  {
    label: 'End Call',
    value: 'end_call',
  },
  {
    label: 'Hangup Call',
    value: 'hangup_call',
  },
  {
    label: 'Forward Call',
    value: 'forward_call',
  },
  {
    label: 'Missed Call',
    value: 'missed_call',
  },
  {
    label: 'Cannot Call',
    value: 'cannot_call',
  },
  {
    label: 'System Error',
    value: 'system_error',
  },
  {
    label: 'blacklisted_phone_numbers',
    value: 'init',
  },
  {
    label: 'Duplicate Phone Numbers',
    value: 'duplicate_phoneNumbers',
  },
  {
    label: 'Out of Range Waiting Time',
    value: 'out_of_range_waiting_time',
  },
  {
    label: 'Out of limits',
    value: 'out_of_limits',
  },
];

export const allVersionList = [
  {
    label: 'v2',
    value: 'v2',
  },
  {
    label: 'v3',
    value: 'v3',
  },
  {
    label: 'v4',
    value: 'v4',
  },
  {
    label: 'v5.5',
    value: 'v5.5',
  },
];

export const allStatusList = [
  {
    label: 'Answered',
    value: 'ANSWERED',
  },
  {
    label: 'No Answer',
    value: 'NO ANSWER',
  },
  {
    label: 'Busy',
    value: 'BUSY',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Congestion',
    value: 'CONGESTION',
  },
];
