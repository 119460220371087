import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Typography,
  DialogActions,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useCampaign from 'hooks/useCampaign';
import { CAMPAIGN_STATUS } from 'constants';

import Iconify from 'components/iconify/Iconify';
import moment from 'moment';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CampaignTooltip from './Tooltip';
import CampaignModal from '../CampaignPage/CampaignModal';

const theme = createTheme({
  palette: {
    createColor: {
      main: '#059669',
      dark: '#047857',
    },
    endColor: {
      main: '#ef4444',
      dark: '#dc2626',
    }
  }
});

const CampaignItem = ({
  index,
  openTooltip,
  handleTooltipClose,
  styles,
  handleTooltipOpen,
  setLoading,
  data,
  handleSetToast,
}) => {
  const {
    id,
    name,
    scenario: { name: scenario_name },
    start_time,
    end_time,
    status,
    total_contact,
    contact_called,
    call_success,
    duration,
    contact_left,
    call_failed,
    call_progress,
    cost,
  } = data;

  const statusState = [
    { state: 'processing', color: '#e5e7eb' },
    { state: 'ready', color: '#fecaca' },
    { state: 'scheduled', color: '#fed7aa' },
    { state: 'ongoing', color: '#bbf7d0' },
    { state: 'paused', color: '#e9d5ff' },
    { state: 'completed', color: '#bae6fd' },
  ];
  const { t } = useTranslation('common');
  const { update, destroy, start, end, uploadReport, reset } = useCampaign();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isShowStartBtn, setIsShowStartBtn] = useState(
    status === CAMPAIGN_STATUS.ready || status === CAMPAIGN_STATUS.scheduled
  );
  const [isShowEndBtn, setIsShowEndBtn] = useState(
    status === CAMPAIGN_STATUS.ongoing || status === CAMPAIGN_STATUS.paused
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isEnableEditOption =
    status === CAMPAIGN_STATUS.processing || status === CAMPAIGN_STATUS.ready ||
    status === CAMPAIGN_STATUS.scheduled || status === CAMPAIGN_STATUS.completed;

  const [isShowResetBtn, setIsShowResetBtn] = useState(
    status === CAMPAIGN_STATUS.completed
  );

  const onCloseDeleteModal = () => setOpenDeleteModal(false);
  const onOpenDeleteModal = () => setOpenDeleteModal(true);

  // XXX: Experiment (delete later)
  const handleStartCampaign = async () => {
    try {
      await start(data.id);
      handleSetToast({ open: true, type: 'success', message: 'Start successful!' });
      setIsShowStartBtn(false);
      setIsShowEndBtn(true);
    } catch (error) {
      handleSetToast({ open: true, type: 'error', message: error.message });
    }
  };

  const handleEndCampaign = async () => {
    try {
      await end(data.id);
      handleSetToast({ open: true, type: 'success', message: 'End successful!' });
      setIsShowEndBtn(false);
    } catch (error) {
      handleSetToast({ open: true, type: 'error', message: error.message });
    }
  };

  const handleResetCampaign = async () => {
    try {
      await reset(data.id);
      handleSetToast({ open: true, type: 'success', message: 'Reset successful!' });
      setIsShowStartBtn(true);
      setIsShowResetBtn(false);
    } catch (error) {
      handleSetToast({ open: true, type: 'error', message: error.message });
    }
  };

  const handleUploadReportToGoogleSheet = async () => {
    try {
      await uploadReport(data.id);
      handleSetToast({ open: true, type: 'success', message: 'Uploading report to google sheet!' });
    } catch (error) {
      handleSetToast({ open: true, type: 'error', message: error.message });
    }
  };

  const formatUnixTime = (timpestamp) => {
    if (!timpestamp) return '—';
    return moment(timpestamp).format('DD/MM/YYYY, HH:mm');
  };

  const handleCloseMenu = () => setAnchorEl(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      destroy(id).then(() => {
        handleSetToast({ open: true, type: 'success', message: 'Delete successful!' });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Grid item>
        <Paper variant="outlined" sx={{ p: 1.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Stack direction="row">
                <Box sx={{ position: 'relative' }}>
                  <Iconify
                    icon="material-symbols:check-circle-outline-rounded"
                    sx={{ color: 'text.disabled', width: 20, height: 20, m: 1 }}
                  />
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                          px: 1,
                          typography: 'body2',
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleOpenModal} disabled={!isEnableEditOption}>
                      {t('table:update')}
                    </MenuItem>

                    {isShowResetBtn ? (
                      <MenuItem
                        onClick={handleResetCampaign}
                      >
                        {t('table:reset')}
                      </MenuItem>
                    ) : (
                      <></>
                    )}

                    <MenuItem sx={{ color: 'error.main' }} onClick={onOpenDeleteModal}>
                      {t('table:delete')}
                    </MenuItem>
                  </Popover>
                </Box>
                <Box marginLeft={1.25}>
                  <Link to={`/campaigns/${id}/report`} className={styles.link} onClick={() => {}}>
                    {name}
                  </Link>
                  <Typography>
                    <span style={{ color: '#78909c' }}>{t('scenario')}:</span>
                    <span>{scenario_name}</span>
                  </Typography>
                  <Typography sx={{ fontSize: 12, letterSpacing: '0.4px', lineHeight: 2, fontWeight: 400, mt: 2 }}>
                    {' '}
                    <span style={{ color: '#78909c' }}>{t('from')}: </span>
                    {formatUnixTime(start_time)}
                    <span style={{ color: '#78909c' }}> {t('to')}:</span> {formatUnixTime(end_time)}{' '}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={2} justifyContent="end" alignItems="center">
                <Chip
                  size="small"
                  label={t(`${statusState[status]?.state || 'processing'}`)}
                  sx={{ backgroundColor: statusState[status]?.color || 'primary.lighter', color: '#52525b' }}
                />
                <Button
                  component={Link}
                  to={`/campaigns/${id}/report`}
                  variant="outlined"
                  color="error"
                  sx={{ height: 28, fontWeight: 400, fontSize: 12 }}
                >
                  {t('report')}
                </Button>
                <CloudUploadRoundedIcon
                  sx={{ color: '#0891b2', cursor: 'pointer' }}
                  onClick={handleUploadReportToGoogleSheet}
                />
                <CampaignTooltip
                  index={index}
                  openTooltip={openTooltip}
                  handleTooltipClose={handleTooltipClose}
                  styles={styles}
                  handleTooltipOpen={handleTooltipOpen}
                  data={data}
                  formatUnixTime={formatUnixTime}
                />
                <IconButton color="inherit" onClick={handleOpenMenu} sx={{ p: 0.5 }}>
                  <Iconify icon={'ic:round-more-horiz'} sx={{ color: 'text.disabled' }} />
                </IconButton>
              </Stack>
              {/* XXX: Experiment (delete later) */}
              <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" marginTop={2}>
              <ThemeProvider theme={theme}>
                {isShowStartBtn ? (
                  <Button
                    variant="contained"
                    color="createColor"
                    sx={{ textTransform: 'capitalize', color: '#fff', height: 28, fontSize: 12 }}
                    onClick={handleStartCampaign}
                  >
                    {t('table:start')}
                  </Button>
                ) : (
                  <></>
                )}
                {isShowEndBtn ? (
                  <Button
                    variant="contained"
                    color="endColor"
                    sx={{ textTransform: 'capitalize', color: '#fff', height: 28, fontSize: 12 }}
                    onClick={handleEndCampaign}
                  >
                    {t('table:end')}
                  </Button>
                ) : (
                  <></>
                )}
                {/* {isShowResetBtn ? (
                  <Button
                    variant="contained"
                    color="resetColor"
                    sx={{ textTransform: 'capitalize', color: '#fff', height: 28, fontSize: 12 }}
                    onClick={handleResetCampaign}
                  >
                    {t('table:reset')}
                  </Button>
                ) : (
                  <></>
                )} */}

              </ThemeProvider>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Box marginLeft={`calc(20px + 1.5rem)`}>
                <Stack
                  direction="row"
                  spacing={3}
                  marginBottom={2}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Stack direction="row" spacing={6}>
                    <div>
                      <b>{total_contact || 0}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#3b82f6',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('total')}</span>
                      </div>
                    </div>

                    <div>
                      <b>{contact_called || 0}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#facc15',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('called')}</span>
                      </div>
                    </div>

                    <div>
                      <b>{call_success || 0}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#22c55e',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('success')}</span>
                      </div>
                    </div>

                    <div>
                      <b>{call_failed || 0}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ef4444',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('failed')}</span>
                      </div>
                    </div>
                    <div>
                      <b>{contact_left || 0}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#a1a1aa',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('left')}</span>
                      </div>
                    </div>

                    <div>
                      <b>{call_progress}%</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#a855f7',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('progress')}</span>
                      </div>
                    </div>
                  </Stack>
                  <Stack direction="row" spacing={6}>
                    <div>
                      <b>{cost} ₫</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <span>{t('table:cost')}</span>
                      </div>
                    </div>
                    <div>
                      <b>{duration}</b>
                      <div
                        style={{
                          color: '#78909c',
                          display: 'flex',
                          fontSize: '12px',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <span>{t('average')}</span>
                      </div>
                    </div>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CampaignModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        steps={['Define call strategy', 'Call plan', 'Schedule', 'Summary']}
        setLoading={setLoading}
        initialModalData={data}
        onSubmit={update}
      />

      <Dialog open={openDeleteModal} onClose={onCloseDeleteModal} maxWidth="md">
        <DialogTitle>Are you sure to delete this Campaign?</DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={onCloseDeleteModal}>
            {t('table:cancel')}
          </Button>
          <Button type="submit" variant="contained" color="error" onClick={() => handleDelete(id)}>
            {t('table:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CampaignItem.propTypes = {
  index: PropTypes.number,
  openTooltip: PropTypes.bool,
  handleTooltipClose: PropTypes.func,
  styles: PropTypes.object,
  handleTooltipOpen: PropTypes.func,
  setLoading: PropTypes.func,
  handleSetToast: PropTypes.func,
  data: PropTypes.object,
};

export default CampaignItem;
