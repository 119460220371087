import { createContext, useReducer } from 'react';
import userService from 'services/user.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  users: [],
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USERS': {
      const { users, total } = action.payload;
      return { ...state, users, total };
    }
    case 'CREATE_USER': {
      const { user } = action.payload;
      return { ...state, users: state.users.concat([user]), total: state.total + 1 };
    }
    case 'UPDATE_USER': {
      const { user } = action.payload;
      const updatedUsers = replaceItemInArrayByKey(state.users, 'id', user);
      return { ...state, users: updatedUsers };
    }
    case 'DELETE_USER': {
      const { id } = action.payload;
      return { ...state, users: state.users.filter((user) => user.id !== id), total: state.total - 1 };
    }

    default:
      return { ...state };
  }
};

const UserContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const { data, meta } = await userService.get(params);

      dispatch({
        type: 'SET_USERS',
        payload: {
          users: data,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: user } = await userService.create(data);
      dispatch({
        type: 'CREATE_USER',
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: user } = await userService.update(id, data);
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await userService.delete(id);
      dispatch({
        type: 'DELETE_USER',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <UserContext.Provider value={{ ...state, method: 'User', get, create, update, destroy }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContext;
