/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
// @mui
import { Button, Box, Typography, Checkbox, Tabs, Tab, Autocomplete, TextField } from '@mui/material';

function UploadContact({
  isConfirmPolicy,
  setIsConfirmPolicy,
  data,
  setData,
  handleNext,
  handleBack,
  isAvailableContacts = false,
  availableContacts = [],
}) {
  const { t } = useTranslation('common');
  const { contact_detail_file: file } = data;
  const [selectedFile, setSelectedFile] = useState(null);
  const [contactGroups, setContactGroups] = useState(data?.contact_group_ids || []);
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });
  const [uploadState, setUploadState] = useState(0);
  const isUploadContactsState = useMemo(() => uploadState === 0, [uploadState]);

  useEffect(() => {
    if (file) {
      setSelectedFile(file);
    }
  }, [file]);

  useEffect(() => {
    setData(
      'contact_group_ids',
      contactGroups.map((contact) => contact.id)
    );
  }, [contactGroups]);

  useEffect(() => {
    setSelectedFile(null);
    setContactGroups([]);
  }, [uploadState]);

  const uploadSchema = yup.object({}).required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(uploadSchema),
  });

  const handleClose = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = `template.xlsx`;
    link.href = '/assets/path/template.xlsx';
    link.click();
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;

    const fileExtension = file?.name.split('.').pop().toLowerCase();
    if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
      setToast({ open: true, type: 'error', message: 'Unsupported file extension. Please check again.' });
      event.target.value = '';
    } else if (file.size > maxFileSize) {
      setToast({ open: true, type: 'error', message: 'The file size is too large!' });
      event.target.value = '';
    } else {
      setData('contact_detail_file', file);
      setSelectedFile(file);
    }
  };

  const handleChangeTabs = (_, newValue) => {
    setUploadState(newValue);
  };

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', width: '800px' }}>
        {isAvailableContacts && (
          <Box sx={{ width: '100%' }}>
            <Tabs value={uploadState} onChange={handleChangeTabs} centered>
              <Tab label="Upload Contact Database" />
              <Tab label="Choose Available Contact Group" />
            </Tabs>
          </Box>
        )}

        <Typography variant="h6" color={'#3e4345'} align="center" marginY={2}>
          {isUploadContactsState ? 'Upload Contact Database' : 'Choose Available Contact Group'}
        </Typography>

        {isUploadContactsState ? (
          <>
            <div style={{ padding: '10px 200px' }}>
              <label htmlFor="upload-file-input" style={{ cursor: 'pointer' }}>
                <div
                  style={{
                    border: '2px dashed rgb(120, 144, 156)',
                    borderRadius: '16px',
                    textAlign: 'center',
                    padding: '16px',
                  }}
                >
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
                    id="upload-file-input"
                    type="file"
                    autoComplete="off"
                    tabIndex="-1"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />

                  {selectedFile ? (
                    <>
                      <img
                        src="/assets/icons/xlsIconColor.svg"
                        alt="upload logo"
                        width={66}
                        height={66}
                        style={{ margin: 'auto' }}
                      />
                      <div style={{ fontWeight: 600, marginBlock: '20px' }}>{selectedFile.name}</div>
                      <div style={{ color: '#78909c', fontSize: '14px' }}>
                        {(selectedFile.size / 1024).toFixed(2)} KB
                      </div>
                      <Button variant="contained" type="submit" sx={{ margin: '10px' }}>
                        <label htmlFor="upload-file-input">Upload another</label>
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        src="/assets/icons/uploadCloud.svg"
                        alt="upload logo"
                        width={60}
                        height={60}
                        style={{ margin: 'auto' }}
                      />
                      <div style={{ fontWeight: 600, marginBlock: '20px' }}>Upload or drop file</div>
                      <div style={{ color: '#78909c', fontSize: '14px' }}>The file type must be XLS or XLSX.</div>
                      <div style={{ color: '#78909c', fontSize: '14px' }}>The maximum size is 20 MB.</div>
                      <Button variant="contained" type="submit" sx={{ margin: '10px' }}>
                        <label htmlFor="upload-file-input">Upload</label>
                      </Button>
                    </>
                  )}
                </div>
              </label>
            </div>

            <div>
              <div style={{ margin: '20px auto', textAlign: 'center' }}>
                <div style={{ color: '#78909c', fontSize: '14px' }}>
                  If necessary, upload a template that matches your scenario.
                </div>
                <div style={{ color: '#78909c', fontSize: '14px' }}>It already contains the necessary columns.</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/assets/icons/xlsIcon.svg" alt="xls icon" width={28} height={28} />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span
                  style={{
                    color: '#da545d',
                    fontWeight: 600,
                    '&:hover': {
                      color: '#c54049',
                    },
                  }}
                  onClick={onDownload}
                >
                  template.xlsx
                </span>
              </div>
            </div>
          </>
        ) : (
          <Autocomplete
            value={contactGroups}
            multiple
            id="contact-group-autocomplete"
            options={availableContacts}
            getOptionLabel={(option) => option.name}
            fullWidth
            onChange={(_, newValue) => setContactGroups(newValue)}
            renderInput={(params) => <TextField {...params} label="Select Contact Group" />}
          />
        )}
      </Box>

      <Box
        sx={{
          padding: '10px',
          background: '#f6f9fa',
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* {isUploadContactsState && (
          <div style={{ display: 'flex' }}>
            <Checkbox
              color="default"
              id="confirm-policy"
              checked={isConfirmPolicy}
              onChange={(event) => setIsConfirmPolicy(event.target.checked)}
            />
            <label
              htmlFor="confirm-policy"
              style={{ color: '#78909c', width: '580px', display: 'flex', fontSize: '13px' }}
            >
              I confirm the legality of processing the uploaded data (including the required consents) and guarantee
              compliance with the requirements of the advertising legislation
            </label>
          </div>
        )} */}
        {handleBack && (
          <Button
            sx={{
              ':hover': {
                boxShadow:
                  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#d5d5d5',
              },
              backgroundColor: '#e0e0e0',
              marginRight: '10px',
              color: 'rgba(0, 0, 0, 0.87);',
            }}
            variant="contained"
            onClick={handleBack}
          >
            {t('back')}
          </Button>
        )}
        <Button
          disabled={!selectedFile && !contactGroups.length}
          variant="contained"
          type="submit"
          onClick={handleSubmit(handleNext)}
        >
          {t('next')}
        </Button>
      </Box>

      <Toast openToast={toast.open} handleClose={handleClose} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}

export default UploadContact;

UploadContact.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  isConfirmPolicy: PropTypes.bool,
  setIsConfirmPolicy: PropTypes.func,
  isAvailableContacts: PropTypes.bool,
  availableContacts: PropTypes.array,
};
