import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/common.json';
import translationVI from './locales/vi/common.json';
import tableEN from './locales/en/table.json';
import tableVI from './locales/vi/table.json';
import errorEN from './locales/en/error.json';
import errorVI from './locales/vi/error.json';
import notificationEN from './locales/en/notification.json';
import notificationVI from './locales/vi/notification.json';

const resources = {
  en: {
    common: translationEN,
    table: tableEN,
    error: errorEN,
    notify: notificationEN,
  },
  vi: {
    common: translationVI,
    table: tableVI,
    error: errorVI,
    notify: notificationVI,
  },
};

const defaultLanguage = localStorage.getItem('i18nDefaultLanguage') || 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nDefaultLanguage', lng);
});

export default i18n;
