import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import StyledLink from 'components/Link';

export const formatDate = (date) => moment(date).format('MM/DD/YYY, HH:mm');

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '12px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

const Row = ({ id, name, scenario_publication, page }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction={'row'}>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }} xs={1}>
          <div
            className={classes.content}
            style={{ justifyContent: 'flex-start', paddingTop: '16px', paddingLeft: '15px', paddingBottom: '16px' }}
          >
            {id}
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>
            <StyledLink to={`/${page}/scenarios/${id}/history`} label={name} />
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>{scenario_publication?.scenario_publication_name || '-'}</div>
        </Grid>
      </Grid>
    </>
  );
};

Row.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  page: PropTypes.string,
  scenario_publication: PropTypes.string,
};

export default Row;
