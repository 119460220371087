import { createContext, useReducer, useEffect } from 'react';
import campaignService from 'services/campaign.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';
import { CAMPAIGNS } from 'constants/dialog';

const initialCampaignState = {
  campaigns: [],
  currentScenario: null,
  total: 0,
  reports: [],
  totalReports: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CAMPAIGNS.SET_CAMPAIGN: {
      const { campaigns, total } = action.payload;
      return { ...state, campaigns, total };
    }
    case CAMPAIGNS.CREATE_CAMPAIGN: {
      const { campaign } = action.payload;
      return { ...state, campaigns: [campaign].concat(state.campaigns), total: state.total + 1 };
    }
    case CAMPAIGNS.UPDATE_CAMPAIGN: {
      const { campaign } = action.payload;
      const updatedCampaigns = replaceItemInArrayByKey(state.campaigns, 'id', campaign);
      return { ...state, campaigns: updatedCampaigns };
    }
    case CAMPAIGNS.DELETE_CAMPAIGN: {
      const { id } = action.payload;
      return { ...state, campaigns: state.campaigns.filter((campaign) => campaign.id !== id), total: state.total - 1 };
    }

    case CAMPAIGNS.SET_REPORT: {
      const { reports, totalReports } = action.payload;
      return { ...state, reports, totalReports };
    }

    default:
      return { ...state };
  }
};

const CampaignContext = createContext({
  ...initialCampaignState,
});

export const CampaignProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCampaignState);

  const get = async (params) => {
    try {
      const { data: campaigns, meta } = await campaignService.get(params);
      dispatch({
        type: CAMPAIGNS.SET_CAMPAIGN,
        payload: {
          campaigns,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: campaign } = await campaignService.createCampaign(data);
      dispatch({
        type: CAMPAIGNS.CREATE_CAMPAIGN,
        payload: {
          campaign,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: campaign } = await campaignService.updateCampaign(id, data);
      dispatch({
        type: CAMPAIGNS.UPDATE_CAMPAIGN,
        payload: {
          campaign,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await campaignService.delete(id);
      dispatch({
        type: CAMPAIGNS.DELETE_CAMPAIGN,
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const getReports = async (id, params) => {
    try {
      const { data: reports, meta } = await campaignService.getReports(id, params);
      dispatch({
        type: CAMPAIGNS.SET_REPORT,
        payload: {
          reports,
          totalReports: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // XXX: Experiment (delete later)
  const start = async (id) => {
    await campaignService.startCampaign(id);
  };

  const end = async (id) => {
    await campaignService.endCampaign(id);
  };

  const reset = async (id) => {
    await campaignService.resetCampaign(id);
  };

  const uploadReport = async (id) => {
    await campaignService.uploadReport(id);
  };

  return (
    <CampaignContext.Provider
      value={{ ...state, method: 'Campaign', get, create, update, destroy, start, end, reset, uploadReport, getReports }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

CampaignProvider.propTypes = {
  children: PropTypes.node,
};

export default CampaignContext;
