/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import Iconify from 'components/iconify';
import PropTypes from 'prop-types';
import HelpText from '../../../HelpText';

const Check = ({ name, title, enable, setEnable, classes, help }) => (
    <>
      <Grid item xs={5.5}>
        <Typography className={classes.heading}>{title}</Typography>
      </Grid>
      <Grid item xs={5.5}>
        <Switch checked={enable} onChange={(e) => setEnable(e.target.checked, name)} />
      </Grid>
      <Grid container direction={'row'} item xs={1} justifyContent={'end'} sx={{ paddingLeft: 'unset !important' }}>
        <HelpText classes={classes} text={help}/>
      </Grid>
    </>
  );

Check.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object,
  enable: PropTypes.bool,
  setEnable: PropTypes.func,
  help: PropTypes.string
};

export default Check;
