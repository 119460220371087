import APIService from './api.service';

const baseURL = '/users';

class UserService extends APIService {
  async changePassword(data) {
    try {
      const response = await this.axios.post(`${this.baseURL}/change-password`, data);
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Change password failed');
    }
  }
}

export default new UserService(baseURL);
