import { useState, useEffect, useMemo } from 'react';
import { Grid, Button, Box, CircularProgress } from '@mui/material';
import moment from 'moment';

import useCampaign from 'hooks/useCampaign';
import LoadingScreen from 'components/LoadingScreen';
import { makeStyles } from '@mui/styles';
import DateTimeRange from 'components/DateTimeRange';
import { useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import useCommon from 'hooks/useCommon';
import campaignService from 'services/campaign.service';
import ConversationNotfound from '../../../../assests/images/conversation_notfound.svg';
import FilterSection from './FilterSection';
import Statistics from './Statistics';
import { allStatusList } from './FilterSection/data';
import ConversationTable from '../ConversationTable';

const filterList = [
  {
    icon: 'material-symbols:call-outline-sharp',
    label: 'Status',
    filterLabel: 'All Status',
    value: 'status',
    menuList: allStatusList,
  },
  // {
  //   icon: 'material-symbols:check-circle-outline-rounded',
  //   label: 'Attempt result',
  //   filterLabel: 'All Attempt results',
  //   value: 'attempResult',
  //   menuList: allAttempList,
  // },
  // {
  //   icon: 'mingcute:version-fill',
  //   label: 'Version',
  //   filterLabel: 'All Versions',
  //   value: 'version',
  //   menuList: allVersionList,
  // },
  // {
  //   icon: 'streamline:interface-login-dial-pad-1-dial-pad-dot',
  //   label: 'Number',
  //   filterLabel: 'All Numbers',
  //   value: 'number',
  // },
  // {
  //   icon: 'bxs:conversation',
  //   label: 'Conversation',
  //   filterLabel: 'All Conversations',
  //   value: 'conversation',
  // },
];

const useStyles = makeStyles((theme) => ({
  timeRange: {
    border: '1px solid #c6cacc',
    borderRadius: '30px',
    padding: '6px 10px',
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#78909c',
    },
  },
  pr10: {
    paddingRight: '10px',
  },
  px10: {
    padding: '0 10px',
  },
  datePicker: {
    position: 'absolute',
    top: '40px',
    background: '#fff',
    boxShadow: '2px 5px 6px 3px #bdbdbd78',
    borderRadius: '8px',
  },
  buttonGroup: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #d8d8d8',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '& .MuiChip-avatar': {
      width: '20px',
      height: '20px',
      color: '#78909c',
    },

    '& .MuiSvgIcon-root': {
      display: 'none',
      color: '#78909c',
      position: 'absolute',
      right: '0',
      zIndex: '10',
      width: '15px',
      height: '15px',
      background: 'linear-gradient(270deg, #f1f1f1 71.88%, rgba(247, 249, 250, 0))',
    },
  },
  filterItem: {
    fontSize: '14px',
    color: '#78909c',
    cursor: 'pointer',
    position: 'relative',
    marginRight: '16px',
    '&:hover': {
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
    '& .MuiChip-label': {
      paddingRight: '24px',
    },
  },
  itemWrapper: {
    position: 'relative',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  exportButton: {
    color: '#fff',
    background: theme.palette.primary.main,
    height: '32px',
    '&.MuiButtonBase-root.MuiButton-root:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

const CampaignReport = () => {
  const { setParentPage, setChildName } = useCommon();
  const { campaigns, reports, getReports, totalReports } = useCampaign();
  const classes = useStyles();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [openSelectFilter, setOpenSelectFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterList);
  const [selectedFilterValue, setSelectedFilterValue] = useState([{ type: 'status', value: 'ANSWERED' }]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [triggerFilterByDate, setTriggerFilterByDate] = useState(0);

  // eslint-disable-next-line consistent-return
  const getCampaignByID = async (id) => {
    try {
      const { data } = await campaignService.getByID(id);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const filterParams = useMemo(() => {
    const params = {};
    selectedFilterValue.forEach((el) => {
      if (el.value) {
        params[el.type] = el.value;
      }
    });
    return params;
  }, [selectedFilterValue]);

  const filterByDateRange = useMemo(() => {
    const start_time = moment
      .utc(`${moment(selectedDateRange.startDate).format('L')} ${moment(selectedTimeRange.startTime).format('LT')}`)
      .format();
    const end_time = moment
      .utc(`${moment(selectedDateRange.endDate).format('L')} ${moment(selectedTimeRange.endTime).format('LT')}`)
      .format();
    return {
      start_time,
      end_time,
    };
  }, [selectedDateRange, selectedTimeRange]);

  useEffect(() => {
    const getCampaign = async () => {
      if (id) {
        const c = campaigns.length ? campaigns.find((el) => el.id.toString() === id) : await getCampaignByID(id);
        setCampaign(c);
      }
    };

    getCampaign();
  }, [campaigns, id]);

  useEffect(() => {
    setParentPage('outbound');
    if (campaign) {
      setChildName(campaign.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    const handleGetCampaignReports = async (id, filterParams) => {
      setIsLoading(true);
      if (triggerFilterByDate !== 0) {
        await getReports(id, { page: 1, limit: rowsPerPage, ...filterByDateRange, ...filterParams });
      } else {
        await getReports(id, { page: 1, limit: rowsPerPage, ...filterParams });
      }
      setIsLoading(false);
    };

    try {
      handleGetCampaignReports(id, filterParams);
    } catch (err) {
      console.log(err);
    }
  }, [id, selectedFilterValue, rowsPerPage, triggerFilterByDate]);

  const handleSelectFilter = (value) => {
    setSelectedFilter((prev) => [...prev, value]);
  };
  const handleDeleteFilter = (filterValue) => {
    setSelectedFilter((data) => data.filter((chip) => chip.value !== filterValue));
  };

  const handleClearFilter = () => {
    setSelectedFilter([]);
    setOpenSelectFilter(false);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setIsLoading(true);
    await getReports(id, { page: newPage + 1, limit: rowsPerPage, ...filterParams });
    setIsLoading(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleExportReports = async (format) => {
    const res = await campaignService.exportReports(id, { format, status: filterParams?.status });
    const fileURL = URL.createObjectURL(new Blob([res]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${campaign.name || 'data'}.${format}`);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  if (!campaign) return <LoadingScreen />;

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container direction="row" xs={10}>
          <DateTimeRange
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setTriggerFilterByDate={setTriggerFilterByDate}
          />

          <FilterSection
            classes={classes}
            filterList={filterList}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            handleDeleteFilter={handleDeleteFilter}
            openSelectFilter={openSelectFilter}
            setOpenSelectFilter={setOpenSelectFilter}
            handleSelectFilter={handleSelectFilter}
            handleClearFilter={handleClearFilter}
            selectedFilterValue={selectedFilterValue}
            setSelectedFilterValue={setSelectedFilterValue}
          />
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <Grid item xs={12}>
            <div className={classes.exportButtons}>
              <Button
                className={classes.exportButton}
                style={{
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                  marginRight: '1px',
                }}
                onClick={() => handleExportReports('xlsx')}
              >
                XLSX
              </Button>
              <Button
                className={classes.exportButton}
                style={{
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                }}
                onClick={() => handleExportReports('csv')}
              >
                CSV
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Statistics {...campaign} />
        </Grid>
        <Grid container item xs={12} sx={{ padding: '10px' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', height: '400px', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={55} />
              </Box>
            ) : reports.length === 0 ? (
              <NotFound title="You don't have any Conversation yet!" image={ConversationNotfound} />
            ) : (
              <ConversationTable
                conversations={reports}
                total={totalReports}
                campaign={campaign}
                page={page}
                handleChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CampaignReport;
