const getStepForm = (isCreate = true) =>
  isCreate
    ? [
        {
          title: 'loadingContactsDetails',
          step: 1,
        },
        {
          title: 'Map',
          step: 2,
        },
        {
          title: 'summary',
          step: 3,
        },
      ]
    : [
        {
          title: 'summary',
          step: 1,
        },
      ];

export default getStepForm;
