import { getToken } from 'utils/auth';
import APIService from './api.service';

const baseURL = '/campaigns';

class CampaignService extends APIService {
  async createCampaign(data) {
    try {
      const response = await this.axios.post(this.baseURL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to post data');
    }
  }

  async updateCampaign(id, data) {
    try {
      const response = await this.axios.post(`${this.baseURL}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to put data');
    }
  }

  // XXX: Experiment API (delete later)
  async startCampaign(id) {
    const response = await this.axios.post(`${this.baseURL}/${id}/start`);
    return response;
  }

  async endCampaign(id) {
    const response = await this.axios.post(`${this.baseURL}/${id}/end`);
    return response;
  }

  async resetCampaign(id) {
    const response = await this.axios.post(`${this.baseURL}/${id}/reset`);
    return response;
  }

  async uploadReport(id) {
    const response = await this.axios.post(`${this.baseURL}/${id}/upload-report`);
    return response;
  }

  async getReports(id, params = {}) {
    try {
      const response = await this.axios.get(`${this.baseURL}/${id}/get-reports`, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch data');
    }
  }

  async exportReports(id, params = {}) {
    try {
      const { format = 'xls', status = '' } = params;
      const token = getToken();
      const response = await this.axios.get(
        status
          ? `${this.baseURL}/${id}/export-reports?format=${format}&status=${status}&token=${token}`
          : `${this.baseURL}/${id}/export-reports?format=${format}&token=${token}`,
        {
          responseType: 'arraybuffer',
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch data');
    }
  }
}

export default new CampaignService(baseURL);
