import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
// hooks
import useToast from 'hooks/useToast';
import { PAGINATION, DIALOG_STATUS } from 'constants';
import useBlacklistContact from 'hooks/useBlacklistContact';
// @mui
import {
  Grid,
  Button,
  Stack,
  Popover,
  MenuItem,
} from '@mui/material';

// components
import SearchBar from 'components/Search';
import Toast from 'components/Toast';
import Table from 'components/Table';
import Iconify from 'components/iconify';
import { useParams } from 'react-router-dom';
import BlacklistDetailModal from './BlacklistDetailModal';
import UploadModal from './UploadModal';
// const

const useStyle = makeStyles((theme) => ({
  row: {
    flex: 1,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    padding: '7.5px',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '13px',
    top: '30px',
    width: '155px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
  box: {
    padding: '10px',
    width: '100%',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  exportButton: {
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    textTransform: 'unset',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
}));

export default function BlacklistDetailPage () {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const { blacklistContacts, total, get, create, update, destroy, upload } = useBlacklistContact();

  const classes = useStyle();
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const [keywordFilter, setKeywordFilter] = useState('');
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const [page, setPage] = useState(1);

  const headers = [
    {
      label: 'No',
      canSort: false,
      width: 1,
    },
    {
      label: t('table:name'),
      canSort: false,
    },
    {
      label: t('table:phoneNumber'),
      canSort: false,
    },
    {
      label: t('table:reason'),
      canSort: false,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const initialModalData = {
    blacklist_id: id,
    contact_detail_file: 0,
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) {
      setSelectedRow({
        'blacklist_id': id
      });
    }
    handleCloseMenu();
    setDialogStatus(status);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModalUpload = () => setOpenModalUpload(true);

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const handleOpenMenu = (event, row) => {
    setSelectedRow(row);
    setOpen(event.currentTarget);
  };

  const handleSubmitData = async (data) => {
    handleCloseModal();
    let message = t('notify:addSuccess');
    switch (dialogStatus) {
      case DIALOG_STATUS.CREATE:
        await create(data);
        break;
      case DIALOG_STATUS.UPDATE:
        message = t('notify:updateSuccess');
        await update(data.id, data);
        break;
      default:
        message = t('notify:deletedSuccess');
        await destroy(data.id);
        break;
    }
    handleSetToast({ open: true, type: 'success', message });
  }

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  }

  const handleUploadData = async (data) => {
    await upload(data);
    const message = t('notify:uploadSuccess');
    handleSetToast({ open: true, type: 'success', message });
    setTimeout(() => window.location.reload(), 2000);
  }

  useEffect(() => {
    const handleGetBlacklistContact = async () => {
      await get({ page: 0, limit: PAGINATION.LIMIT, keyword: keywordFilter, blacklist_id: id });
    };

    handleGetBlacklistContact();
  }, [keywordFilter, id]);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('blacklist')} </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <div
          style={{
            display: 'flex',
            gap: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '550px',
          }}
        >
          <SearchBar setFilter={setKeywordFilter} />
        </div>
        <div>
          <Button variant="contained"
            startIcon={<Iconify icon="material-symbols:drive-folder-upload-rounded" color="#fff" />}
            sx={{
              marginRight: '10px', background: '#059669',
              '&:hover': {
                background: '#077f5a'
              },
            }} onClick={handleOpenModalUpload}>
            {t('table:upload')}
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
            onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
          >
            {t('table:add')}
          </Button>
        </div>
      </Stack>

      <Table total={total} get={get} headers={headers} onChangePage={handleChangePage}>
        {blacklistContacts.map((blacklistContact, index) => (
          <Grid
            key={index}
            container
            direction={'row'}
            alignContent={'center'}
            style={{ borderBottom: '#e3e5e6 1px solid' }}
          >
            <Grid item className={classes.row} xs={1}>
              <div className={classes.content}>{(page - 1) * 10 + index + 1}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{blacklistContact?.name}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{blacklistContact?.phone_number}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content} style={{ whiteSpaceCollapse: 'break-spaces' }}>
                {blacklistContact?.reason}
              </div>
            </Grid>
            <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
              <Button className={classes.button} onClick={(event) => handleOpenMenu(event, blacklistContact)}>
                <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </Table>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.UPDATE)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:edit')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.DELETE)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      {openModal && (
        <BlacklistDetailModal
          dialogStatus={dialogStatus}
          openModal={openModal}
          values={selectedRow}
          handleCloseModal={handleCloseModal}
          onSubmit={handleSubmitData}
        />
      )}

      {openModalUpload && (
        <UploadModal
          openModal={openModalUpload}
          handleCloseModal={handleCloseModalUpload}
          initialModalData={initialModalData}
          onSubmit={handleUploadData}
        />
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
