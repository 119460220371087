import { createContext, useReducer } from 'react';
import audioService from 'services/audio.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  audios: [],
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUDIO_RECORDS': {
      const { audios, total } = action.payload;
      return { ...state, audios, total };
    }
    case 'CREATE_AUDIO_RECORD': {
      const { audio } = action.payload;
      return { ...state, audios: state.audios.concat([audio]), total: state.total + 1 };
    }
    case 'UPDATE_AUDIO_RECORD': {
      const { audio } = action.payload;
      const updatedPermissions = replaceItemInArrayByKey(state.audios, 'id', audio);
      return { ...state, audios: updatedPermissions };
    }
    case 'DELETE_AUDIO_RECORD': {
      const { id } = action.payload;
      return { ...state, audios: state.audios.filter((audio) => audio.id !== id), total: state.total - 1 };
    }

    default:
      return { ...state };
  }
};

const AudioRecordContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const AudioRecordProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const { data: audios, meta } = await audioService.get(params);
      dispatch({
        type: 'SET_AUDIO_RECORDS',
        payload: {
          audios,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: audio } = await audioService.createAudioRecord(data);
      dispatch({
        type: 'CREATE_AUDIO_RECORD',
        payload: {
          audio,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: audio } = await audioService.update(id, data);
      dispatch({
        type: 'UPDATE_AUDIO_RECORD',
        payload: {
          audio,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await audioService.delete(id);
      dispatch({
        type: 'DELETE_AUDIO_RECORD',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <AudioRecordContext.Provider value={{ ...state, method: 'AudioRecord', get, create, update, destroy }}>
      {children}
    </AudioRecordContext.Provider>
  );
};

AudioRecordProvider.propTypes = {
  children: PropTypes.node,
};

export default AudioRecordContext;
