import SearchBar from 'components/Search';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from 'hooks/useToast';
// @mui
import { Stack, Button, Box, Grid } from '@mui/material';
import ScenarioTable from 'pages/ScenarioPage/ScenarioTable';

import { PAGINATION, SCENARIO_TYPE } from 'constants';
import { makeStyles } from '@mui/styles';
// components
import Toast from 'components/Toast';
import Iconify from '../../../components/iconify';
import ScenarioModal from '../ScenarioModal';
// mock
import SCENARIO_GROUP from '../../../_mock/scenario';
import navScenarioConfig from '../config';
import { DIALOG_STATUS } from '../../../constants/dialog';
import CreateScenario from '../ScenarioModal/CreateScenario';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  navItem: {
    padding: '5px 10px',
    width: '100%',
    justifyContent: 'start',
    color: theme.palette.text.disabled,
  },
}));

export default function ScenarioList({
  hasFilterOption = false,
  scenarios = [],
  total = 0,
  get,
  create,
  update,
  destroy,
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { toast, handleSetToast, handleCloseToast } = useToast();

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const filteredUsers = applySortFilter(SCENARIO_GROUP, getComparator(order, orderBy), filter);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterType, setFilterType] = useState('all');

  const headers = [
    {
      label: 'ID',
      canSort: true,
      width: 1,
    },
    {
      label: t('table:name'),
      canSort: true,
    },
    {
      label: t('table:type'),
      canSort: false,
      width: 2,
    },
    {
      label: t('table:enable'),
      canSort: false,
      width: 1,
    },
    {
      label: t('table:phoneNumber'),
      canSort: false,
      width: 2,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) setSelectedRow(null);
    // handleCloseMenu();
    setDialogStatus(status);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleFilterByType = (title) => {
    setPage(0);
    setFilterType(title);
  };

  useEffect(() => {
    const filterScenarios = async () => {
      await get({ page, limit: PAGINATION.LIMIT, keyword: filter, type: SCENARIO_TYPE[filterType] });
    };

    try {
      filterScenarios();
    } catch (err) {
      console.log(err);
    }
  }, [page, filter, filterType]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchBar setFilter={setFilter} />
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
          onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
        >
          {t('table:add')}
        </Button>
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{
            '&>.MuiGrid-item:first-of-type': {
              paddingTop: 0,
            },
          }}
        >
          <Grid item xs={4} md={1.5}>
            <Box>
              <Stack direction={'column'} alignItems={'start'} p={1} gap={0.5}>
                {navScenarioConfig.map(({ title }) => (
                  <Button
                    color="inherit"
                    key={title}
                    // sx={{ py: 0.5, px: 1, width: '100%', justifyContent: 'start', color: 'text.disabled' }}
                    className={classes.navItem}
                    onClick={() => handleFilterByType(title)}
                    sx={{
                      textAlign: 'left',
                      color: `${filterType === title ? '#2065d1 !important' : '#919EAB !important'}`,
                    }}
                  >
                    {t(title)}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={8} md={10.5}>
            <ScenarioTable
              handleOpenModal={handleOpenModal}
              scenarios={scenarios}
              total={total}
              headers={headers}
              update={update}
              get={get}
            />
          </Grid>
        </Grid>
      </Box>

      <ScenarioModal
        title="Create Scenario"
        dialogStatus={dialogStatus}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <CreateScenario onClose={handleCloseModal} create={create} handleSetToast={handleSetToast} />
      </ScenarioModal>

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
