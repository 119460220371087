import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  list: {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: '0',
    width: '326px',
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
  },
  item: {
    margin: 0,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
  },
}));

const Search = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.list}>
      <div style={{ padding: '8px' }}>
        <TextField
          id="outlined-start-adornment"
          sx={{
            width: '100%',
            '&>.MuiInputBase-root': {
              borderRadius: '50px',
            },
          }}
          placeholder={t('search')}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default Search;
