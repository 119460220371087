import { Button, TextField, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Iconify from 'components/iconify/Iconify';
import { DIALOG_STATUS } from 'constants';

const BlacklistModal = ({ dialogStatus, values, openModal, handleCloseModal, onSubmit }) => {
  const { t } = useTranslation('common');
  const schema = yup
    .object({
      name: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: values || {
      name: '',
      note: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth="true" maxWidth="xs" sx={{
      '& .MuiPaper-root': {
        padding: '15px 20px'
      },
    }}>
      <DialogTitle sx={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{ textTransform: 'capitalize' }}>
          {dialogStatus === DIALOG_STATUS.CREATE && t('table:createBlacklist')}
          {dialogStatus === DIALOG_STATUS.UPDATE && t('table:updateBlacklist')}
          {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteBlacklist')}
        </span>
        <div role="presentation" style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
          <Iconify icon='ic:baseline-close' />
        </div>
      </DialogTitle>
      {dialogStatus !== DIALOG_STATUS.DELETE && (
        <DialogContent sx={{ padding: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  margin="dense"
                  label={t('table:name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            />

            <Controller
              name="note"
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.note}
                  helperText={errors?.note?.message}
                  margin="dense"
                  label={t('table:note')}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                />
              )}
            />
          </form>
        </DialogContent>
      )}

      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleCloseModal} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: '#fff',
            background: (theme) => dialogStatus === DIALOG_STATUS.DELETE ? theme.palette.error.main : theme.palette.primary.main,
            '&:hover': {
              background: (theme) => dialogStatus === DIALOG_STATUS.DELETE ? theme.palette.error.main : theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {dialogStatus === DIALOG_STATUS.CREATE && t('table:add')}
          {dialogStatus === DIALOG_STATUS.UPDATE && t('table:update')}
          {dialogStatus === DIALOG_STATUS.DELETE && t('table:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BlacklistModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default BlacklistModal;
