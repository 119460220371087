import { conversationContent } from '_mock/conversation';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import { Button, Dialog, Grid } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import LogNotfound from 'assests/images/log_notfound.svg';
import NotFound from 'components/NotFound';
import { makeStyles } from '@mui/styles';
import { copyToClipboard } from 'utils/copyToClipboard';
import Toast from 'components/Toast';
import { formatLocaleDateTime } from 'utils/formatTime';
import { getStatusString } from '../Row';

const useStyles = makeStyles(() => ({
  title: {
    color: '#3e4345',
    margin: '0',
    fontSize: '19px',
    fontWeight: 600,
    paddingBottom: '16px',
  },
  leftPanel: {
    height: '525px',
    display: 'flex',
    padding: '8px',
    overflow: 'auto',
    background: '#f5f5f5',
    borderRadius: '6px',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    borderBottom: '1px solid #eff2f7',
    marginBottom: '15px',
    paddingBottom: '10px',
  },
  rowLabel: {
    color: '#78909c',
    fontSize: '14px',
    fontWeight: 500,
    paddingRight: '10px',
  },
  rowText: {
    color: '#374c56',
    fontSize: '14px',
  },
  logContainer: {
    height: '468px',
    padding: '8px',
    overflow: 'auto',
    background: '#f5f5f5',
    borderRadius: '6px',
    marginBottom: '15px',
  },
  button: {
    minWidth: 'unset',
    padding: 0,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

const LogFile = ({
  open,
  onClose,
  called_at,
  status,
  duration,
  customerPhone,
  number,
  number_of_attempts,
  conversation_id,
  conversation_type,
  campaignName,
  reportRecordPath,
}) => {
  const classes = useStyles();
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });
  const [content, setContent] = useState(conversationContent);

  const {
    call_at,
    create_at,
    call_duration,
    ring_duration,
    callcenter_phone,
    customer_phone,
    conversation_status_code,
    attempt_level,
    sip_code,
    scenario,
    scenario_publication,
    campaign,
  } = content;

  const handleCopy = (text) => {
    copyToClipboard(text);
    setToast({ open: true, type: 'success', message: 'Coppied!' });
  };

  const handleClose = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        sx={{
          '& .MuiPaper-root': {
            padding: '25px 25px 20px 25px',
            width: '1000px',
          },
          '& .MuiStepConnector-root': {
            display: 'none',
          },
          '& .MuiStep-root': {
            paddingLeft: '5px !important',
            paddingRight: '5px !important',
            flex: 'unset !important',
          },
          '& .MuiStepLabel-alternativeLabel': {
            marginTop: 'unset !important',
            textAlign: 'left',
          },
        }}
      >
        <div style={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="flex-end">
            <Button className={classes.button} onClick={onClose}>
              <Iconify icon="material-symbols:close" />
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={5}>
              <div className={classes.title}>Information</div>
              <div className={classes.leftPanel}>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    ID
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    className={classes.rowText}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleCopy(conversation_id)}
                  >
                    {conversation_id}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    privateLink
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    <a href={`/conversations/${conversation_id}/private`}>
                      {'private'}
                      {'_'}
                      {conversation_id}
                    </a>
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    publicLink
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    <a href={`/conversations/${conversation_id}/public`}>
                      {'public'}
                      {'_'}
                      {conversation_id}
                    </a>
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Type
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {conversation_type === 1 ? 'Outbound' : 'Inbound'} Call
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Call Center Phone
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {number?.number || '-'}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Customer Phone
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {customerPhone}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Created At
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {formatLocaleDateTime(create_at)}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Call At
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {formatLocaleDateTime(called_at)}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Ring Duration
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {ring_duration ? '' : '00:00'}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Call Duration
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {call_duration ? '' : '00:00'}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Converstion Status Code
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {getStatusString(conversation_status_code)}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Sip Code
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {sip_code}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Scenario
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {scenario.scenario_name}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Publication
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {scenario_publication.scenario_publication_name}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Campaign
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {campaignName}
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid item xs={5} className={classes.rowLabel}>
                    Attempt
                  </Grid>
                  <Grid item xs={7} className={classes.rowText} sx={{ cursor: 'pointer' }}>
                    {number_of_attempts}
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item container xs={7}>
              <div className={classes.title}>
                Logs
                <Iconify icon="tabler:copy" width={18} height={18} style={{ marginLeft: '10px', cursor: 'pointer' }} />
              </div>
              <Grid container className={classes.logContainer}>
                <Grid container item direction="column" justifyContent="center" alignItems="center">
                  <NotFound title="log not found!" image={LogNotfound} />
                </Grid>
              </Grid>
              <ReactAudioPlayer src={reportRecordPath} controls style={{ width: '100%', outline: 'none' }} />
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Toast openToast={toast.open} handleClose={handleClose} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
};

LogFile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reportRecordPath: PropTypes.string,
};

export default LogFile;
