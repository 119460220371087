import APIService from './api.service';

const baseURL = '/numbers';

class NumberService extends APIService {
    async checkNumber () {
        try {
            const response = await this.axios.post(`${this.baseURL}/check-all-number`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Failed to post data');
        }
    }
}

export default new NumberService(baseURL);
