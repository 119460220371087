import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './Datepicker.css';

import PropTypes from 'prop-types';

const DateRangeSelector = ({ ranges, onChange, onSubmit, selectedDateRange, setSelectedDateRange, ...rest }) => {
  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
  };

  return (
    <>
      <div className="shadow d-inline-block">
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[selectedDateRange]}
          direction="horizontal"
        />
      </div>
    </>
  );
};

DateRangeSelector.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  ranges: PropTypes.array,
};

DateRangeSelector.propTypes = {
  /**
   * On Submit
   */
  ranges: PropTypes.array,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  selectedDateRange: PropTypes.object,
  setSelectedDateRange: PropTypes.func,
};

export default DateRangeSelector;
