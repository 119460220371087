import { Grid, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'components/iconify';
import PropTypes from 'prop-types';

const Item = ({ icon, label, value, classes }) => (
  <>
    <Grid
      item
      container
      direction="row"
      xs={12}
      justifyContent="flex-start"
      alignItems="center"
      sx={{ margin: '10px 0' }}
    >
      {icon && (
        <Grid item xs={1}>
          <Iconify icon={icon} color="#78909c" />
        </Grid>
      )}
      <Grid item xs={11}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  </>
);

Item.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object,
};

export default Item;
