import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import { PAGINATION } from 'constants';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '10px',
    marginBottom: '12px',
    backgroundColor: 'rgba(120, 144, 156, 0.1)',
  },
  body: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    minHeight: '570px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    marginRight: '4px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
}));

const Table = ({ total = 0, get, headers, children, onChangePage }) => {
  const classes = useStyles();

  const numberOfPages = useMemo(
    () => (total % PAGINATION.LIMIT === 0 ? total / PAGINATION.LIMIT : Math.floor(total / PAGINATION.LIMIT) + 1),
    [total]
  );

  const handleChangePage = (event, page) => {
    get({ page, limit: PAGINATION.LIMIT });
    onChangePage(page);
  };

  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  return (
    <>
      <Grid container direction="row" className={classes.header} alignItems="center">
        {headers.map((header, idx) => (
          <Grid
            item
            xs={header.width}
            key={header.label}
            style={{
              flex: `${idx === headers.length - 1 ? 'unset' : '1'}`,
              paddingLeft: `${idx === 0 ? '15px' : ''}`,
              paddingTop: '16px',
              paddingBottom: '16px',
              borderRadius: `${idx === 0 ? '10px 0 0 10px' : ''}`,
              justifyContent: `${idx === 0 ? 'flex-start' : 'center'}`,
              alignItems: 'center',
              display: 'flex',
              margin: '-7.5px 0',
              cursor: `${header.canSort ? 'pointer' : 'default'}`,
              width: `${idx === headers.length - 1 ? '30px' : ''}`,
            }}
            sx={{
              color: (theme) => theme.palette.text.black,
            }}
          >
            {header.canSort ? (
              <div className={classes.sortContainer}>
                <Iconify
                  style={{ marginBottom: '-11.5px' }}
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-up-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
                <Iconify
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
              </div>
            ) : null}
            {header.label}
          </Grid>
        ))}
      </Grid>

      <div className={classes.body}>{children}</div>

      {numberOfPages !== 0 && (
        <Grid container direction={'row'} justifyContent="center" sx={{ marginTop: '15px' }}>
          <Pagination
            className={classes.pagination}
            count={numberOfPages}
            defaultPage={1}
            boundaryCount={1}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </>
  );
};

Table.propTypes = {
  total: PropTypes.number,
  headers: PropTypes.array,
  get: PropTypes.func,
  children: PropTypes.node,
  onChangePage: PropTypes.func,
};

export default Table;
