import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
// @mui
import {
  Button,
  TextField,
  Box,
  ButtonGroup,
  Chip,
  Grid,
  Stack,
  Autocomplete,
  Typography,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Iconify from 'components/iconify';
import TimePicker from 'components/TimePicker';
import { call_days } from '_mock/scenario';
import ToggleDate from './components/ToggleDate';
import ToggleDuration from './components/ToggleDuration';
import WaitingTime from './components/WaitingTime';
import Check from './components/Check';
import HelpText from '../HelpText';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    backgroundColor: '#fff',
    '& .MuiInputBase-root': {
      height: '46px',
    },
  },
  container: {
    marginTop: '15px',
  },
  heading: {
    fontSize: '12px',
    color: '#78909c',
    marginBottom: '5px',
  },
  icon: {
    color: '#78909c',
    width: 15,
    height: 15,
    paddingLeft: 'unset',
    cursor: 'pointer',
  },
  iconNormal: {
    color: '#78909c',
    width: 17,
    height: 17,
    paddingLeft: 'unset',
    cursor: 'pointer',
  },
  autoComplete: {
    background: '#fff',
  },
  listItem: {
    marginBottom: '15px',
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#3c52b2',
      color: '#3c52b2',
    },
  },
  button: {
    width: '40px',
    height: '34.5px',
  },
  overFlowPanel: {
    maxHeight: '434px',
    height: '434px',
    overflowY: 'auto',
    paddingRight: '10px',
  },
}));

const attempts = [0, 1, 2, 3, 4, 5];

export function translateDayToEng(dayName) {
  const dayMap = {
    'Thứ 2': 'Monday',
    'Thứ 3': 'Tuesday',
    'Thứ 4': 'Wednesday',
    'Thứ 5': 'Thursday',
    'Thứ 6': 'Friday',
    'Thứ 7': 'Saturday',
    'Chủ nhật': 'Sunday',
  };

  return dayMap[dayName] || dayName;
}

function translateDayToVni(dayName) {
  const dayMap = {
    MONDAY: 'Thứ 2',
    TUESDAY: 'Thứ 3',
    WEDNESDAY: 'Thứ 4',
    THURSDAY: 'Thứ 5',
    FRIDAY: 'Thứ 6',
    SATURDAY: 'Thứ 7',
    SUNDAY: 'Chủ nhật',
  };

  return dayMap[dayName] || dayName;
}

function checkLastElementExistence(array) {
  const lastElement = array[array.length - 1];
  const restOfArray = array.slice(0, array.length - 1);
  return restOfArray.find((el) => el.title === lastElement.title);
}

export default function Schedule({
  data,
  setData,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  intervalDay,
  setIntervalDay,
  intervalTime,
  setIntervalTime,
  minWaiting,
  setMinWaiting,
  maxWaiting,
  setMaxWaiting,
  checkedStartCampaign,
  setCheckedStartCampaign,
  checkedEndCampaign,
  setCheckedEndCampaign,
  handleNext,
  handleBack,
}) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles();
  const { number_of_attempts } = data;
  const days = [...Array(6).keys()];

  const scheduleStepSchema = yup
    .object({
      call_days: yup.array().min(1, t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(scheduleStepSchema),
  });

  const handleChange = (value, name) => {
    setData(name, value);
  };

  const defaultCallDays = useMemo(() => {
    if (typeof data?.call_days === 'string') {
      return JSON.parse(data?.call_days).map((el, idx) => ({ id: idx + 1, title: translateDayToVni(el) }));
    }
    return data.call_days.map((el, idx) => ({ id: idx + 1, title: translateDayToVni(el) }));
  }, [data]);

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px' }}>
        <Typography sx={{ color: '#78909c', fontSize: '14px', fontWeight: 600 }} variant="string">
          {t(`table:schedule`)}
        </Typography>

        <form onSubmit={handleSubmit(handleNext)}>
          <Grid container sx={{ marginLeft: 'unset', marginTop: 'unset', width: '100%' }}>
            <Grid
              container
              xs={6}
              direction="column"
              sx={{ zIndex: '1', overflowY: 'auto' }}
              className={classes.overFlowPanel}
            >
              <Grid xs={12} direction="column">
                <Grid container item xs={12} direction="row" alignItems={'center'} className={classes.container}>
                  <ToggleDate
                    title={t(`table:${'campaign_start'}`)}
                    name="start_time"
                    control={control}
                    state={checkedStartCampaign}
                    setState={setCheckedStartCampaign}
                    value={data.start_time}
                    setValue={handleChange}
                    help={
                      'Date and time when IVR Smart Call will start calling your customers. If you select the Ignore checkbox, it will start calling customers immediately after you start the campaign. WARNING! If you specify the date and time before the current date and time, then the system will also start calling your customers immediately after you start the campaign at step 4 of the Campaign Manager.'
                    }
                  />
                </Grid>
                <Grid container item xs={12} direction="row" alignItems={'center'} className={classes.container}>
                  <ToggleDate
                    title={t(`table:${'campaign_end'}`)}
                    name="end_time"
                    control={control}
                    state={checkedEndCampaign}
                    setState={setCheckedEndCampaign}
                    value={data.end_time}
                    setValue={handleChange}
                    help={
                      'Date and time when IVR Smart Call will start calling your customers. If you select the Ignore checkbox, it will start calling customers immediately after you start the campaign. WARNING! If you specify the date and time before the current date and time, then the system will also start calling your customers immediately after you start the campaign at step 4 of the Campaign Manager.'
                    }
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <Grid item xs={12} className={classes.heading}>
                    {t(`table:callDays`)}
                  </Grid>
                  <Grid item container direction={'row'} xs={12}>
                    <Grid item xs={11.3}>
                      <Controller
                        name={'call_days'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={defaultCallDays}
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            className={classes.autoComplete}
                            options={call_days}
                            value={defaultCallDays}
                            getOptionLabel={(option) => option.title}
                            onChange={(e, newValue) => {
                              let formattedValue = [...newValue];
                              if (checkLastElementExistence(formattedValue)) {
                                formattedValue = formattedValue.filter(
                                  (el) => el.title !== formattedValue[formattedValue.length - 1].title
                                );
                              }
                              const translatedDays = formattedValue.map((el) => translateDayToEng(el.title));
                              handleChange(translatedDays, 'call_days');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors?.call_days}
                                helperText={errors?.call_days?.message}
                                variant="outlined"
                                sx={{
                                  '&  .MuiFormHelperText-root.Mui-error': {
                                    backgroundColor: '#f6f9fa',
                                    margin: '0px',
                                    padding: '3px 14px 0px',
                                  },
                                }}
                              />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  value={option.title}
                                  key={option.id}
                                  label={option.title}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  {selected ? (
                                    <Chip variant="outlined" size="small" label={option.title} />
                                  ) : (
                                    <Chip size="small" label={option.title} />
                                  )}
                                </Stack>
                              </li>
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container direction={'row'} item xs={0.7} justifyContent={'end'}>
                      <HelpText classes={classes} text="callDaysHint" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <ToggleDuration
                    classes={classes}
                    state={data.working_hours_active}
                    setState={(value) => handleChange(value, 'working_hours_active')}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    help="The time during which IVR Smart Call will be calling your customers. The customers' local time is used. IMPORTANT! The local time is calculated according to the  parameter in your Excel file. If you select the Ignore checkbox, IVR Smart Call will be calling your customers 24/7"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              xs={6}
              sx={{
                zIndex: '1',
                maxHeight: '410px',
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingLeft: '10px',
              }}
            >
              <Grid xs={12} direction="column">
                <Grid container item xs={12} direction="row" alignItems={'center'} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>{t('table:numberAttempt')}</Typography>
                  </Grid>
                  <Grid container spacing={1} item xs={12} direction="row" justifyContent={'space-between'}>
                    <Grid item xs={11.4} direction="column" justifyContent="center">
                      <Grid item xs={12}>
                        <Controller
                          name="numberAttempt"
                          control={control}
                          rules={{ required: true }}
                          render={() => (
                            <ButtonGroup
                              variant="contained"
                              aria-label="outlined primary button group"
                              sx={{
                                background: '#fff',
                                width: '100%',
                                justifyContent: 'space-around',
                                padding: '5.5px',
                                '&>.MuiButtonBase-root.MuiButton-root': {
                                  borderRadius: '6px',
                                },
                              }}
                            >
                              {attempts.map((el) => (
                                <Button
                                  key={el}
                                  sx={{
                                    background: `${
                                      parseInt(number_of_attempts, 10) === el ? theme.palette.primary.main : '#fff'
                                    }`,
                                    color: `${parseInt(number_of_attempts, 10) === el ? '#fff' : '#333'}`,
                                    borderColor: 'unset !important',
                                    borderRight: 'unset !important',
                                    '&:hover': {
                                      background: `${
                                        parseInt(number_of_attempts, 10) === el ? theme.palette.primary.main : '#f5f5f5'
                                      }`,
                                      color: `${parseInt(number_of_attempts, 10) === el ? '#fff' : '#333'}`,
                                    },
                                  }}
                                  className={classes.button}
                                  onClick={() => handleChange(el, 'number_of_attempts')}
                                >
                                  {el}
                                </Button>
                              ))}
                            </ButtonGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={0.6} sx={{ paddingLeft: '5px !important' }}>
                      <HelpText
                        classes={classes}
                        text="Number of attempts – you can choose how many times to dial each number from your contact list. If it is not possible to reach the subscriber for the indicated number of attempts, this subscriber will be marked as 'unsuccessful' in the campaign statistics."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>Interval</Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={5.8}>
                      <Controller
                        name="attempt_interval"
                        control={control}
                        rules={{ required: true }}
                        render={() => (
                          <Select
                            value={intervalDay}
                            onChange={(e) => setIntervalDay(e.target.value)}
                            size="small"
                            sx={{
                              height: '46px',
                              width: '100%',
                              '.MuiSvgIcon-root': { display: 'none' },
                              background: '#fff',
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '60px !important',
                                  boxShadow: 'unset !important',
                                  background: 'unset !important',
                                  marginTop: '10px',
                                  borderRadius: '6px !important',
                                  '& .MuiList-root': {
                                    background: '#fff !important',
                                    paddingTop: 'unset !important',
                                    paddingBottom: 'unset !important',
                                    width: '45% !important',
                                    boxShadow: '2px 5px 6px 3px #bdbdbd78',
                                    borderRadius: '6px',
                                  },
                                  '& .MuiMenuItem-root': {
                                    color: theme.palette.primary.main,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '40px',
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    lineHeight: '40px',
                                    '&:hover': {
                                      background: '#78909c1a',
                                    },
                                  },
                                },
                              },
                            }}
                            endAdornment={
                              <IconButton size="small">
                                <Iconify icon="mdi-calendar" className={classes.iconNormal} />
                              </IconButton>
                            }
                            renderValue={(value) => `0${value}d`}
                          >
                            {days.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid xs={5.8} sx={{ paddingTop: '0px', height: '46px', cursor: 'pointer' }}>
                      <Controller
                        name="interval"
                        control={control}
                        rules={{ required: true }}
                        render={() => <TimePicker right={'68px'} value={intervalTime} setValue={setIntervalTime} />}
                      />
                    </Grid>
                    <Grid xs={0.4} direction="row" alignItems="center" justifyContent="center">
                      <HelpText classes={classes} text="Time interval between call attempts to the same number." />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <Grid item xs={12} className={classes.heading}>
                    {t('table:minWaitingTime')}
                  </Grid>
                  <WaitingTime
                    name={'min_waiting_time'}
                    enable={data.min_waiting_time_active}
                    setEnable={handleChange}
                    control={control}
                    classes={classes}
                    waitingTime={minWaiting}
                    setWaitingTime={setMinWaiting}
                    help="Min waiting time"
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <Grid item xs={12} className={classes.heading}>
                    {t('table:maxWaitingTime')}
                  </Grid>
                  <WaitingTime
                    name={'max_waiting_time'}
                    enable={data.max_waiting_time_active}
                    setEnable={handleChange}
                    control={control}
                    classes={classes}
                    waitingTime={maxWaiting}
                    setWaitingTime={setMaxWaiting}
                    help="Max waiting time"
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justifyContent={'space-between'}
                  className={classes.container}
                >
                  <Grid item container xs={6} direction="row" sx={{ paddingRight: '10px' }}>
                    <Check
                      name={'duplicate'}
                      title={t('table:checkDuplicate')}
                      classes={classes}
                      enable={data.duplicate}
                      setEnable={handleChange}
                      help="check_duplicate_interval"
                    />
                  </Grid>

                  <Grid item container xs={6} direction="row">
                    <Check
                      name={'validate_phone_number'}
                      title={t('table:validatePhoneNumber')}
                      classes={classes}
                      enable={data.validate_phone_number}
                      setEnable={handleChange}
                      help="Auto validate phone number"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box
        sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', textAlign: 'right' }}
      >
        <Button
          sx={{
            ':hover': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#d5d5d5',
            },
            backgroundColor: '#e0e0e0',
            marginRight: '10px',
            color: 'rgba(0, 0, 0, 0.87);',
          }}
          variant="contained"
          onClick={handleBack}
        >
          {t('back')}
        </Button>
        <Button variant="contained" type="submit" onClick={handleSubmit(handleNext)}>
          {t('next')}
        </Button>
      </Box>
    </>
  );
}

Schedule.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  setData: PropTypes.func,
  startTime: PropTypes.object,
  setStartTime: PropTypes.func,
  endTime: PropTypes.object,
  setEndTime: PropTypes.func,
  intervalDay: PropTypes.number,
  setIntervalDay: PropTypes.func,
  intervalTime: PropTypes.number,
  setIntervalTime: PropTypes.func,
  minWaiting: PropTypes.object,
  setMinWaiting: PropTypes.func,
  maxWaiting: PropTypes.object,
  setMaxWaiting: PropTypes.func,
  checkedStartCampaign: PropTypes.bool,
  setCheckedStartCampaign: PropTypes.func,
  checkedEndCampaign: PropTypes.bool,
  setCheckedEndCampaign: PropTypes.func,
  checkedWorkingHours: PropTypes.bool,
  setCheckedWorkingHours: PropTypes.func,
};
