import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useCommon from 'hooks/useCommon';
import { makeStyles } from '@mui/styles';

// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import navConfig from '../nav/config';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.dark,
    cursor: 'pointer',
    fontSize: '24px',
    '&:hover': {
      color: theme.palette.text.blue,
      textDecoration: 'underline',
    },
    paddingRight: '10px',
  },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  zIndex: '1000',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const classes = useStyles();
  const { parentPage, childName } = useCommon();
  const location = useLocation();
  const { t } = useTranslation('common');

  const getHeader = () => {
    const currentPage = location.pathname.split('/')[1];
    if (!currentPage) return 'Header';

    const header = navConfig.find((el) => el.path === `/${currentPage}`);
    if (!header) return 'Header';

    if (parentPage && parentPage === header.title) {
      return (
        <>
          <Link className={classes.link} to={header.path}>
            {t(header.title)}
          </Link>
          <Iconify icon="material-symbols:arrow-forward-ios-rounded" width={12} height={12} color="#78909c" />
          <span style={{ paddingLeft: '10px' }}>{childName}</span>
        </>
      );
    }
    return t(header.title);
  };

  return (
    <StyledRoot>
      <StyledToolbar>
        <Typography
          sx={{ fontSize: '24px', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}
          noWrap
          color={'black'}
        >
          {getHeader()}
        </Typography>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
