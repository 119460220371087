import { createContext, useReducer } from 'react';
import permissionService from 'services/permission.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  permissions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERMISSIONS': {
      const { permissions } = action.payload;
      return { ...state, permissions };
    }
    case 'CREATE_PERMISSION': {
      const { permission } = action.payload;
      return { ...state, permissions: state.permissions.concat([permission]) };
    }
    case 'UPDATE_PERMISSION': {
      const { permission } = action.payload;
      const updatedPermissions = replaceItemInArrayByKey(state.permissions, 'id', permission);
      return { ...state, permissions: updatedPermissions };
    }
    case 'DELETE_PERMISSION': {
      const { id } = action.payload;
      return { ...state, permissions: state.permissions.filter((permission) => permission.id !== id) };
    }

    default:
      return { ...state };
  }
};

const PermissionContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const PermissionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async () => {
    try {
      const { data: permissions } = await permissionService.get();
      dispatch({
        type: 'SET_PERMISSIONS',
        payload: {
          permissions,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: permission } = await permissionService.create(data);
      dispatch({
        type: 'CREATE_PERMISSION',
        payload: {
          permission,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: permission } = await permissionService.update(id, data);
      dispatch({
        type: 'UPDATE_PERMISSION',
        payload: {
          permission,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await permissionService.delete(id);
      dispatch({
        type: 'DELETE_PERMISSION',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <PermissionContext.Provider value={{ ...state, method: 'Permission', get, create, update, destroy }}>
      {children}
    </PermissionContext.Provider>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.node,
};

export default PermissionContext;
