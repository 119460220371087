import APIService from './api.service';
import { setToken, removeToken } from '../utils/auth';

class AuthService extends APIService {
  async getCurrentUser() {
    try {
      const res = await this.axios.get('/user');
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async getUsers(pageNum, perPage = 10, orderBy = 'user_id', orderType = 'desc') {
    try {
      // orderType = 'desc' || 'asc'
      const res = await this.axios.get(
        `/user/getUser?page_num=${pageNum}&per_page=${perPage}&order_by=${orderBy}&order_type=${orderType}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async login(data) {
    try {
      const res = await this.axios.post(`/login`, data);
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error('Tên tài khoản hoặc mật khẩu không chính xác');
      } else {
        throw new Error('Lỗi hệ thống, vui lòng thử lại sau');
      }
    }
  }
}

export default new AuthService();
