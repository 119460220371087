import { Navigate, useRoutes } from 'react-router-dom';
import { Suspense } from 'react';

import LoadingScreen from 'components/LoadingScreen';
import GuestGuard from 'components/Guard/GuestGuard';
import AuthGuard from 'components/Guard/AuthGuard';
// layouts
import DashboardLayout from 'layouts/dashboard';
import SimpleLayout from 'layouts/simple';
// pages
import UserPage from 'pages/UserPage';
import ManageAPIKey from 'pages/ManageAPIKey';
// import MonitorPage from 'pages/MonitorPage';
import NumberGroupPage from 'pages/NumberGroupPage';
import ContactGroupPage from 'pages/ContactGroupPage';
import ScenarioPage from 'pages/ScenarioPage';
import LoginPage from 'pages/LoginPage';
import Page404 from 'pages/Page404';
import Role from 'pages/Role';
// import ScenarioFlow from 'pages/ScenarioFlow';
import Outbound from 'pages/OutBound';
import CampaignPage from 'pages/OutBound/components/CampaignPage';
import Monitor from 'pages/OutBound/components/Monitor';
import CampaignReport from 'pages/OutBound/components/CampaignReport';
import AutoCampaign from 'pages/OutBound/components/AutoCampaign';
import ScenarioAnalyze from 'pages/OutBound/components/ScenarioAnalyze';
import Billing from 'pages/Billing';
import Overview from 'pages/Billing/components/Overview';
import OverviewPage from 'pages/Overview';
import Deposit from 'pages/Billing/components/Deposit';
import Transaction from 'pages/Billing/components/Transaction';
import ScenarioList from 'pages/OutBound/components/ScenarioList';
import OutboundChildDetail from 'pages/OutboundChildDetail';
import { campaignChildrenTabs, scenariohildrenTabs, inboundScenarioTabs } from 'pages/OutboundChildDetail/helpers';
import ScenarioHistory from 'pages/OutBound/components/ScenarioHistory';
import InBound from 'pages/InBound';
import CostEstimate from 'pages/CostEstimate';
import AuditLog from 'pages/AuditLog';
import AudioRecord from 'pages/AudioRecord';
import Blacklist from 'pages/Blacklist';
import BlacklistDetail from 'pages/Blacklist/BlacklistDetail';
import NumbersPage from 'pages/Numbers';

import useAuth from 'hooks/useAuth';
import { filterRoutes } from 'utils/auth';

// ----------------------------------------------------------------------

export default function Router() {
  const { permissions } = useAuth();

  const routes = [
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/campaigns" />, index: true },
        { path: 'users', element: <UserPage />, permission: 'get_user' },
        // TODO: add permissions
        { path: 'manage-api-key', element: <ManageAPIKey /> },
        { path: 'cost-estimate', element: <CostEstimate /> },
        { path: 'overview', element: <OverviewPage /> },
        { path: 'numbers', element: <NumbersPage /> },
        { path: 'logging', element: <AuditLog /> },
        // { path: 'monitor', element: <MonitorPage /> },
        { path: 'manage-number-group', element: <NumberGroupPage /> },
        { path: 'manage-contact-group', element: <ContactGroupPage /> },
        { path: 'scenario', element: <ScenarioPage /> },
        { path: 'roles', element: <Role /> },
        { path: 'audio-record', element: <AudioRecord /> },
        { path: 'blacklists', element: <Blacklist /> },
        { path: 'blacklists/:id', element: <BlacklistDetail /> },
        // {
        //   path: 'outbound',
        //   element: <Outbound />,
        //   children: [
        //     {
        //       path: '/outbound',
        //       element: <Navigate to={'/outbound/scenarios'} />,
        //       index: true,
        //     },
        //     {
        //       path: 'campaigns',
        //       element: <CampaignPage />,
        //     },
        //     {
        //       path: 'auto-campaigns',
        //       element: <AutoCampaign />,
        //     },
        //     { path: 'scenarios', element: <ScenarioList page={'outbound'} />, index: true },
        //     {
        //       path: 'monitor',
        //       element: <Monitor />,
        //     },
        //   ],
        // },
        {
          path: 'campaigns',
          element: <CampaignPage />,
        },
        {
          path: 'campaigns/:id/report',
          element: <CampaignReport />,
        },
        // {
        //   path: 'campaigns/:id',
        //   element: <OutboundChildDetail getTabs={campaignChildrenTabs} />,
        //   children: [
        //     {
        //       path: 'report',
        //       element: <CampaignReport />,
        //     },
        //     {
        //       path: 'monitor',
        //       element: <Monitor />,
        //     },
        //     {
        //       path: 'scenarioAnalyze',
        //       element: <ScenarioAnalyze page={'campaign'} />,
        //     },
        //   ],
        // },
        {
          path: 'outbound/scenarios/:id',
          element: <OutboundChildDetail getTabs={scenariohildrenTabs} />,
          children: [
            {
              path: 'history',
              element: <ScenarioHistory page={'outbound'} />,
            },
            {
              path: 'campaigns',
              element: <CampaignPage />,
            },
            {
              path: 'auto-campaigns',
              element: <AutoCampaign />,
            },
            {
              path: 'monitor',
              element: <Monitor />,
            },
            {
              path: 'scenarioAnalyze',
              element: <ScenarioAnalyze page={'scenario'} />,
            },
          ],
        },
        {
          path: 'inbound',
          element: <InBound />,
          children: [
            {
              path: '/inbound',
              element: <Navigate to={'/inbound/scenarios'} />,
              index: true,
            },
            { path: 'scenarios', element: <ScenarioList page={'inbound'} />, index: true },
            {
              path: 'monitor',
              element: <Monitor />,
            },
          ],
        },
        {
          path: 'inbound/scenarios/:id',
          element: <OutboundChildDetail getTabs={inboundScenarioTabs} />,
          children: [
            {
              path: 'history',
              element: <ScenarioHistory page={'inbound'} />,
            },
            {
              path: 'monitor',
              element: <Monitor />,
            },
            {
              path: 'scenarioAnalyze',
              element: <ScenarioAnalyze page={'scenario'} />,
            },
          ],
        },
        {
          path: 'billing',
          element: <Billing />,
          children: [
            {
              path: '/billing',
              element: <Navigate to={'/billing/overview'} />,
              index: true,
            },
            {
              path: 'overview',
              element: <Overview />,
            },
            {
              path: 'deposit',
              element: <Deposit />,
            },
            {
              path: 'transaction',
              element: <Transaction />,
            },
          ],
        },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: 'scenario-flow',
    //   element: <ScenarioFlow />,
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: '*',
      element: (
        <GuestGuard>
          <SimpleLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
    { path: '*', element: <Page404 /> },
  ];

  // BUG: suspense không được gọi -> lazy load page hoặc set key cho suspense
  return <Suspense fallback={<LoadingScreen />}>{useRoutes(filterRoutes(routes, permissions))}</Suspense>;
}
