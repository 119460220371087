import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Grid, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import { useTranslation } from 'react-i18next';

import Row from './Row';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '10px',
    marginBottom: '12px',
    backgroundColor: 'rgba(120, 144, 156, 0.1)',
  },
  body: {
    width: '100%',
    maxHeight: 'calc(100vh - 505px)',
    minHeight: '425px',
    overflowY: 'auto',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    marginRight: '4px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      minWidth: '40px',
      height: '40px',
      padding: '0 10px',
      fontSize: '16px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
      },
    },
  },
}));

function ConversationTable({
  maxHeight = 'calc(100vh - 505px)',
  campaign,
  total,
  conversations,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleChangePage = (event, page) => {
  //   getReports(id, { page, limit: 20 });
  // };

  const headers = [
    {
      label: 'calledAt',
      canSort: true,
    },
    {
      label: 'conversationID',
      canSort: true,
      width: 3,
    },
    {
      label: 'call_plan_type',
      canSort: false,
      width: 1,
    },
    {
      label: 'number_id',
      canSort: false,
    },
    {
      label: 'customerPhone',
      canSort: false,
    },
    {
      label: 'duration',
      canSort: false,
      width: 1,
    },
    {
      label: 'status',
      canSort: false,
      width: 1,
    },
    {
      label: 'callPrice',
      canSort: false,
      width: 1,
    },
    {
      label: 'attempt',
      canSort: false,
      width: 1,
    },
    {
      label: '',
      canSort: false,
      width: 1,
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container direction="row" className={classes.header} alignItems="center">
        {headers.map((header, idx) => (
          <Grid
            item
            xs={header.width}
            key={header.label}
            style={{
              flex: '1',
              paddingLeft: `${idx === 0 ? '15px' : ''}`,
              paddingTop: '16px',
              paddingBottom: '16px',
              borderRadius: `${idx === 0 ? '10px 0 0 10px' : ''}`,
              justifyContent: `${idx === 0 ? 'flex-start' : 'center'}`,
              alignItems: 'center',
              display: 'flex',
              margin: '-7.5px 0',
              cursor: `${header.canSort ? 'pointer' : 'default'}`,
            }}
            sx={{
              color: (theme) => theme.palette.text.black,
            }}
          >
            {header.canSort ? (
              <div className={classes.sortContainer}>
                <Iconify
                  style={{ marginBottom: '-11.5px' }}
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-up-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
                <Iconify
                  width={22}
                  height={22}
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  className={classes.sortIcon}
                  color="#c6cacc"
                />
              </div>
            ) : null}
            {t(`table:${header.label}`)}
          </Grid>
        ))}
      </Grid>
      <div className={classes.body} style={{ maxHeight }}>
        {conversations.map((conversation) => (
          <Row key={campaign.id} {...campaign} {...conversation} />
        ))}
      </div>

      <Grid container direction={'row'} justifyContent="center" sx={{ marginTop: '15px' }}>
        <TablePagination
          className={classes.pagination}
          count={total}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          // boundaryCount={1}
          page={page}
          rowsPerPageOptions={[20, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <Pagination
          className={classes.pagination}
          count={numberOfPages}
          defaultPage={1}
          boundaryCount={1}
          onChange={handleChangePage}
        /> */}
      </Grid>
    </Box>
  );
}

ConversationTable.propTypes = {
  maxHeight: PropTypes.string,
  conversations: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  campaign: PropTypes.object,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
};

export default ConversationTable;
