import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Grid, Button, Tooltip, List, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify/Iconify';
import moment from 'moment';
import { findDifferentTime } from 'utils/findDifferentTime';
import { formatDateTime, convertToMinuteSeconds, fDate } from 'utils/formatTime';
import { formatNumberWithCommas } from 'utils/currency';
import { copyToClipboard } from 'utils/copyToClipboard';
import { capitalCase } from 'change-case';
import Toast from 'components/Toast';
import LogFile from '../LogFile';

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    minWidth: 'unset',
    position: 'relative',
  },
  listAction: {
    position: 'absolute',
    right: '0px',
    top: 'calc(100%)',
    width: '118px',
    border: '#e3e5e6 1px solid',
    margin: 0,
    display: 'inline-block',
    padding: '8px',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    listStyle: 'none',
  },
  listActionItem: {
    margin: 0,
    color: theme.palette.text.black,
    padding: '0 8px',
    minWidth: '64px',
    boxShadow: 'none',
    minHeight: '32px',
    transition: 'background-color 0.3s !important',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

const truncateMiddleText = (text) => `${text.slice(0, 8)}...${text.slice(-8)}`;

export const getStatusString = (status_code) => {
  switch (status_code) {
    case 103:
      return 'Missed Call';
    default:
      return 'Undefined Code';
  }
};

const Row = ({
  called_at,
  status,
  duration,
  customer,
  number,
  number_of_attempts,
  conversation_id,
  conversation_type,
  name,
  call_record_path,
  attempt_conversations,
  call_price,
  callcenter_phone,
  customer_phone,
  conversation_status_code,
  pickup_at,
  hangup_at,
  attempt_level,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [openLog, setOpenlog] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });

  const customerPhone = useMemo(() => customer.split('-')[0], [customer]);

  const reportRecordPath = useMemo(() => {
    return `${call_record_path}${fDate(called_at, 'yyyy/MM/dd')}/${customerPhone}.wav`;
  }, []);

  const callPrice = useMemo(() => (duration * 650) / 60, [duration]);

  const handleCopy = (text) => {
    copyToClipboard(text);
    setToast({ open: true, type: 'success', message: 'Coppied!' });
  };

  const handleCloseToast = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };

  const handleClose = () => setOpenlog(false);
  const handleOpen = () => setOpenlog(true);
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item className={classes.row} style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}>
          <div className={classes.content} style={{ paddingLeft: '15px', justifyContent: 'flex-start' }}>
            {formatDateTime(called_at)}
          </div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={3}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <Tooltip title={conversation_id} placement="top">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              className={classes.content}
              style={{ cursor: 'pointer' }}
              onClick={() => handleCopy(crypto.randomUUID())}
            >
              {truncateMiddleText(crypto.randomUUID())}
            </span>
          </Tooltip>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content}>
            {conversation_type === 1 ? (
              <Iconify icon="ic:baseline-phone-forwarded" widtg={24} height={24} color="#78909c" />
            ) : (
              <Iconify icon="icon-park-solid:phone-incoming-one" widtg={24} height={24} color="#78909c" />
            )}
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}>
          <div className={classes.content}>{number?.number || '-'}</div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}>
          <div className={classes.content}>{customerPhone}</div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content}>{convertToMinuteSeconds(duration)}</div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content}>{capitalCase(status)}</div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content}>{formatNumberWithCommas(Math.round(callPrice))}đ</div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content}>
            {number_of_attempts}
            {number_of_attempts !== 0 && (
              <Iconify
                icon="ic:outline-arrow-drop-down"
                color="#78909c"
                width={24}
                height={24}
                className={classes.icon}
                onClick={() => setExpanded((prev) => !prev)}
              />
            )}
          </div>
        </Grid>
        <Grid
          item
          className={classes.row}
          xs={1}
          style={{ borderBottom: `${expanded ? 'unset' : '#e3e5e6 1px solid'}` }}
        >
          <div className={classes.content} style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="View log info" placement="top">
              <Button className={classes.button} onClick={handleOpen}>
                <Iconify icon="mdi:message-text-clock-outline" width={'17.5px'} />
              </Button>
            </Tooltip>

            <Button className={classes.button} onClick={() => setOpenAction((prev) => !prev)}>
              <Iconify icon="ph:dots-three-outline-fill" width={'17.5px'} />

              {openAction ? (
                <List className={classes.listAction}>
                  <ListItemButton className={classes.listActionItem}>
                    <ListItemText>flowDetail</ListItemText>
                  </ListItemButton>
                </List>
              ) : null}
            </Button>
          </div>
        </Grid>
      </Grid>

      <Collapse in={expanded}>
        <Grid container direction={'row'} style={{ background: '#f1f4f573', padding: '10px' }}>
          <Grid container direction={'row'}>
            <Grid item className={classes.row}>
              <div className={classes.contentCollapse} style={{ paddingLeft: '15px', justifyContent: 'flex-start' }}>
                {/* {formatLocaleDateTime(attempt_call_at)} */}
              </div>
            </Grid>
            <Grid item className={classes.row} xs={3}>
              <Grid item className={classes.row} xs={3}>
                {/* <Tooltip title={attempt_conversation_id} placement="top">
                  <span className={classes.contentCollapse}>{truncateMiddleText(attempt_conversation_id)}</span>
                </Tooltip> */}
              </Grid>
            </Grid>
            <Grid item className={classes.row} xs={1}>
              <div className={classes.contentCollapse}>
                {/* {attempt_conversation_type === 1 ? (
                  <Iconify icon="ic:baseline-phone-forwarded" widtg={24} height={24} color="#78909c" />
                ) : (
                  <Iconify icon="icon-park-solid:phone-incoming-one" widtg={24} height={24} color="#78909c" />
                )} */}
              </div>
            </Grid>
            <Grid item className={classes.row}>
              {/* <div className={classes.contentCollapse}>{attempt_callcenter_phone}</div> */}
            </Grid>
            <Grid item className={classes.row}>
              {/* <div className={classes.contentCollapse}>{attempt_customer_phone}</div> */}
            </Grid>
            <Grid item className={classes.row} xs={1}>
              {/* <div className={classes.contentCollapse}>{findDifferentTime(attempt_hangup_at, attempt_pickup_at)}</div> */}
            </Grid>
            <Grid item className={classes.row} xs={1}>
              <div className={classes.contentCollapse}>
                {/* {attempt_call_price ? formatVndCurrency(attempt_call_price) : '-'} */}
              </div>
            </Grid>
            <Grid item className={classes.row} xs={1}>
              {/* <div className={classes.contentCollapse}>{attempt_attempt_level}</div> */}
            </Grid>
            <Grid item className={classes.row} xs={1}>
              {' '}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>

      <LogFile
        open={openLog}
        onClose={handleClose}
        called_at={called_at}
        status={status}
        duration={duration}
        customerPhone={customerPhone}
        number={number}
        number_of_attempts={number_of_attempts}
        campaignName={name}
        reportRecordPath={reportRecordPath}
      />

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
};

Row.propTypes = {
  called_at: PropTypes.number,
  attempt_conversations: PropTypes.object,
  call_price: PropTypes.number,
  conversation_id: PropTypes.string,
  callcenter_phone: PropTypes.string,
  conversation_type: PropTypes.number,
  customer_phone: PropTypes.string,
  conversation_status: PropTypes.number,
  conversation_status_code: PropTypes.number,
  pickup_at: PropTypes.number,
  hangup_at: PropTypes.number,
  number_of_attempts: PropTypes.number,
  duration: PropTypes.number,
  number: PropTypes.object,
  customer: PropTypes.string,
  status: PropTypes.string,
};

export default Row;
