import { useState } from 'react';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import useAuth from 'hooks/useAuth';
import Iconify from '../../../components/iconify';
import Toast from '../../../components/Toast';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });

  const handleClose = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };

  const handleLogin = async () => {
    try {
      await login({ user_name: username, password });
    } catch (error) {
      setToast({ open: true, type: 'error', message: error.message });
    }
  };

  const handleInputUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <Stack spacing={3} mb={5}>
        <TextField
          label="Username"
          id="username"
          key="username"
          name="username"
          onChange={handleInputUsername}
          autoComplete={'username'}
          fullWidth
        />

        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          id="password"
          key="password"
          name="password"
          onChange={handleInputChange}
          fullWidth
          autoComplete={'current-password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>

      <Toast openToast={toast.open} handleClose={handleClose} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
