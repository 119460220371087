export const ccuDashboard = {
  "msg": "Success",
  "status": 0,
  "vertical_points_list": [
      {
          "timepoint": 1680800400000,
          "value": 0
      },
      {
          "timepoint": 1680886800000,
          "value": 41439
      },
      {
          "timepoint": 1680973200000,
          "value": 38381
      },
      {
          "timepoint": 1681059600000,
          "value": 41325
      },
      {
          "timepoint": 1681146000000,
          "value": 40572
      },
      {
          "timepoint": 1681232400000,
          "value": 48320
      },
      {
          "timepoint": 1681318800000,
          "value": 34209
      },
      {
          "timepoint": 1681405200000,
          "value": 46556
      },
      {
          "timepoint": 1681491600000,
          "value": 44468
      },
      {
          "timepoint": 1681578000000,
          "value": 47637
      },
      {
          "timepoint": 1681664400000,
          "value": 38301
      },
      {
          "timepoint": 1681750800000,
          "value": 39874
      },
      {
          "timepoint": 1681837200000,
          "value": 33029
      },
      {
          "timepoint": 1681923600000,
          "value": 55442
      },
      {
          "timepoint": 1682010000000,
          "value": 0
      },
      {
          "timepoint": 1682096400000,
          "value": 0
      },
      {
          "timepoint": 1682182800000,
          "value": 0
      },
      {
          "timepoint": 1682269200000,
          "value": 0
      },
      {
          "timepoint": 1682355600000,
          "value": 0
      },
      {
          "timepoint": 1682442000000,
          "value": 0
      },
      {
          "timepoint": 1682528400000,
          "value": 0
      },
      {
          "timepoint": 1682614800000,
          "value": 0
      },
      {
          "timepoint": 1682701200000,
          "value": 3
      },
      {
          "timepoint": 1682787600000,
          "value": 0
      },
      {
          "timepoint": 1682874000000,
          "value": 0
      },
      {
          "timepoint": 1682960400000,
          "value": 0
      },
      {
          "timepoint": 1683046800000,
          "value": 0
      },
      {
          "timepoint": 1683133200000,
          "value": 0
      },
      {
          "timepoint": 1683219600000,
          "value": 0
      },
      {
          "timepoint": 1683306000000,
          "value": 0
      },
      {
          "timepoint": 1683392400000,
          "value": 0
      }
  ]
}

export const ccuDashboardByScenario = {
  "msg": "Success",
  "scenarios": [
      {
          "scenario_id": 1683,
          "scenario_name": "[P] Khóa Học - V2",
          "scenario_type": 2
      },
      {
          "scenario_id": 1833,
          "scenario_name": "[P] EBOOK",
          "scenario_type": 2
      },
      {
          "scenario_id": 2079,
          "scenario_name": "[P] Khóa Học - V3",
          "scenario_type": 2
      }
  ],
  "status": 0,
  "vertical_points_list": [
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1680800400000
      },
      {
          "1683": 16794,
          "1833": 0,
          "2079": 24645,
          "timepoint": 1680886800000
      },
      {
          "1683": 10881,
          "1833": 0,
          "2079": 27500,
          "timepoint": 1680973200000
      },
      {
          "1683": 15855,
          "1833": 0,
          "2079": 25470,
          "timepoint": 1681059600000
      },
      {
          "1683": 13166,
          "1833": 0,
          "2079": 27406,
          "timepoint": 1681146000000
      },
      {
          "1683": 48320,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681232400000
      },
      {
          "1683": 34209,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681318800000
      },
      {
          "1683": 46556,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681405200000
      },
      {
          "1683": 44468,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681491600000
      },
      {
          "1683": 47637,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681578000000
      },
      {
          "1683": 38301,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681664400000
      },
      {
          "1683": 39874,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681750800000
      },
      {
          "1683": 33029,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681837200000
      },
      {
          "1683": 55442,
          "1833": 0,
          "2079": 0,
          "timepoint": 1681923600000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682010000000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682096400000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682182800000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682269200000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682355600000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682442000000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682528400000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682614800000
      },
      {
          "1683": 0,
          "1833": 3,
          "2079": 0,
          "timepoint": 1682701200000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682787600000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682874000000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1682960400000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1683046800000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1683133200000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1683219600000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1683306000000
      },
      {
          "1683": 0,
          "1833": 0,
          "2079": 0,
          "timepoint": 1683392400000
      }
  ]
}

export const ccuDashboardByConversation = {
    "conversation_types": [
        {
            "conversation_describe": "Cuộc gọi inbound",
            "conversation_type": 0
        },
        {
            "conversation_describe": "Cuộc gọi outbound",
            "conversation_type": 1
        }
    ],
    "msg": "Success",
    "status": 0,
    "vertical_points_list": [
        {
            "0": 0,
            "1": 0,
            "timepoint": 1680800400000
        },
        {
            "0": 0,
            "1": 41439,
            "timepoint": 1680886800000
        },
        {
            "0": 0,
            "1": 38381,
            "timepoint": 1680973200000
        },
        {
            "0": 0,
            "1": 41325,
            "timepoint": 1681059600000
        },
        {
            "0": 0,
            "1": 40572,
            "timepoint": 1681146000000
        },
        {
            "0": 0,
            "1": 48320,
            "timepoint": 1681232400000
        },
        {
            "0": 0,
            "1": 34209,
            "timepoint": 1681318800000
        },
        {
            "0": 0,
            "1": 46556,
            "timepoint": 1681405200000
        },
        {
            "0": 0,
            "1": 44468,
            "timepoint": 1681491600000
        },
        {
            "0": 0,
            "1": 47637,
            "timepoint": 1681578000000
        },
        {
            "0": 0,
            "1": 38301,
            "timepoint": 1681664400000
        },
        {
            "0": 0,
            "1": 39874,
            "timepoint": 1681750800000
        },
        {
            "0": 0,
            "1": 33029,
            "timepoint": 1681837200000
        },
        {
            "0": 0,
            "1": 55442,
            "timepoint": 1681923600000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682010000000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682096400000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682182800000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682269200000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682355600000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682442000000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682528400000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682614800000
        },
        {
            "0": 0,
            "1": 3,
            "timepoint": 1682701200000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682787600000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682874000000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1682960400000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1683046800000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1683133200000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1683219600000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1683306000000
        },
        {
            "0": 0,
            "1": 0,
            "timepoint": 1683392400000
        }
    ]
}
export const ccuDashboardByConversationStatus = {
  "conversation_status_code": [
      {
          "conversation_status_code": 0,
          "conversation_status_name": "INIT"
      },
      {
          "conversation_status_code": 1,
          "conversation_status_name": "PREPARE"
      },
      {
          "conversation_status_code": 2,
          "conversation_status_name": "CALLING"
      },
      {
          "conversation_status_code": 3,
          "conversation_status_name": "TIMEOUT"
      },
      {
          "conversation_status_code": 100,
          "conversation_status_name": "END CALL"
      },
      {
          "conversation_status_code": 101,
          "conversation_status_name": "HANGUP"
      },
      {
          "conversation_status_code": 102,
          "conversation_status_name": "FORWARD"
      },
      {
          "conversation_status_code": 103,
          "conversation_status_name": "MISSCALL"
      },
      {
          "conversation_status_code": 104,
          "conversation_status_name": "CANNOT CALL"
      },
      {
          "conversation_status_code": 105,
          "conversation_status_name": "SYSTEM ERROR"
      }
  ],
  "msg": "Success",
  "status": 0,
  "vertical_points_list": [
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1682787600000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1682874000000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1682960400000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1683046800000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1683133200000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1683219600000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1683306000000
      },
      {
          "0": 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "100": 0,
          "101": 0,
          "102": 0,
          "103": 0,
          "104": 0,
          "105": 0,
          "timepoint": 1683392400000
      }
  ]
}