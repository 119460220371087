import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { Grid, Stack, Button, Popover, MenuItem } from '@mui/material';
// hooks
import useRole from 'hooks/useRole';
import usePermission from 'hooks/usePermission';
import { useStyles } from 'hooks/useStyles';
import useToast from 'hooks/useToast';

import { PAGINATION } from 'constants';
// components
import SearchBar from 'components/Search';
import Toast from 'components/Toast';
import Table from 'components/Table';
import Iconify from '../../components/iconify';
import RoleModal from './RoleModal';

// mock
import { DIALOG_STATUS } from '../../constants/dialog';

export default function UserPage() {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { roles, total, get: getRoles } = useRole();
  const { permissions, get: getPermissions } = usePermission();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const [selectedRow, setSelectedRow] = useState(null);
  const { toast, handleSetToast, handleCloseToast } = useToast();
  const headers = [
    {
      label: t('table:id'),
      canSort: true,
      width: 1,
    },
    {
      label: t('table:roleName'),
      canSort: true,
    },
    {
      label: t('table:roleDescription'),
      canSort: true,
    },
    {
      label: '',
      canSort: false,
    },
  ];

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) setSelectedRow(null);
    handleCloseMenu();
    setDialogStatus(status);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenMenu = (event, row) => {
    setSelectedRow(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    const handleGetRoles = async () => {
      await getRoles({ page, limit: PAGINATION.LIMIT, keyword: filter });
    };

    try {
      handleGetRoles();
    } catch (err) {
      console.log(err);
    }
  }, [page, filter]);

  useEffect(() => {
    const handleGetPermissions = async () => {
      await getPermissions();
    };
    try {
      handleGetPermissions();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('role')} </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchBar setFilter={setFilter} />
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
          onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
        >
          {t('table:add')}
        </Button>
      </Stack>

      <Table total={total} get={getRoles} headers={headers}>
        {roles.map((role) => (
          <Grid
            key={role.id}
            container
            direction={'row'}
            alignContent={'center'}
            style={{ borderBottom: '#e3e5e6 1px solid' }}
          >
            <Grid item className={classes.row} xs={1}>
              <div className={classes.content}>{role?.id}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{role?.name}</div>
            </Grid>
            <Grid item className={classes.row}>
              <div className={classes.content}>{role?.description}</div>
            </Grid>
            <Grid item className={classes.row} sx={{ width: '30px', flex: 'unset !important' }}>
              <Button className={classes.button} onClick={(event) => handleOpenMenu(event, role)}>
                <Iconify icon="ph:dots-three-outline-fill" width={15} height={15} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </Table>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.UPDATE)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:update')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.DELETE)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      {openModal && (
        <RoleModal
          dialogStatus={dialogStatus}
          openModal={openModal}
          values={selectedRow}
          handleCloseModal={handleCloseModal}
          handleSetToast={handleSetToast}
          availableData={{ permissions }}
        />
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
