import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
// @mui
import { Button, Box, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

function Summary({ data, setData, handleNext, handleBack, campaigns }) {
  const { t } = useTranslation('common');
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const summarySchema = yup
    .object({
      name: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      campaign_id: '',
      note: '',
      ...data,
    },
    resolver: yupResolver(summarySchema),
  });

  const onSubmit = (data) => {
    setData((prev) => ({ ...prev, ...data }));
    setIsDataUpdated(true);
  };

  useEffect(() => {
    if (isDataUpdated) {
      handleNext();
      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px' }}>
        <form
          style={{
            maxWidth: '400px',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                size="small"
                helperText={errors?.name?.message}
                margin="dense"
                label={t('table:name')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Controller
            name="campaign_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                value={campaigns.find((campaign) => campaign.id === Number(field.value)) || null}
                onChange={(event, newValue) => field.onChange(newValue?.id || null)}
                onBlur={field.onBlur}
                size="small"
                error={!!errors.campaign_id}
                helperText={errors?.campaign_id?.message}
                margin="dense"
                label={t('campaign')}
                options={campaigns}
                getOptionLabel={(campaign) => campaign.name}
                renderInput={(params) => <TextField {...params} label={t('campaign')} variant="outlined" fullWidth />}
              />
            )}
          />
          <Controller
            name="note"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                error={!!errors.note}
                helperText={errors?.note?.message}
                margin="dense"
                label={t('table:note')}
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={3}
              />
            )}
          />
        </form>
      </Box>

      <Box
        sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', textAlign: 'right' }}
      >
        <Button
          sx={{
            ':hover': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#d5d5d5',
            },
            backgroundColor: '#e0e0e0',
            marginRight: '10px',
            color: 'rgba(0, 0, 0, 0.87);',
          }}
          variant="contained"
          onClick={handleBack}
        >
          {t('table:cancel')}
        </Button>
        <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
          {t('table:update')}
        </Button>
      </Box>
    </>
  );
}

export default Summary;

Summary.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  setData: PropTypes.func,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
