import React from 'react';
import { Link } from 'react-router-dom';
import { Tab } from '@mui/material';

const LinkTab = (props) => (
  <Tab
    component={Link}
    // eslint-disable-next-line react/prop-types
    to={props.pathname}
    {...props}
    sx={{ fontSize: 16 }}
  />
);

export default LinkTab;
