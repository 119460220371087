import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import useRole from 'hooks/useRole';
import { useMemo, useState, useEffect } from 'react';
// @mui
import {
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Stack,
  Divider,
} from '@mui/material';

import { DIALOG_STATUS } from '../../../constants/dialog';

export default function RoleModal({
  dialogStatus,
  values,
  openModal,
  handleCloseModal,
  handleSetToast,
  availableData,
}) {
  const { t } = useTranslation('common');
  const { create, update, destroy } = useRole();
  const [checkAllPermissions, setCheckAllPermissions] = useState(false);
  const permissions = useMemo(() => availableData.permissions, [availableData.permissions]);
  const schema = yup
    .object({
      name: yup.string().required(t('error:roleNameRequired')),
    })
    .required();

  const handleCheckAllPermissions = (event) => {
    const isChecked = event.target.checked;
    setCheckAllPermissions(isChecked);
    const updatedValue = isChecked ? permissions.map((permission) => permission.name) : [];
    setValue('permissions', updatedValue);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: { id: '', name: '', description: '', permissions: [] },
    values,
    resolver: yupResolver(schema),
  });

  const generateSuccessToast = (message) => {
    handleSetToast({ open: true, type: 'success', message });
  };

  const onSubmit = (data) => {
    if (dialogStatus === DIALOG_STATUS.CREATE) {
      create(data);
      generateSuccessToast(t('notify:addSuccess'));
    } else if (dialogStatus === DIALOG_STATUS.UPDATE) {
      update(data.id, data);
      generateSuccessToast(t('notify:updateSuccess'));
    } else {
      destroy(data.id);
      generateSuccessToast(t('notify:deletedSuccess'));
    }
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
    reset({ id: '', name: '', description: '', permissions: [] });
  };

  useEffect(() => {
    const isCheckAll = permissions.every((permission) => getValues('permissions').includes(permission.name));
    setCheckAllPermissions(isCheckAll);
  }, [permissions, getValues]);

  return (
    <Dialog open={openModal} onClose={onClose} maxWidth="md">
      <DialogTitle>
        {dialogStatus === DIALOG_STATUS.CREATE && t('table:createRole')}
        {dialogStatus === DIALOG_STATUS.UPDATE && t('table:updateRole')}
        {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteRole')}
      </DialogTitle>
      {dialogStatus !== DIALOG_STATUS.DELETE && (
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  margin="dense"
                  label={t('table:roleName')}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  margin="dense"
                  label={t('table:description')}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Paper variant="outlined" sx={{ marginBlock: '8px 4px' }}>
              <FormControlLabel
                sx={{ padding: '0 14px' }}
                label={t('table:checkAll')}
                control={<Checkbox checked={checkAllPermissions} onChange={handleCheckAllPermissions} />}
              />
              <Divider />
              <Controller
                name="permissions"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormGroup>
                    <Stack direction="row" flexWrap={'wrap'} sx={{ padding: '0 14px' }}>
                      {permissions.map((permission) => (
                        <FormControlLabel
                          key={permission.id}
                          control={
                            <Checkbox
                              {...field}
                              value={permission.name}
                              onChange={(e) => {
                                const value = e.target.value;
                                const isChecked = e.target.checked;
                                const updatedValue = isChecked
                                  ? [...field.value, value]
                                  : field.value.filter((val) => val !== value);
                                field.onChange(updatedValue);
                                const isCheckAll = permissions.every((permission) =>
                                  updatedValue.includes(permission.name)
                                );
                                setCheckAllPermissions(isCheckAll);
                              }}
                            />
                          }
                          label={permission.name}
                          value={permission.id}
                          checked={field.value.includes(permission.name)}
                        />
                      ))}
                    </Stack>
                  </FormGroup>
                )}
              />
            </Paper>
          </form>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>{t('table:cancel')}</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {dialogStatus === DIALOG_STATUS.DELETE
            ? t('table:delete')
            : dialogStatus === DIALOG_STATUS.UPDATE
            ? t('table:update')
            : t('table:add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RoleModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleSetToast: PropTypes.func,
  availableData: PropTypes.object,
};
