import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { scenarios } from '_mock/scenario';

import { makeStyles } from '@mui/styles';
import DateTimeRange from 'components/DateTimeRange';
import { useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import useCommon from 'hooks/useCommon';
import { conversations } from '_mock/conversation';
import Iconify from 'components/iconify/Iconify';
import ConversationNotfound from '../../../../assests/images/conversation_notfound.svg';
import FilterSection from '../CampaignReport/FilterSection';
// import Statistics from '../CampaignReport/Statistics';
import { allAttempList, allVersionList } from '../CampaignReport/FilterSection/data';
import ConversationTable from '../ConversationTable';
import PlaySwitch from './PlaySwitch';

const filterList = [
  {
    icon: 'mdi:speak',
    label: 'Campaign',
    filterLabel: 'All Campaign',
    value: 'attempResult',
    menuList: allAttempList,
  },
  {
    icon: 'material-symbols:check-circle-outline-rounded',
    label: 'Attempt result',
    filterLabel: 'All Attempt results',
    value: 'attempResult',
    menuList: allAttempList,
  },
  {
    icon: 'mingcute:version-fill',
    label: 'Version',
    filterLabel: 'All Versions',
    value: 'version',
    menuList: allVersionList,
  },
  {
    icon: 'streamline:interface-login-dial-pad-1-dial-pad-dot',
    label: 'Number',
    filterLabel: 'All Numbers',
    value: 'number',
  },
  {
    icon: 'bxs:conversation',
    label: 'Conversation',
    filterLabel: 'All Conversations',
    value: 'conversation',
  },
  {
    icon: 'ic:baseline-phone',
    label: 'Call type',
    filterLabel: 'All Attempt results',
    value: 'attempResult',
    menuList: allAttempList,
  },
];

const useStyles = makeStyles((theme) => ({
  timeRange: {
    border: '1px solid #c6cacc',
    borderRadius: '30px',
    padding: '6px 10px',
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#78909c',
    },
  },
  pr10: {
    paddingRight: '10px',
  },
  px10: {
    padding: '0 10px',
  },
  datePicker: {
    position: 'absolute',
    top: '40px',
    background: '#fff',
    boxShadow: '2px 5px 6px 3px #bdbdbd78',
    borderRadius: '8px',
  },
  buttonGroup: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #d8d8d8',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '& .MuiChip-avatar': {
      width: '20px',
      height: '20px',
      color: '#78909c',
    },

    '& .MuiSvgIcon-root': {
      display: 'none',
      color: '#78909c',
      position: 'absolute',
      right: '0',
      zIndex: '10',
      width: '15px',
      height: '15px',
      background: 'linear-gradient(270deg, #f1f1f1 71.88%, rgba(247, 249, 250, 0))',
    },
  },
  filterItem: {
    fontSize: '14px',
    color: '#78909c',
    cursor: 'pointer',
    position: 'relative',
    marginRight: '16px',
    '&:hover': {
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
  },
  itemWrapper: {
    position: 'relative',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  exportButton: {
    color: '#fff',
    background: theme.palette.primary.main,
    height: '32px',
    '&:hover': {
      opacity: '0.8',
      color: '#fff',
      background: theme.palette.primary.main,
    },
  },
}));

const ScenarioHistory = ({ page }) => {
  const { setParentPage, setChildName } = useCommon();
  const classes = useStyles();
  const { id } = useParams();
  const [scenario, setScenario] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [selectDateMode, setSelectDateMode] = useState('date');
  const [openSelectFilter, setOpenSelectFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState([]);
  const [status, toggleStatus] = useState('stopped');

  useEffect(() => {
    if (id) {
      const c = scenarios.scenarios.find((el) => el.scenario_id.toString() === id);
      setScenario(c);
    }
  }, [id]);

  useEffect(() => {
    setParentPage(page);
    if (scenario) {
      setChildName(scenario.scenario_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  const handleSelectFilter = (value) => {
    setSelectedFilter((prev) => [...prev, value]);
  };

  const handleDeleteFilter = (filter) => () => {
    setSelectedFilter((data) => data.filter((chip) => chip.value !== filter.value));
  };

  const handleClearFilter = () => {
    setSelectedFilter([]);
    setOpenSelectFilter(false);
  };

  const handleChangeStatus = () => {
    toggleStatus(status === 'stopped' ? 'playing' : 'stopped');
  }

  if (!scenario) return <div>Loading</div>;

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container direction="row" xs={10}>
          <DateTimeRange
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />

          <PlaySwitch status={status} handleChangeStatus={handleChangeStatus} />

          <FilterSection
            classes={classes}
            filterList={filterList}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            handleDeleteFilter={handleDeleteFilter}
            openSelectFilter={openSelectFilter}
            setOpenSelectFilter={setOpenSelectFilter}
            handleSelectFilter={handleSelectFilter}
            handleClearFilter={handleClearFilter}
          />
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <Grid item xs={12}>
            <div className={classes.exportButtons}>
              <Button className={classes.exportButton}>
                <Iconify icon="carbon:play-filled" color="#fff" sx={{ marginRight: '5px' }} />
                Call Test
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* <Grid container item xs={12}>
          <Statistics call_information={scenario.call_information} />
        </Grid> */}
        <Grid container item xs={12} sx={{ padding: '10px' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {conversations.length === 0 ? (
              <NotFound title="You don't have any Conversation yet!" image={ConversationNotfound} />
            ) : (
              <ConversationTable maxHeight='calc(100vh - 370px)' />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ScenarioHistory.propTypes = {
  page: PropTypes.string,
}

export default ScenarioHistory;
