import { filter } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { Box, Tab, Tabs, Typography } from '@mui/material';
// mock
import NumberList from './ManageNumbers/NumberList';

// ----------------------------------------------------------------------

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function NumbersPage() {
  const { t } = useTranslation('common');
  const [value, setValue] = useState(0);
  const tabs = [
    {
      idx: 0,
      pathname: '',
      label: t('manageNumbers'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
            aria-label="primary tabs example"
            TabIndicatorProps={{ style: { height: '3px' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.pathname}
                component={Typography}
                label={tab.label}
                {...a11yProps(tab.idx)}
                sx={{ fontSize: 16 }}
              />
            ))}
          </Tabs>
        </Box>

        {/* Render panel to show component */}
        <TabPanel value={value} index={0}>
          {/* <ScenarioList /> */}
          <NumberList hasFilterOption />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <></>
        </TabPanel>
      </>
    </>
  );
}
