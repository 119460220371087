/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// @mui
import { Button, Box, Typography, Checkbox, Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from 'components/iconify';
import excelExport from 'utils/excelExport';
import { getUniqueIndexes } from 'utils/array';
import ColumnVariable from './components/ColumnVariable';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: '#78909c',
    fontSize: '14px',
    textAlign: 'center',
    marginLeft: '5px',
  },
}));

const isObjectValueEmpty = (obj) => {
  return Object.values(obj).every((value) => value === '');
};

const removeEmpty = (rows) => {
  return rows.filter((row) => !isObjectValueEmpty(row));
};

const handleRemoveDuplicate = (mappedArr) => {
  if (!mappedArr) return [];
  const uniqueIdxs = getUniqueIndexes(mappedArr?.[0]);

  const newArr = mappedArr.map((el) => {
    const filtered = el.map((item, idx) => {
      if (uniqueIdxs.includes(idx)) {
        return item;
      }
      return null;
    });

    return filtered.map((m) => m ?? '');
  });

  return newArr;
};

const KEEP_COLUMNS = ['', 'phone', 'name'];
function MapFile({
  data,
  setData,
  handleNext,
  handleBack,
  mappedArr,
  setMapptedArr,
  activeCols,
  setActiveCols,
  mapContent,
  setMapContent,
}) {
  const { t } = useTranslation('common');
  const [showExclude, setShowExclue] = useState(true);
  const [autoFill, setAutoFill] = useState(false);
  const [typeContact, setTypeContact] = useState(data.contact_type);
  const classes = useStyles();

  useEffect(() => {
    if (!mappedArr || !mappedArr?.[0]) return;
    const handleAutoFill = () => {
      const autoMapContent = mappedArr[0].slice(0, 2).map((el, idx) => ({
        column: el,
        key: KEEP_COLUMNS[idx + 1],
        value: mappedArr[1][idx],
      }));

      setMapContent(autoMapContent);
    };

    if (autoFill) {
      handleAutoFill();
    } else {
      const initialMapContent = mappedArr[0].map((el, idx) => ({
        column: el,
        key: null,
        value: mappedArr[1][idx],
      }));

      setMapContent(initialMapContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFill, mappedArr]);

  useEffect(() => {
    const handleExtractData = async (file) => {
      try {
        const rows = await excelExport(file);
        const filteredRows = handleRemoveDuplicate(removeEmpty(rows));

        setMapptedArr(filteredRows);
        setActiveCols(filteredRows[0]);
      } catch (error) {
        console.error(error);
        throw new Error('extract error');
      }
    };
    if (data) {
      handleExtractData(data.contact_detail_file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const planStepSchema = yup
    .object({
      call_plan_type: yup.string().required(t('error:required')),
      call_rule: yup.string().required(t('error:required')),
      call_center: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(planStepSchema),
  });

  const handleSwitchInclude = useCallback(
    (includes, header) => {
      if (includes) {
        setActiveCols((prev) => prev.filter((el) => el !== header));
      } else {
        setActiveCols((prev) => prev.concat([header]));
      }
    },
    [setActiveCols]
  );

  const handleMapValue = (column, key) => {
    if (!mapContent || !mapContent?.map) return;
    const newMapContent = mapContent.map((content) => {
      if (content.column === column) {
        return {
          ...content,
          key,
        };
      }
      return content;
    });

    setMapContent(newMapContent);
  };

  const currentColumns = useMemo(() => {
    if (!mappedArr) return [];
    if (showExclude) return mappedArr?.[0];
    return activeCols;
  }, [activeCols, mappedArr, showExclude]);

  const isFullyMapped = useMemo(() => {
    if (mapContent.find((el) => activeCols.includes(el.column) && !el.key)) return false;
    const notMapped = activeCols.find((col) => !mapContent.find((content) => content.column === col));
    if (notMapped) return false;

    return true;
  }, [mapContent, activeCols]);

  const handleChangeTypeContact = (e) => {
    setTypeContact(e.target.value);
    setData('contact_type', e.target.value);
  };

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', width: '800px' }}>
        <Typography variant="h6" color={'#3e4345'} align="center" marginY={2}>
          Map variables
        </Typography>
        <div className={classes.label}>
          Map Variables from the scenario with the columns from the uploaded Excel file.
        </div>

        <div style={{ display: 'flex', overflowX: 'scroll' }}>
          {currentColumns?.map((el, idx) => (
            <ColumnVariable
              key={el}
              control={control}
              header={el}
              value={mappedArr[1][idx]}
              columns={KEEP_COLUMNS}
              includes={activeCols && activeCols.includes(el)}
              switchInclude={handleSwitchInclude}
              mappedValue={mapContent.find((content) => content.column === el)}
              handleMapValue={handleMapValue}
            />
          ))}
        </div>

        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '10px' }}>
          {isFullyMapped ? (
            <>
              <Iconify icon="material-symbols:check-circle-rounded" color="#7acc90" />
              <Typography className={classes.label}>Everything is mapped</Typography>
            </>
          ) : (
            <>
              <Iconify icon="jam:triangle-danger-f" color="#f3aa18" />
              <Typography className={classes.label}>Match the column that contains the phone number</Typography>
            </>
          )}
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '10px' }}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
              <Checkbox
                color="default"
                id="confirm-policy"
                checked={showExclude}
                onChange={(e) => {
                  setShowExclue(e.target.checked);
                }}
              />
              <label
                htmlFor="confirm-policy"
                style={{ color: '#78909c', width: '580px', display: 'flex', fontSize: '13px' }}
              >
                Show excluded columns
              </label>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
              <Checkbox
                color="default"
                id="confirm-policy"
                checked={autoFill}
                onChange={(e) => {
                  setAutoFill(e.target.checked);
                }}
              />
              <label
                htmlFor="confirm-policy"
                style={{ color: '#78909c', width: '580px', display: 'flex', fontSize: '13px' }}
              >
                Auto Fill Column
              </label>
            </div>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Box>

      <Box
        sx={{
          padding: '10px',
          background: '#f6f9fa',
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container direction={'row'} spacing={1} justifyContent="space-between">
          <Grid item container xs={8}>
            <Grid item xs={12}>
              <RadioGroup row value={typeContact} onChange={handleChangeTypeContact} sx={{ paddingLeft: '9px' }}>
                <FormControlLabel value="0" control={<Radio size="small" />} label="All" />
                <FormControlLabel value="1" control={<Radio size="small" />} label="Only Viettel" />
                <FormControlLabel value="2" control={<Radio size="small" />} label="Not Viettel" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item container direction="row" xs={4} justifyContent="flex-end" alignItems={'center'}>
            <Button
              sx={{
                ':hover': {
                  boxShadow:
                    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                  backgroundColor: '#d5d5d5',
                },
                backgroundColor: '#e0e0e0',
                marginRight: '10px',
                color: 'rgba(0, 0, 0, 0.87);',
              }}
              variant="contained"
              onClick={handleBack}
            >
              {t('back')}
            </Button>
            <Button disabled={!isFullyMapped} variant="contained" type="submit" onClick={handleNext}>
              {t('next')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MapFile;

MapFile.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  mappedArr: PropTypes.array,
  setMapptedArr: PropTypes.func,
  activeCols: PropTypes.array,
  setActiveCols: PropTypes.func,
  mapContent: PropTypes.array,
  setMapContent: PropTypes.func,
};
