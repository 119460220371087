/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '280px',
    padding: '10px',
    display: 'flex',
  },
  column: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'scroll',

    '&::-webkit-scrollbar-thumb': {
      display: 'none',
      background: '#dcdcdc',
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-track': {
      display: 'none',
      background: '#c6cacc',
      borderRadius: '10px',
    },
    
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        display: 'unset',
      },
  
      '&::-webkit-scrollbar-track': {
        display: 'unset',
      },
    }
  },
  list: {
    listStyle: 'none',
    width: '75px',
    margin: '0px',
    padding: '0px',
  },
  item: {
    height: '40px',
    textAlign: 'center',
    lineHeight: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: '#78909c1a !important',
    },
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
}));

const hours = Array.from(Array(24).keys());
const minutes = Array.from(Array(60).keys());

const TimePicker = ({ pickedTime, handleChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div style={{ height: '100%', display: 'flex' }}>
        <div>
          <div className={classes.column}>
            <ul className={classes.list}>
              {hours.map((el) => (
                <li
                  style={{ background: `${pickedTime?.getHours() === el ? '#78909c1a' : '#fff'}` }}
                  className={classes.item}
                  key={`time-${el}`}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role={'button'}
                  onClick={() => {
                    handleChange({
                      h: el,
                      m: pickedTime?.getMinutes(),
                    })
                  }}
                >
                  {el}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <span style={{ width: '1px', background: '#dcdcdc' }} />
      <div style={{ height: '100%', display: 'flex' }}>
        <div>
          <div className={classes.column}>
            <ul className={classes.list}>
              {minutes.map((el) => (
                <li
                  style={{ background: `${pickedTime?.getMinutes() === el ? '#78909c1a' : '#fff'}` }}
                  className={classes.item}
                  key={`time-${el}`}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role={'button'}
                  onClick={() => {
                    handleChange({
                      h: pickedTime?.getHours(),
                      m: el,
                    })
                  }}
                >
                  {el}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  pickedTime: PropTypes.object,
  handleChange: PropTypes.func,
};

export default TimePicker;
