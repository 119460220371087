import { createContext, useReducer } from 'react';
import contactGroupService from 'services/contact-group.service';
import PropTypes from 'prop-types';
import { replaceItemInArrayByKey } from 'utils/array';

const initialAppState = {
  contacts: [],
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CONTACT_GROUPS': {
      const { contacts, total } = action.payload;
      return { ...state, contacts, total };
    }
    case 'CREATE_CONTACT_GROUPS': {
      const { contact } = action.payload;
      return { ...state, contacts: [contact].concat(state.contacts), total: state.total + 1 };
    }
    case 'UPDATE_CONTACT_GROUPS': {
      const { contact } = action.payload;
      const updatedRoles = replaceItemInArrayByKey(state.contacts, 'id', contact);
      return { ...state, contacts: updatedRoles };
    }
    case 'DELETE_CONTACT_GROUPS': {
      const { id } = action.payload;
      return { ...state, contacts: state.contacts.filter((contact) => contact.id !== id), total: state.total - 1 };
    }

    default:
      return { ...state };
  }
};

const ContactGroupContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const ContactGroupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const { data: contacts, meta } = await contactGroupService.get(params);
      dispatch({
        type: 'SET_CONTACT_GROUPS',
        payload: {
          contacts,
          total: meta?.total,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const { data: contact } = await contactGroupService.create(data);
      dispatch({
        type: 'CREATE_CONTACT_GROUPS',
        payload: {
          contact,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const { data: contact } = await contactGroupService.update(id, data);
      dispatch({
        type: 'UPDATE_CONTACT_GROUPS',
        payload: {
          contact,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await contactGroupService.delete(id);
      dispatch({
        type: 'DELETE_CONTACT_GROUPS',
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ContactGroupContext.Provider value={{ ...state, method: 'ContactGroup', get, create, update, destroy }}>
      {children}
    </ContactGroupContext.Provider>
  );
};

ContactGroupProvider.propTypes = {
  children: PropTypes.node,
};

export default ContactGroupContext;
