import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import useScenario from 'hooks/useScenario';

// @mui
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { blackList } from '_mock/blackList';
import HelpText from '../HelpText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(() => ({
  selectChips: {
    width: '100%',
    '& .MuiSelect-select': {
      backgroundColor: '#fff',
    },
  },
  icon: {
    color: '#78909c',
    width: 15,
    height: 15,
    paddingLeft: 'unset',
    cursor: 'pointer',
  },
}));

const textFileSxProps = { input: { background: '#fff' } };
const formControlSxProps = { marginTop: 1, marginBottom: 0.5, '& .MuiInputBase-root': { background: '#fff' } };

const textFiels = [
  {
    name: 'name',
    help: 'Campaign name. Latin and Cyrillic characters, as well as numbers and spaces are allowed.',
  },
  {
    name: 'number_phone_lines',
    type: 'number',
    help: 'Phone lines specify the number of calls that the system will make simultaneously. As soon as the call on one phone line is finished (for any reason: the call is declined, finished, etc), the system will call the next phone number on the list.',
  },
  {
    name: 'blacklist_ids',
    type: 'tags',
    menuItems: blackList,
    help: 'blacklistsHint',
  },
  {
    name: 'callback_url',
    help: 'callbackUrlDescription',
  },
];

function CallStragety({ data, setData, handleNext }) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { scenario_id, voice_id } = data;
  const { scenarios, get } = useScenario();

  const settingStepSchema = yup
    .object({
      name: yup.string().required(t('error:required')),
      scenario_id: yup.string().required(t('error:required')),
      number_phone_lines: yup.number().min(1).required(t('error:required')),
      // voice_id: yup.number().required(t('error:required')),
      // callback_url: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(settingStepSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await get();
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const scenario = useMemo(() => {
    const scenarioItems = scenarios.filter((el) => el.status === 1).map((el) => ({ value: el.id, label: el.name }));
    return {
      name: 'scenario_id',
      menuItems: scenarioItems,
      help: 'Campaign name. Latin and Cyrillic characters, as well as numbers and spaces are allowed.',
    };
  }, [scenarios]);

  const handleChange = (event, name) => {
    const {
      target: { value },
    } = event;

    setValue(name, value);
    setData(name, value);
  };

  const voices = useMemo(
    () =>
      // if (!scenario_id) return [];

      // const v = scenario.menuItems.find((el) => el.value === scenario_id);
      // if (!v) return [];

      // return v.voice_id;

      [
        {
          label: 'Ten',
          value: 0,
          scenario_id: 0,
        },
        {
          label: 'Two',
          value: 1,
          scenario_id: 2,
        },
      ],
    []
  );

  const onSubmit = () => {
    handleNext();
  };

  return (
    <>
      <Box sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px' }}>
        <Typography sx={{ color: '#78909c' }} variant="subtitle2">
          {t('table:settings')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} justifyContent="space-between">
            {/* Textfiels in the left column */}
            <Grid item xs={6} direction="column" justifyContent="space-between">
              {textFiels.map((field) => {
                const { name, type, menuItems, help, label } = field;

                if (type && type === 'tags') {
                  return (
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      key={name}
                      sx={{ margin: '6px 0 3px 0' }}
                    >
                      <Grid item xs={11.3}>
                        <Controller
                          name={name}
                          control={control}
                          rules={{ required: true }}
                          defaultValue={data[name] || []}
                          render={({ field }) => (
                            <FormControl className={classes.selectChips}>
                              <InputLabel id="demo-multiple-chip-label">{t(`table:${name}`)}</InputLabel>
                              <Select
                                {...field}
                                defaultValue=""
                                key={name}
                                className={classes.selectChips}
                                id="demo-multiple-chip"
                                multiple
                                value={data[name] || []}
                                onChange={(event) => handleChange(event, name)}
                                input={<OutlinedInput id="select-multiple-chip" label={`table:${name}`} />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                      const item = menuItems.find((el) => el.value === value);
                                      if (item) return <Chip key={item.label} label={item.label} />;

                                      return <Chip key={'null'} label={''} />;
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {menuItems.map((item) => (
                                  <MenuItem
                                    key={item.label}
                                    value={item.value}
                                    // style={getStyles(item.label, blacklist_ids, theme)}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid
                        container
                        direction={'row'}
                        item
                        xs={0.7}
                        justifyContent={'end'}
                        sx={{ paddingLeft: 'unset !important' }}
                      >
                        <HelpText classes={classes} text={help} />
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid item container xs={6} direction="row" alignItems="center" justifyContent="center" key={name}>
                    <Grid item xs={11.3}>
                      <Controller
                        name={name}
                        control={control}
                        value={data[name]}
                        defaultValue={data[name]}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            // {...field}
                            defaultValue={data[name]}
                            sx={textFileSxProps}
                            error={!!errors[name]}
                            helperText={errors[name]?.message}
                            margin="dense"
                            label={label || t(`table:${name}`)}
                            fullWidth
                            variant="outlined"
                            size="normal"
                            type={type || 'text'}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(e) => {
                              setData(name, e.target.value);
                              setValue(name, e.target.value);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      container
                      direction={'row'}
                      item
                      xs={0.7}
                      justifyContent={'end'}
                      sx={{ paddingLeft: 'unset !important' }}
                    >
                      <HelpText classes={classes} text={help} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            {/* Select in the left column */}
            <Grid item xs={6} direction="column" spacing={0}>
              <Grid
                item
                xs={6}
                direction="row"
                alignItems="center"
                justifyContent="center"
                key={scenario.name}
                spacing={0}
                sx={{ display: 'flex' }}
              >
                <Grid item xs={11.3}>
                  <Controller
                    name={scenario.name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth size="normal" error={!!errors[scenario.name]} sx={formControlSxProps}>
                        <InputLabel id="demo-simple-select-label">{t(`table:${scenario.name}`)}</InputLabel>
                        <Select
                          defaultValue=""
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t(`table:${scenario.name}`)}
                          {...field}
                          inputProps={{
                            name: { name: scenario.name },
                            id: `${scenario.name}-helper-text`,
                          }}
                          value={scenario_id}
                          onChange={(event) => handleChange(event, scenario.name)}
                        >
                          {scenario.menuItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errors[scenario.name]?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid
                  container
                  spacing={0}
                  direction={'row'}
                  item
                  xs={0.7}
                  justifyContent={'end'}
                  sx={{ paddingLeft: 'unset !important' }}
                >
                  <HelpText classes={classes} text={scenario.help} />
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                direction="row"
                alignItems="center"
                justifyContent="center"
                key={'voice_id'}
                spacing={0}
                sx={{ display: 'flex' }}
              >
                <Grid item xs={11.3}>
                  <Controller
                    name={'voice_id'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth size="normal" error={!!errors.voice_id} sx={formControlSxProps}>
                        <InputLabel id="demo-simple-select-label">{t(`table:voice_id`)}</InputLabel>
                        <Select
                          defaultValue=""
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t(`table:voice_id`)}
                          {...field}
                          inputProps={{
                            name: 'voice_id',
                            id: `voice_id-helper-text`,
                          }}
                          value={voice_id}
                          onChange={(event) => handleChange(event, 'voice_id')}
                        >
                          {voices &&
                            voices?.map &&
                            voices.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.voice_id?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid
                  container
                  direction={'row'}
                  item
                  xs={0.7}
                  justifyContent={'end'}
                  sx={{ paddingLeft: 'unset !important' }}
                >
                  <HelpText classes={classes} text="ttsVoiceDescription" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box
        sx={{ padding: '10px', background: '#f6f9fa', borderRadius: '8px', marginBottom: '10px', textAlign: 'right' }}
      >
        <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
          {t('next')}
        </Button>
      </Box>
    </>
  );
}

export default CallStragety;

CallStragety.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleComplete: PropTypes.func,
  setData: PropTypes.func,
};
