import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStyles } from 'hooks/useStyles';
// @mui
import { Grid, Stack, CircularProgress, Box } from '@mui/material';
// hooks
import useCost from 'hooks/useCost';
import Table from 'components/Table';
import DateRangeSelector from 'components/DateRangeSelector';
import { makeStyles } from '@mui/styles';
import Iconify from '../../components/iconify';

function formatDateDisplay(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format);
}

const useStyleDate = makeStyles((theme) => ({
  timeRange: {
    border: '1px solid #c6cacc',
    borderRadius: '30px',
    padding: '6px 10px',
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#78909c',
    },
  },
  pr10: {
    paddingRight: '10px',
  },
  px10: {
    padding: '0 10px',
  },
  datePicker: {
    position: 'absolute',
    top: '40px',
    background: '#fff',
    boxShadow: '2px 5px 6px 3px #bdbdbd78',
    borderRadius: '8px',
    zIndex: '1000',
  },
  buttonGroup: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #d8d8d8',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    color: '#fff',
    background: theme.palette.primary.main,
    height: '33px',
    borderRadius: '8px',
    padding: '6px 16px',
    minWidth: '64px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
}));

export default function CostEstimate() {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const classesDate = useStyleDate();
  const { costs, total, get: getCosts } = useCost();
  const [filter, setFilter] = useState('');
  const headers = [
    {
      label: 'STT'
    },
    {
      label: 'HƯỚNG',
    },
    {
      label: 'SỐ GIÂY',
    },
    {
      label: 'SỐ TIỀN',
    },
    {
      label: '',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const onFilter = () => {
    setOpenDateRange(false);
    console.log(selectedDateRange);
    setFilter({
      start: formatDateDisplay(selectedDateRange.startDate, 'DD-MM-YYYY'),
      end: formatDateDisplay(selectedDateRange.endDate, 'DD-MM-YYYY')
    });
  };

  useEffect(() => {
    const handleGetCosts = async () => {
      setLoading(true);
      await getCosts(filter);
      setLoading(false);
    };

    try {
      handleGetCosts();
    } catch (err) {
      console.log(err);
    }
  }, [filter]);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('const')} </title>
      </Helmet>

      <Box sx={{ position: 'relative' }}>
        <Stack item container direction="row" justifyContent="space-between" mb={5} xs={10} sx={{ position: 'relative' }}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div className={classesDate.timeRange} onClick={() => setOpenDateRange((prev) => !prev)}>
            <span className={classesDate.pr10}>{`${formatDateDisplay(selectedDateRange.startDate)}`}</span>
            <span>to</span>
            <span className={classesDate.px10}>{`${formatDateDisplay(selectedDateRange.endDate)}`}</span>
            <Iconify icon="material-symbols:calendar-today" color="#78909c" />
          </div>
          {openDateRange && (
            <div className={classesDate.datePicker}>
                <DateRangeSelector
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                />
              <div className={classesDate.buttonGroup}>
                <button type="button" onClick={onFilter} className={classesDate.button}>
                  OK
                </button>
              </div>
            </div>
          )}
        </Stack>

        <Table total={total} get={getCosts} headers={headers}>
          {costs.map((cost, index) => (
            <Grid
              key={index}
              container
              direction={'row'}
              alignContent={'center'}
              style={{ borderBottom: '#e3e5e6 1px solid', paddingRight: '32px',
                background: `${index + 1 === costs.length ? '#cbd4ff' : '#fff'}`,
                fontWeight: `${index + 1 === costs.length ? 'bold' : 'normal'}`,
              }}
            >
              <Grid item className={classes.row} sx={{ paddingLeft: 3 }}>
                <div className="text-left pl-3">{cost?.stt}</div>
              </Grid>
              <Grid item className={classes.row}>
                <div className={classes.content}>{cost?.title}</div>
              </Grid>
              <Grid item className={classes.row}>
                <div className={classes.content}>{cost?.time_seconds}</div>
              </Grid>
              <Grid item className={classes.row}>
                <div className={classes.content}>{cost?.cost}</div>
              </Grid>
            </Grid>
          ))}
        </Table>

        {loading ? (
          <>
          <Box sx={{ position: 'absolute', width: '100%', top: 0 }}>
            <Box sx={{ display: 'flex', height: '600px', alignItems: 'center', justifyContent: 'center', background: '#fff6;' }}>
              <CircularProgress size={55} />
            </Box>
          </Box>
        </>
        ) : ('')}
      </Box>
    </>
  );
}
