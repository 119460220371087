import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';

import useCommon from 'hooks/useCommon';
import LinkTab from 'components/LinkTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Billing = () => {
  const { t } = useTranslation('common');
  const initialTabs = useMemo(
    () => [
      {
        idx: 0,
        pathname: '/billing/overview',
        label: t('overview'),
      },
      {
        idx: 1,
        pathname: '/billing/deposit',
        label: t('deposit'),
      },
      {
        idx: 2,
        pathname: '/billing/transaction',
        label: t('transaction'),
      },
    ],
    [t]
  );
  const { setParentPage, setChildName } = useCommon();
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState(initialTabs);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.pathname === '/billing') {
      setValue(0);
    }
  }, [location]);

  useEffect(() => {
    setParentPage(null);
    setChildName(null);
  }, []);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('billing')} </title>
      </Helmet>

      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
            aria-label="primary tabs example"
            TabIndicatorProps={{ style: { height: '3px' } }}
          >
            {tabs.map((tab) => (
              <LinkTab key={tab.pathname} label={tab.label} pathname={tab.pathname} {...a11yProps(tab.idx)} />
            ))}
          </Tabs>
        </Box>

        {/* Render panel to show component */}
        {tabs.map((tab) => (
          <TabPanel key={tab.label} value={value} index={tab.idx}>
            <Outlet />
          </TabPanel>
        ))}
      </>
    </>
  );
};

export default Billing;
