import APIService from './api.service';

const baseURL = '/audio-records';

class AudioService extends APIService {
  async createAudioRecord(data) {
    try {
      const response = await this.axios.post(this.baseURL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to post data');
    }
  }

  async updateAudioRecord(id, data) {
    try {
      data.append('_method', 'PUT');
      const response = await this.axios.post(`${this.baseURL}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to put data');
    }
  }
}

export default new AudioService(baseURL);
