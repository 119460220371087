import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useScenario from 'hooks/useScenario';

const EditScenario = ({ data, onClose, handleSetToast }) => {
  const { t } = useTranslation('common');
  const { update } = useScenario();

  const schema = yup.object().shape({
    name: yup.string().required(t('error:roleNameRequired')),
    type: yup.string().required(t('error:roleNameRequired')),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data || {},
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit = (data) => {
    update(data.id, data);
    handleSetToast({ open: true, type: 'success', message: t('notify:updateSuccess') });
    handleClose();
  };

  return (
    <div>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue={data.name}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.name}
                helperText={errors?.name?.message}
                margin="dense"
                label={t('table:name')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />

          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth sx={{ marginTop: '10px' }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  defaultValue={data.type}
                >
                  <MenuItem value={2}>Inbound/Outbound</MenuItem>
                  <MenuItem value={1}>Outbound</MenuItem>
                  <MenuItem value={0}>Inbound</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="dialplan"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.dialplan}
                helperText={errors?.dialplan?.message}
                margin="dense"
                label={t('table:dialplan')}
                fullWidth
                variant="outlined"
                multiline
                rows={10}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleClose} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: '#fff',
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {t('table:update')}
        </Button>
      </DialogActions>
    </div>
  );
};

EditScenario.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  handleSetToast: PropTypes.func,
};

export default EditScenario;
