import React from 'react'
import PropTypes from 'prop-types';
import './style.css'

const NotFound = ({ title, image}) => (
  <div className='campaign_notfound'>
    <img src={image} alt='not_found'/>
    <h2>{title}</h2>
  </div>
)

NotFound.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
}

export default NotFound