import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import { copyToClipboard } from 'utils/copyToClipboard';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Alert,
  AlertTitle,
  Switch,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// hooks
import useUser from 'hooks/useUser';
import useRole from 'hooks/useRole';
// components
import Toast from 'components/Toast';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import ManageAPIKeyModal from './ManageAPIKeyModal';
// sections
import { ListHead, ListToolbar } from '../../sections/@dashboard/user';
// const
import { DIALOG_STATUS } from '../../constants/dialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'apiName', label: 'API Name', alignRight: false },
  { id: 'publicKey', label: 'Public Key', alignRight: false },
  { id: 'organizationID', label: 'Organization ID', alignRight: false },
  { id: 'organizationName', label: 'Organization Name', alignRight: false },
  { id: 'isApiEnable', label: 'Is Api Enable', alignRight: false },
  { id: 'allowApiEnable', label: 'Allow Api Enable', alignRight: false },
  { id: 'createAt', label: 'Create At', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const { t } = useTranslation('common');
  const { users, total, get: getUsers } = useUser();
  const { roles, get: getRoles } = useRole();
  const [isApiEnable, setIsApiEnable] = useState(true);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.CREATE);
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });
  const [selectedRow, setSelectedRow] = useState(null);
  const emptyRows = page > 1 ? Math.max(0, rowsPerPage - users.length) : 0;
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy));
  const isNotFound = !filteredUsers.length && !!filterName;

  const handleCopy = (text) => {
    copyToClipboard(text);
    setToast(
      produce((draft) => {
        draft.open = true;
        draft.message = 'Coppied';
      })
    );
  };

  const handleCloseToast = () => {
    setToast(
      produce((draft) => {
        draft.open = false;
      })
    );
  };

  const handleSetToast = (newToast) => {
    setToast(
      produce((draft) => {
        draft.open = newToast.open || false;
        draft.type = newToast.type || 'success';
        draft.message = newToast.message || '';
      })
    );
  };

  const handleOpenModal = (status) => {
    if (status === DIALOG_STATUS.CREATE) setSelectedRow(null);
    handleCloseMenu();
    setDialogStatus(status);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenMenu = (event, row) => {
    setSelectedRow({ ...row });
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    const handleGetUsers = async () => {
      await getUsers({ page, limit: rowsPerPage, keyword: filterName });
    };

    const handleGetRoles = async () => {
      await getRoles();
    };

    try {
      handleGetUsers();
      handleGetRoles();
    } catch (err) {
      console.log(err);
    }
  }, [page, rowsPerPage, filterName]);

  return (
    <>
      <Helmet>
        <title> {sentenceCase('user')} </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" color="#fff" />}
          onClick={() => handleOpenModal(DIALOG_STATUS.CREATE)}
        >
          {t('table:add')}
        </Button>
      </Stack>

      {/* TODO: after creating show the alert */}
      {/* <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        <div>
          Public key: <strong>up5Rqbm8bRMvQzX35z2S4CHFx7z4JKmI </strong>
          <IconButton aria-label="copy" size="small" onClick={() => handleCopy('up5Rqbm8bRMvQzX35z2S4CHFx7z4JKmI')}>
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div>
          Secret key:{' '}
          <strong>
            64878e143deeb92fa3496d55970995a7440571e370d837a252f62cc8b0f871b65ef501dc51ce7f347d59a2ed9b782e5399e39f7feab266bf65b7ddbf4e5872fa
          </strong>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() =>
              handleCopy(
                '64878e143deeb92fa3496d55970995a7440571e370d837a252f62cc8b0f871b65ef501dc51ce7f347d59a2ed9b782e5399e39f7feab266bf65b7ddbf4e5872fa'
              )
            }
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Alert> */}

      <Card>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder={t('search')}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.map((row) => {
                  const { id, user_name, role, name, email } = row;
                  const selectedUser = selected.indexOf(name) !== -1;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                      </TableCell>

                      <TableCell align="left">{id}</TableCell>

                      <TableCell align="left">{user_name}</TableCell>

                      <TableCell align="left">{name}</TableCell>

                      <TableCell align="left">{email}</TableCell>

                      <TableCell align="left">{role}</TableCell>

                      <TableCell align="left">
                        {/* TODO: call api update api enable */}
                        <Switch checked={isApiEnable} onChange={(e) => setIsApiEnable(e.target.checked)} />
                      </TableCell>

                      <TableCell align="left">
                        <Switch checked />
                      </TableCell>

                      <TableCell align="left">{role}</TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.UPDATE)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('table:update')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(DIALOG_STATUS.DELETE)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('table:delete')}
        </MenuItem>
      </Popover>

      {openModal && (
        <ManageAPIKeyModal
          dialogStatus={dialogStatus}
          openModal={openModal}
          values={selectedRow}
          handleCloseModal={handleCloseModal}
          handleSetToast={handleSetToast}
          availableData={{ roles }}
        />
      )}

      <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast>
    </>
  );
}
