import { format, getTime, formatDistanceToNow } from 'date-fns';
import cron from 'cron-parser';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const convertCronExpression = (expression) => {
  const interval = cron.parseExpression(expression);
  const nextDate = interval.next();
  return moment(nextDate.toDate()).format('HH:mm');
};

export const convertToMinuteSeconds = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const formatLocaleDateTime = (date) => moment(date).format('DD/MM/YYYY, HH:mm');

export const formatDateTime = (dateTime) => {
  const utcDate = new Date(`${dateTime.replace(' ', 'T')}Z`);
  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getDate()).padStart(2, '0');
  const hours = String(utcDate.getHours()).padStart(2, '0');
  const minutes = String(utcDate.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};
