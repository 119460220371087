export const DIALOG_STATUS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const CAMPAIGNS = {
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  SET_REPORT: 'SET_REPORT',
};
export default {
  DIALOG_STATUS,
};
