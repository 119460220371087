import APIService from './api.service';

const baseURL = '/scenarios';

class ScenarioService extends APIService {
  async testScenario(id, phoneNumber) {
    const response = await this.axios.post(`${this.baseURL}/${id}/test-making-call/${phoneNumber}`);
    return response.data;
  }
}

export default new ScenarioService(baseURL);
