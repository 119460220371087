import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import useScenario from 'hooks/useScenario';

const Test = ({ id, onClose, handleSetToast }) => {
  const { t } = useTranslation('common');
  const { testScenario } = useScenario();

  const schema = yup.object().shape({
    phoneNumber: yup.string().required(t('error:required')),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit = async (data) => {
    try {
      await testScenario(id, data.phoneNumber);
      handleSetToast({ open: true, type: 'success', message: t('notify:updateSuccess') });
      handleClose();
    } catch (error) {
      handleSetToast({ open: true, type: 'error', message: t('notify:updateFailed') });
    }
  };

  return (
    <div>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.phoneNumber}
                helperText={errors?.phoneNumber?.message}
                margin="dense"
                label={t('table:phoneNumber')}
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: '10px' }}>
        <Button onClick={handleClose} sx={{ color: (theme) => theme.palette.text.grey }}>
          {t('table:cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: '#fff',
            background: (theme) => theme.palette.primary.main,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
              opacity: '0.8',
            },
          }}
        >
          {t('call')}
        </Button>
      </DialogActions>
    </div>
  );
};

Test.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  handleSetToast: PropTypes.func,
};

export default Test;
