import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, TextField, Switch, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { copyToClipboard } from 'utils/copyToClipboard';

export const formatDate = (date) => moment(date).format('MM/DD/YYY, HH:mm');

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
  },
  content: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
  contentCollapse: {
    flex: 1,
    display: 'flex',
    paddingTop: '16px',
    paddingBottom: '16px',
    justifyContent: 'center',
    fontSize: '14px',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    color: '#fff',
    padding: '12px',
    minWidth: '64px',
    position: 'relative',
    background: theme.palette.primary.main,
    height: '33px',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px',
      color: 'rgb(62, 67, 69)',
    },
    '& .MuiInputBase-input': {
      height: '1.1876em'
    },
  },
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px',
    },
    '& .MuiInputBase-input': {
      height: '1.1876em',
      color: 'rgb(62, 67, 69)',
    },
    '& .MuiInputBase-root': {
      height: '46px',
    }
  },
}));

const truncateMiddleText = (text) => `${text.slice(0, 8)}...${text.slice(-8)}`;
export const getStatusString = (status_code) => {
  switch (status_code) {
    case 103:
      return 'Missed Call';
    default:
      return 'Undefined Code';
  }
};

const typeOptions = [
  {
    label: 'events',
    value: 'events',
  },
  {
    label: 'tags',
    value: 'tags',
  }
]

const Row = ({ propKey, name, isVisualized, type }) => {
  const classes = useStyles();
  const [toast, setToast] = useState({ open: false, type: 'success', message: '' });

  const handleChange = () => {
    setToast({ open: false, type: toast.type, message: toast.message });
  };
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>
            <TextField className={classes.input} id="outlined-helperText" label={'key'} defaultValue={propKey} value={propKey} />
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>
            <TextField className={classes.input} id="outlined-helperText" label={'name'} defaultValue={''} value={name} />
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.row}
          style={{ borderBottom: '#e3e5e6 1px solid' }}
        >
          <div className={classes.content}>
            <Switch checked={isVisualized} />
          </div>
        </Grid>
        <Grid item className={classes.row} style={{ borderBottom: '#e3e5e6 1px solid' }}>
          <div className={classes.content}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="type"
                onChange={handleChange}
                className={classes.select}
              >
                {typeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.row}
          style={{ borderBottom: '#e3e5e6 1px solid' }}
        >
          <div className={classes.content} style={{ paddingTop: '22px' }}>
            <Button className={classes.button}>-</Button>
          </div>
        </Grid>
      </Grid>

      {/* <Toast openToast={toast.open} handleClose={handleCloseToast} type={toast.type}>
        {toast.message}
      </Toast> */}
    </>
  );
};

Row.propTypes = {
  propKey: PropTypes.string,
  name: PropTypes.string,
  isVisualized: PropTypes.string,
  type: PropTypes.string,
};

export default Row;
