import { filter } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import { Stack, Button, Popover, MenuItem, Pagination, Grid, Box, InputAdornment, TextField } from '@mui/material';
import Iconify from 'components/iconify';

import USERLIST from '_mock/user';
import { campaigns } from '_mock/campaign';
import { makeStyles } from '@mui/styles';
import navCampaignConfig from '../CampaignPage/config';
import CampaignModal from '../CampaignPage/CampaignModal';
import CampaignItem from '../CampaiginItem';
import NotFound from '../../../../components/NotFound';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.dark,
    cursor: 'pointer',
    fontSize: '16px',
    '&:hover': {
      color: theme.palette.text.blue,
      textDecoration: 'underline',
    },
  },
  tooltipTitle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  titleSmall: {
    color: '#fff',
    fontSize: '14px',
  },
  tooltipInfo: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    letterSpacing: '0.44px',
    overflow: 'hidden',
    display: 'flex',
    marginBottom: '12px',
    marginTop: '16px',
  },
  tooltipLabel: {
    color: '#c6cacc',
    flex: 1,
  },
  tooltipContent: {
    flex: 1,
  },
  navItem: {
    padding: '5px 10px',
    width: '100%',
    justifyContent: 'start',
    color: theme.palette.text.disabled,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AutoCampaign() {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openTooltip, setOpenTooltip] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const onSubmit = (data) => console.log(data);

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  const handleTooltipOpen = (e, index) => {
    e.stopPropagation();
    setOpenTooltip(index);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (title) => {
    setPage(0);
    setFilterName(title);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Stack sx={{ padding: '20px' }} direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <TextField
          id="outlined-start-adornment"
          sx={{
            m: 1,
            width: '478px',
            '&>.MuiInputBase-root': {
              borderRadius: '50px',
            },
          }}
          placeholder={t('search')}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />

        <Button variant="contained" onClick={handleOpenModal}>
          Create Auto Campaign
        </Button>
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{
            '&>.MuiGrid-item:first-of-type': {
              paddingTop: 0,
            },
          }}
        >
          <Grid item xs={4} md={1.5}>
            <Box>
              <Stack direction={'column'} alignItems={'start'} p={1} gap={0.5}>
                {navCampaignConfig.map(({ title }) => (
                  <Button
                    color="inherit"
                    key={title}
                    className={classes.navItem}
                    onClick={() => handleFilterByName(title)}
                    sx={{ color: `${filterName === title ? '#2065d1 !important' : '#919EAB !important'}` }}
                  >
                    {t(title)}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={8} md={10.5}>
            <Grid
              container
              rowSpacing={2}
              sx={{
                maxHeight: `calc(100vh - 350px)`,
                overflowY: 'scroll',
                display: 'block',
                paddingRight: 1.5,
                '&>.MuiGrid-item': {
                  paddingTop: 0,
                  paddingBottom: 2,
                },
              }}
            >
              {campaigns.length > 0 ? (
                campaigns.map((campaign, index) => (
                  <CampaignItem
                    key={campaign.campaign_id}
                    index={index}
                    openTooltip={openTooltip}
                    handleTooltipClose={handleTooltipClose}
                    styles={classes}
                    handleOpenMenu={handleOpenMenu}
                    handleTooltipOpen={handleTooltipOpen}
                    data={campaign}
                  />
                ))
              ) : (
                <NotFound />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {campaigns.length > 0 && (
        <Pagination
          count={13}
          page={1}
          size="large"
          shape="rounded"
          onChange={handleChangePage}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        />
      )}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>Edit</MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>Delete</MenuItem>
      </Popover>

      {/* <CampaignModal openModal={openModal} handleCloseModal={handleCloseModal} onSubmit={onSubmit} /> */}
      <CampaignModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        steps={['Define call strategy', 'Call plan', 'Schedule', 'Summary']}
        onSubmit={(data) => {
          console.log('data', data);
        }}
      />
    </>
  );
}
