import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LinkTab from 'components/LinkTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OutboundChildDetail = ({ getTabs }) => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState(getTabs(id));
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!id) return;
    navigate(`${getTabs(id)[0].pathname}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="primary tabs example"
          TabIndicatorProps={{ style: { height: '3px' } }}
        >
          {tabs.map((tab) => (
            <LinkTab key={tab.pathname} label={t(`${tab.label}`)} pathname={tab.pathname} {...a11yProps(tab.idx)} />
          ))}
        </Tabs>
      </Box>

      {/* Render panel to show component */}
      {tabs.map((tab) => (
        <TabPanel key={tab.label} value={value} index={tab.idx}>
          <Outlet />
        </TabPanel>
      ))}
    </>
  );
};

OutboundChildDetail.propTypes = {
  getTabs: PropTypes.func,
};

export default OutboundChildDetail;
