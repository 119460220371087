import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import useUser from 'hooks/useUser';
import { useMemo, useEffect } from 'react';
// @mui
import {
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  Chip,
  Stack,
  Switch,
} from '@mui/material';
// const
import { DIALOG_STATUS } from '../../../constants/dialog';

export default function ManageAPIKeyModal({
  dialogStatus,
  values,
  openModal,
  handleCloseModal,
  handleSetToast,
  availableData,
}) {
  const { t } = useTranslation('common');
  const { create, update, destroy } = useUser();
  const roles = useMemo(() => availableData.roles, [availableData.roles]);
  const schema = yup
    .object({
      apiName: yup.string().required(t('error:required')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: { apiName: '', allowAllScenario: true, allowScenarioIds: [] },
    values,
    resolver: yupResolver(schema),
  });

  const allowAllScenarioWatcher = watch('allowAllScenario');

  const generateSuccessToast = (message) => {
    handleSetToast({ open: true, type: 'success', message });
  };

  const onSubmit = (data) => {
    if (dialogStatus === DIALOG_STATUS.CREATE) {
      create(data);
      generateSuccessToast(t('notify:addSuccess'));
    } else if (dialogStatus === DIALOG_STATUS.UPDATE) {
      update(data.id, data);
      generateSuccessToast(t('notify:updateSuccess'));
    } else {
      destroy(data.id);
      generateSuccessToast(t('notify:deletedSuccess'));
    }
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
    reset({ apiName: '', allowAllScenario: true });
  };

  useEffect(() => {
    console.log('values', values);
  }, []);

  return (
    <Dialog open={openModal} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        {dialogStatus === DIALOG_STATUS.CREATE && t('table:createAPI')}
        {dialogStatus === DIALOG_STATUS.UPDATE && t('table:updateAPI')}
        {dialogStatus === DIALOG_STATUS.DELETE && t('table:deleteAPI')}
      </DialogTitle>
      {dialogStatus !== DIALOG_STATUS.DELETE && (
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="apiName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label={t('table:apiName')}
                  fullWidth
                  variant="outlined"
                  error={!!errors.apiName}
                  helperText={errors?.apiName?.message}
                />
              )}
            />
            <div>
              <Controller
                name="allowAllScenario"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                )}
              />
              <span>{t('table:allowAllScenario')}</span>
            </div>
            {!allowAllScenarioWatcher && (
              // BUG: all freeSolo is not working (but in smartcall is the same behavior)
              <Controller
                name="allowScenarioIds"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value || []}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    onBlur={field.onBlur}
                    multiple
                    freeSolo
                    id="tags-standard"
                    options={[{ title: 'scenario1', id: 12 }]}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip value={option.title} key={option.id} label={option.title} {...getTagProps({ index })} />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {selected ? (
                            <Chip variant="outlined" size="small" label={option.title} />
                          ) : (
                            <Chip size="small" label={option.title} />
                          )}
                        </Stack>
                      </li>
                    )}
                  />
                )}
              />
            )}
          </form>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>{t('table:cancel')}</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {dialogStatus === DIALOG_STATUS.DELETE
            ? t('table:delete')
            : dialogStatus === DIALOG_STATUS.UPDATE
            ? t('table:update')
            : t('table:add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ManageAPIKeyModal.propTypes = {
  dialogStatus: PropTypes.string,
  values: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleSetToast: PropTypes.func,
  availableData: PropTypes.object,
};
